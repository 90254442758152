import React from "react";
import { Box, Typography } from "@mui/material";

import { AttachmentContent } from "../../../types/Ticket";
import { sxProps } from "./attachment-thumbnail.style";
import { attachmentSxProps } from "../attachment.style";
import { FilePresentOutlined } from "@mui/icons-material";

export type AttachmentThumbnailProps = {
  attachment: AttachmentContent;
};

export const AttachmentThumbnail = ({
  attachment,
}: AttachmentThumbnailProps) => {
  const src = `data:${attachment.type};base64, ${attachment.base64}`;
  if (attachment.type === "application/pdf") {
    return (
      <Box
        sx={{
          ...sxProps.attachmentThumbnailBox,
          ...attachmentSxProps.attachmentBorder,
          ...sxProps.attachmentFileBox,
        }}
      >
        <FilePresentOutlined sx={sxProps.attachmentIcon} />
        <Typography variant="overline" sx={sxProps.attachmentName}>
          {attachment.name}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          ...sxProps.attachmentThumbnailBox,
          ...attachmentSxProps.attachmentBorder,
        }}
      >
        <img src={src} alt="attachment" />
      </Box>
    );
  }
};
