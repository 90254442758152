import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardListWrapper: {
    minHeight: (theme) => theme.custom.dashboardListHeight,
    height: (theme) => theme.custom.dashboardListHeight,
    overflow: "scroll",
    padding: 0,
    backgroundColor: (theme) => theme.palette.background.paper,
  },
};
