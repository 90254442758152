import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";

import App from "./app/shared/App";
import { store } from "./app/shared/store";
import { getEnvValue } from "./app/shared/utils/env.helper";
import "./index.css";

const sentryDsn = getEnvValue("SENTRY_DSN");

if (sentryDsn) {
  const tracesSampleRate = getEnvValue("SENTRY_TRACES_SAMPLE_RATE");

  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: tracesSampleRate ? Number(tracesSampleRate) : undefined,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
