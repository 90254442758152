import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  noTicketsStack: {
    alignItems: "center",
    justifyContent: "center",
    margin: (theme) => theme.spacing(0, 1),
    height: "75vh",
  },
  noTicketsHeadline: {
    fontWeight: 700,
    paddingBottom: (theme) => theme.spacing(1),
  },
  noTicketsText: {
    color: (theme) => theme.palette.common.black,
    textAlign: "center",
  },
  noTicketsImage: {
    display: "flex",
    padding: (theme) => theme.spacing(0, 0, 5, 0),
  },
};
