import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentThumbnailBox: {
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  },
  attachmentFileBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: (theme) => theme.spacing(1),
  },
  attachmentIcon: {
    color: (theme) => theme.palette.grey[300],
    width: (theme) => theme.spacing(8),
    height: (theme) => theme.spacing(7),
  },
  attachmentName: {
    color: (theme) => theme.palette.grey[800],
  },
};
