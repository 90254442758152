import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import watch from "redux-watch";

import { saveAuthState } from "../utils/localStorage";
import authReducer, { AuthState } from "./authSlice";
import loadingReducer from "./loadingSlice";
import snackbarReducer from "./snackbarSlice";
import ticketReducer from "./ticketSlice";
import attachmentsReducer from "./attachmentSlice";
import messagesReducer from "./messageSlice";
import profileReducer from "./profileSlice";
import notificationsReducer from "./notificationSlice";
import mentionReducer from "./mentionSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: loadingReducer,
    snackbar: snackbarReducer,
    ticket: ticketReducer,
    attachments: attachmentsReducer,
    messages: messagesReducer,
    profile: profileReducer,
    notifications: notificationsReducer,
    mention: mentionReducer,
  },
});

//every time auth state us changed it will be saved in local storage
let w = watch(store.getState, "auth");
store.subscribe(
  w((newVal: AuthState, oldVal: AuthState) => {
    if (newVal !== oldVal) {
      saveAuthState(newVal);
    }
  })
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
