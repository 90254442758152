import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  bellWrapper: {
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
  },

  activeBellIcon: {
    color: (theme) => theme.palette.primary.main,
  },

  inactiveBellIcon: {
    color: (theme) => theme.palette.grey[500],
  },
};
