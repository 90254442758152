import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  navbarButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: (theme) => theme.spacing(3),
    borderRadius: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.primary.contrastText,
    padding: (theme) => theme.spacing(0, 1),
    overflow: "hidden",
  },

  navbarButtonActive: {
    background: (theme) => theme.palette.primary.contrastText,
    color: (theme) => theme.palette.text.primary,
  },

  navbarButtonBoxCounter: {
    paddingRight: 0,
    paddingLeft: (theme) => theme.spacing(1),
  },

  counter: {
    marginLeft: (theme) => theme.spacing(1),
    borderRadius: (theme) => theme.spacing(1),
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
    textAlign: "center",
    fontSize: (theme) => theme.typography.button.fontSize,
    fontWeight: (theme) => theme.typography.button.fontWeight,
  },

  counterActive: {
    borderRadius: 0,
  },

  counterPrimary: {
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
  },

  counterSecondary: {
    background: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.primary.contrastText,
  },
};
