import { TaggedUser, UserSuggestion } from "../../types/Ticket";
import {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { MessagesProvider } from "../../provider/messagesProvider";
import { Box, Fade, Popper, Stack, TextField } from "@mui/material";
import { sxProps } from "./taggable-text-field.style";
import { TaggableTextFieldMentionComponent } from "./components";
import { SystemStyleObject } from "@mui/system";
import { Theme } from "@mui/material/styles/createTheme";
import { store } from "../../store";
import {
  selectMentions,
  storeMentions,
  storeQuery,
} from "../../store/mentionSlice";
import { useAppSelector } from "../../store/hooks";
import { useTicket } from "../../../+serviceRequest/ticketContextProvider";

export type TaggableTextFieldProps = {
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  sx?: SystemStyleObject<Theme>;
  fullWidth?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  taggedUsers: TaggedUser[];
  setTaggedUsers: Dispatch<SetStateAction<TaggedUser[]>>;
  disablePortal: boolean;
};

export const TaggableTextFieldComponent = ({
  minRows = 1,
  maxRows = 5,
  multiline = true,
  sx,
  fullWidth = false,
  label,
  error,
  helperText,
  disabled,
  message,
  setMessage,
  taggedUsers,
  setTaggedUsers,
  disablePortal,
}: TaggableTextFieldProps) => {
  const ticket = useTicket();
  const inputEl = useRef(null);
  const [tagActive, setTagActive] = useState<TaggedUser>({
    userId: "",
    text: "",
  });
  const [messageBeforeDelete, setMessageBeforeDelete] = useState("");
  const [isQuerying, setIsQuerying] = useState(false);
  const [query, setQuery] = useState("");
  const mentions = useAppSelector((state) => selectMentions(state));

  const messagesProvider = new MessagesProvider();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query) {
        store.dispatch(storeQuery(query.split("@")[1]));
        fetchUsers(query);
      } else {
        store.dispatch(storeMentions([]));
      }
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUsers = async (query: string) => {
    const splitedQuery = query.split("@")[1];
    if (splitedQuery.length > 0) {
      await messagesProvider.fetchSuggestedMentions({
        query: splitedQuery,
        ticketKey: ticket?.key,
      });
    }
  };

  const selectUser = (mention: UserSuggestion) => {
    const newMessage = message.replaceAll(query, `@${mention.displayName}`);
    setMessage(newMessage);
    setIsQuerying(false);
    setQuery("");
    store.dispatch(storeMentions([]));
    setTaggedUsers([
      ...taggedUsers,
      ...[{ userId: mention.accountId, text: mention.displayName }],
    ]);
  };

  const removeTaggedUser = (userId: string) => {
    const newTaggedUsers = taggedUsers.filter((user) => {
      return user.userId !== userId;
    });
    setTaggedUsers(newTaggedUsers);
  };

  const typedUser = (input: string, caret: number | null) => {
    if (!caret) {
      caret = 0;
    }
    const slicedInput = input.slice(input.lastIndexOf("@"), caret);

    if (slicedInput.indexOf("@") === -1) {
      setQuery("");
      setIsQuerying(false);
    } else {
      setQuery(slicedInput);
    }
  };

  const positionOfTags = (caretPosition: number) => {
    taggedUsers.forEach((tag) => {
      const start = message.indexOf(`@${tag.text}`);
      const end = start + tag.text.length + 1;
      const active: boolean = start < caretPosition && end > caretPosition;
      if (active) setTagActive({ userId: tag.userId, text: tag.text });
    });
  };

  const popper = {
    style: {
      display: "flex",
      alignItems: "flex-end",
      width: "100%",
      maxHeight: "60vh",
      overflow: "scroll",
      paddingBottom: "16px",
      zIndex: 99,
    },
  };
  return (
    <Box sx={sx}>
      <Popper
        style={popper.style}
        id={"mentions-popper"}
        open={isQuerying}
        anchorEl={inputEl.current}
        placement={"top-start"}
        transition
        disablePortal={disablePortal}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box sx={sxProps.mentionsBox}>
              <Stack>
                {mentions.map((m, i) => {
                  return (
                    <TaggableTextFieldMentionComponent
                      mention={m}
                      key={i}
                      selectUser={selectUser}
                    />
                  );
                })}
              </Stack>
            </Box>
          </Fade>
        )}
      </Popper>
      <TextField
        sx={sxProps.messageInput}
        ref={inputEl}
        disabled={disabled}
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
          if (taggedUsers) {
            positionOfTags(e.target.selectionEnd || 0);
          }
          if (isQuerying) {
            typedUser(e.target.value, e.target.selectionEnd);
          }
        }}
        onInput={(e: BaseSyntheticEvent) => {
          // @ts-ignore
          if (e.nativeEvent.data === "@") {
            setIsQuerying(true);
          }
          if (taggedUsers) {
            setMessageBeforeDelete(message);
          }
          setMessage(e.target.value);
          if (taggedUsers) {
            positionOfTags(e.target.selectionEnd || 0);
          }
          if (isQuerying) {
            typedUser(e.target.value, e.target.selectionEnd);
          }
        }}
        onKeyUp={(e) => {
          if (tagActive.text.length > 0) {
            const newMessage = messageBeforeDelete.replaceAll(
              `@${tagActive.text}`,
              ""
            );
            setMessage(newMessage);
            setTagActive({ userId: "", text: "" });
            removeTaggedUser(tagActive.userId);
          }
        }}
        variant="outlined"
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        fullWidth={fullWidth}
        label={label}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
};
