import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  wrapperBox: {
    marginBottom: (theme) => theme.spacing(3),
  },

  wrapperBoxLeft: {
    marginLeft: (theme) => theme.spacing(1),
    marginRight: (theme) => theme.spacing(6),
  },

  wrapperBoxRight: {
    marginLeft: (theme) => theme.spacing(6),
    marginRight: (theme) => theme.spacing(1),
  },

  dateBox: {
    marginBottom: (theme) => theme.spacing(1),
  },

  dateBoxAlignLeft: {
    textAlign: "left",
  },

  dateBoxAlignRight: {
    textAlign: "right",
  },

  dateTypography: {
    color: (theme) => theme.palette.primary.main,
  },
  messageBox: {
    background: (theme) => theme.palette.grey[100],
    borderRadius: (theme) => theme.spacing(2),
    padding: (theme) => theme.spacing(2, 1, 2, 2),
    overflowWrap: "break-word",
    whiteSpace: "normal",
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },

  internalMessageBox: {
    background: (theme) => `${theme.palette.warning.light}20`,
    border: (theme) => `2px solid ${theme.custom.activeReading}`,
  },

  thirdPartyMessageBox: {
    background: (theme) => `${theme.palette.primary.main}20`,
  },
};
