/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CreateBodyDto, Notification } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Notifications<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags notifications
   * @name Create
   * @request POST:/notifications
   * @secure
   */
  create = (data: CreateBodyDto, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name GetAll
   * @request GET:/notifications
   * @secure
   */
  getAll = (query?: { page?: number; limit?: number; unread?: boolean }, params: RequestParams = {}) =>
    this.request<
      {
        docs?: Notification[];
        totalDocs?: number;
        limit?: number;
        page?: number | null;
        currentPage?: number;
        totalPages?: number;
        nextPage?: number | null;
        prevPage?: number | null;
        pagingCounter?: number;
        hasPrevPage?: boolean;
        hasNextPage?: boolean;
      },
      any
    >({
      path: `/notifications`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name GetOne
   * @request GET:/notifications/{id}
   * @secure
   */
  getOne = (id: string, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name MarkAllAsRead
   * @request POST:/notifications/markAsRead
   * @secure
   */
  markAllAsRead = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/notifications/markAsRead`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name MarkOneAsRead
   * @request POST:/notifications/{id}/markAsRead
   * @secure
   */
  markOneAsRead = (id: string, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/${id}/markAsRead`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name MarkOneAsUnread
   * @request POST:/notifications/{id}/markAsUnread
   * @secure
   */
  markOneAsUnread = (id: string, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/${id}/markAsUnread`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
