import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  menuItemBox: {
    height: "43px", //(theme) => theme.spacing(5.375),
    width: "43px", //(theme) => theme.spacing(5.375),
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  menuItemActive: {
    background: (theme) => theme.palette.primary.contrastText,
    color: (theme) => theme.palette.primary.main,
  },
};
