import { store } from "../store";
import { setLoading } from "../store/loadingSlice";
import { JiraServiceClient } from "./clients/jira-service.client";
import { executeWithRefresh, getLoggedUser } from "./authProvider";
import { JiraProfile } from "../types/Profile";
import { setJiraProfile } from "../store/profileSlice";

export class ProfileProvider {
  private client: JiraServiceClient;

  constructor() {
    this.client = new JiraServiceClient();
  }

  fetchProfile = async () => {
    const authUser = getLoggedUser();
    store.dispatch(setLoading(true));
    const profile = await executeWithRefresh<JiraProfile>({
      authUser,
      func: this.client.getJiraProfile,
    });
    if (profile) store.dispatch(setJiraProfile(profile));
    store.dispatch(setLoading(false));
  };
}
