import { TransitionFormFormat } from "../../../../../../../shared/types/Workflow";
import { ChangeEvent } from "react";

export const statusTransitionFormValidation = (
  formData: any,
  transitionFormFormat: TransitionFormFormat
) => {
  const error: Record<string, string> = {};
  //validating comments
  if (!formData.message.message) {
    error.comment = "error.commentIsMissing";
  }
  return error;
};

export const removeStartingZero = (
  e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => {
  const slicedValue = e.target.value.slice(0, 1);
  if (slicedValue.toString() === "0") {
    e.target.value = e.target.value.slice(1);
  }
};
