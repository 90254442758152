import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  modal: {
    margin: "25vh 10vw 0 10vw",
  },
  modalPaper: {
    outline: "none",
  },
  titleBox: {
    textAlign: "left",
    padding: (theme) => theme.spacing(2),
  },
  markAllAsReadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: (theme) => theme.spacing(1.5),
    color: (theme) => theme.palette.primary.main,
  },
  notificationList: {
    height: (theme) => theme.custom.notificationListHeight,
    overflow: "scroll",
    padding: 0,
  },
  divider: {
    padding: 0,
    color: (theme) => theme.palette.grey[300],
  },
};
