import React, { useCallback } from "react";
import { Chip, Paper } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../shared/store/hooks";
import {
  selectTicketQuery,
  setTicketQuery,
} from "../../../shared/store/ticketSlice";

import { sxProps } from "./location-chip.style";

export const LocationChip = () => {
  const ticketQuery = useAppSelector((state) => selectTicketQuery(state));
  const dispatch = useAppDispatch();
  const onDelete = useCallback(() => {
    dispatch(setTicketQuery({}));
  }, [dispatch]);

  if (!ticketQuery?.address || !ticketQuery?.countryCode) return null;
  return (
    <Paper sx={sxProps.locationChipPaper}>
      <Chip
        sx={sxProps.locationChip}
        variant="outlined"
        label={ticketQuery.address}
        onDelete={onDelete}
      />
    </Paper>
  );
};
