import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  ticketCountBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconStyle: {
    width: "100%",
    height: "100%",
  },

  ticketCountAllIcon: {
    width: (theme) => theme.spacing(6),
    color: (theme) => theme.palette.secondary.main,
    marginRight: (theme) => theme.spacing(1),
  },
  ticketCountOpenIcon: {
    width: (theme) => theme.spacing(5),
    color: (theme) => theme.palette.grey[500],
    marginRight: (theme) => theme.spacing(1),
  },
  ticketCountClosedIcon: {
    width: (theme) => theme.spacing(5),
    color: (theme) => theme.palette.success.main,
    marginRight: (theme) => theme.spacing(1),
  },
  ticketCountOverdueIcon: {
    width: (theme) => theme.spacing(5),
    color: (theme) => theme.palette.error.main,
    marginRight: (theme) => theme.spacing(1),
  },
  ticketCountInProgressIcon: {
    width: (theme) => theme.spacing(5),
    color: (theme) => theme.palette.info.main,
    marginRight: (theme) => theme.spacing(1),
  },
  ticketCountLabel: {
    color: (theme) => theme.palette.grey[600],
  },
  ticketCountNumber: {
    padding: (theme) => theme.spacing(0, 1, 0.25, 0),
    lineHeight: "50%",
  },
};
