import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  locationChipPaper: {
    marginTop: (theme) => theme.spacing(1),
    padding: (theme) => theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  locationChip: {
    background: (theme) => theme.palette.grey[300],
    border: "none",
  },
};
