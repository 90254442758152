import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Ticket } from "../../../shared/types/Ticket";
import { sxProps } from "./service-request-header.style";

export type ServiceRequestHeaderProps = {
  ticket: Ticket;
  ellipsis?: boolean;
};

export const ServiceRequestHeader = ({
  ticket,
  ellipsis,
}: ServiceRequestHeaderProps) => {
  const { t } = useTranslation();

  const typographyStyle = useMemo(() => {
    return ellipsis ? { ...sxProps.ellipsis } : {};
  }, [ellipsis]);
  return (
    <Box sx={sxProps.serviceRequestDetailsBox}>
      <Box>
        <Typography
          variant="overline"
          component="span"
          sx={sxProps.serviceRequestDetailsIdLabel}
        >
          {t("+serviceRequest.details.requestId")}
        </Typography>{" "}
        <Typography
          variant="overline"
          component="span"
          sx={sxProps.serviceRequestDetailsId}
        >
          {ticket.key}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h1" sx={typographyStyle}>
          {ticket.summary}
        </Typography>
      </Box>
    </Box>
  );
};
