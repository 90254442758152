import React, { forwardRef } from "react";
import { Alert } from "@mui/material";
import { SnackbarContent, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Snackbar as SnackbarProps } from "../../../types/Snackbar";

export const CustomSnackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { closeSnackbar } = useSnackbar();

    const handleClose = () => {
      closeSnackbar(props.id);
    };

    return (
      <SnackbarContent ref={ref}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {t(props.message)}
        </Alert>
      </SnackbarContent>
    );
  }
);
