import { Ticket } from "../../../../../../../../shared/types/Ticket";

export const convertDateToString = (date: Date) => {
  return date
    .toISOString()
    .replace(/-/g, "")
    .replace(/:/g, "")
    .replace(/\..../g, "");
};

export const createIcalFile = (
  appointmentTimeObject: Record<string, string>,
  profile: any,
  ticket: Ticket,
  reschedule: number
) => {
  const now = convertDateToString(new Date());
  const ics_lines = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "CALSCALE:GREGORIAN",
    "METHOD:REQUEST",
    "BEGIN:VEVENT",
    `DTSTAMP:${appointmentTimeObject.startTime}`,
    `DTSTART:${appointmentTimeObject.startTime}`,
    `DTEND:${appointmentTimeObject.endTime}`,
    `ORGANIZER:${profile?.displayName || ""}`,
    `SUMMARY:${ticket?.summary}`,
    `DESCRIPTION:${ticket?.description}`,
    `LOCATION:${ticket?.fullAddress}`,
    "STATUS:CONFIRMED",
    `LAST-MODIFIED:${now}`,
    `SEQUENCE:${reschedule}`,
    `UID:${ticket?.key}@mywork.com`,
    `ATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=TRUE;CN=${ticket?.email};X-NUM-GUESTS=0:mailto:${ticket?.email}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ];
  return `data:text/calendar;charset=utf-8,${encodeURIComponent(
    ics_lines.join("\r\n")
  )}`;
};
