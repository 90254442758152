import React, { useCallback, useMemo } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined";
import ListIcon from "@mui/icons-material/List";

import { JiraProfile, Unit } from "../../../shared/types/Profile";
import { sxProps } from "./profile-units.style";
import { useTranslation } from "react-i18next";
import { NavigateButton } from "../../../shared/components";
import { TicketProvider } from "../../../shared/provider/ticketProvider";
import { useAppDispatch, useAppSelector } from "../../../shared/store/hooks";
import { selectLoading } from "../../../shared/store/loadingSlice";
import { useNavigate } from "react-router";
import { paths } from "../../../shared/routes/paths";
import { setTicketQuery } from "../../../shared/store/ticketSlice";

export type ProfileUnitsProps = {
  profile: JiraProfile;
};

const ticketProvider = new TicketProvider();

export const ProfileUnits = ({ profile }: ProfileUnitsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => selectLoading(state));

  const onShowRequests = useCallback(
    async (unit: Unit) => {
      const ticketQuery = {
        countryCode: unit.countryCode,
        address: unit.address,
      };
      dispatch(setTicketQuery(ticketQuery));
      await ticketProvider.queryTickets(ticketQuery);
      navigate(paths.dashboard);
    },
    [navigate, dispatch]
  );

  const units = useMemo(() => {
    return profile.units.map((unit) => {
      return (
        <Grid
          item
          container
          xs={6}
          key={unit.address}
          sx={sxProps.profileUnitsGridItem}
        >
          <Box sx={sxProps.profileUnitsIconWrapper}>
            <HomeWorkIcon sx={sxProps.profileUnitsIcon} />
          </Box>
          <Typography variant="body1" sx={sxProps.profileUnitsAddress}>
            {unit.address}
          </Typography>
          <Button
            disabled={isLoading}
            sx={sxProps.profileUnitsButton}
            fullWidth
            variant="contained"
            onClick={() => onShowRequests(unit)}
          >
            <ListIcon />
            {t("+profile.units.requests")}
          </Button>
          <NavigateButton
            buttonSx={sxProps.profileUnitsButton}
            labelKey="+profile.units.navigate"
            address={unit.address}
          />
        </Grid>
      );
    });
  }, [profile.units, t, isLoading, onShowRequests]);

  return (
    <Box sx={sxProps.profileUnitsBox}>
      <Typography variant="overline" sx={sxProps.profileUnitsLabel}>
        {t("+profile.units.label")}
      </Typography>
      <Grid container>{units}</Grid>
    </Box>
  );
};
