import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const attachmentSxProps: Record<string, SxProps<Theme>> = {
  attachmentBorder: {
    height: (theme) => theme.spacing(19.5),
    borderStyle: "solid",
    borderWidth: "3px",
    borderColor: (theme) => theme.palette.grey[300],
  },
};
