import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  notificationItemWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: (theme) => theme.spacing(2),
  },
  notificationIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: (theme) => theme.spacing(5),
    height: (theme) => theme.spacing(5),
    backgroundColor: (theme) => theme.palette.primary.main,
    borderRadius: "50%",
    color: (theme) => theme.palette.primary.contrastText,
  },
  notificationTextWrapper: {
    paddingLeft: (theme) => theme.spacing(2),
    textAlign: "left",
    width: "80%",
  },
  notificationLabel: {
    color: (theme) => theme.palette.secondary.main,
  },
};
