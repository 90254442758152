import { store } from "../store";
import { executeWithRefresh, getLoggedUser } from "./authProvider";
import {
  addNotifications,
  setAllNotificationAsRead,
  setNotificationAsRead,
} from "../store/notificationSlice";
import {
  NotificationServiceClient,
  GetAllNotificationsResponse,
} from "./clients/notification-service.client";
import { Notification } from "../../../generated/clients/notifications/data-contracts";
import { ValidNotificationTypes } from "../types/Notification";

export class NotificationProvider {
  private client: NotificationServiceClient;

  constructor() {
    this.client = new NotificationServiceClient();
  }

  fetchAllNotifications = async (params?: { page?: number }) => {
    const authUser = getLoggedUser();
    const getAllNotificationsResponse =
      await executeWithRefresh<GetAllNotificationsResponse>({
        authUser,
        func: this.client.getAllNotifications,
        args: [params?.page],
        silentFail: true,
      });
    if (!getAllNotificationsResponse) return;
    const { hasNextPage, nextPage, notifications } =
      getAllNotificationsResponse;
    const notificationsToDispatch = notifications
      ? //only accepts valid notification types for mywork mobile
        notifications.filter((n) => this.isTypeValid(n))
      : [];
    store.dispatch(
      addNotifications({
        notifications: notificationsToDispatch,
        hasNextPage,
        nextPage: nextPage ? nextPage : undefined,
      })
    );
  };

  markAsRead = async (notifications: Notification[]) => {
    const authUser = getLoggedUser();
    await Promise.all(
      notifications.map(async ({ id }) => {
        store.dispatch(setNotificationAsRead(id));
        await executeWithRefresh<void>({
          authUser,
          func: this.client.markNotificationAsRead,
          args: [id],
        });
      })
    );
  };

  markAllAsRead = async () => {
    const authUser = getLoggedUser();
    store.dispatch(setAllNotificationAsRead());
    await executeWithRefresh<void>({
      authUser,
      func: this.client.markAllNotificationsAsRead,
    });
  };

  private isTypeValid = (n: Notification) => {
    const type = n.metadata.type.toString() as ValidNotificationTypes;
    return Object.values(ValidNotificationTypes).includes(type);
  };
}
