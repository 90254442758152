export type Ticket = {
  id: string;
  key: string;
  requestType: {
    id: string;
    name: string;
    type: RequestTypesNamesEnum;
  };
  countryCode: ProjectCountryCode;
  description: string;
  summary: string;
  priority: TicketPriority;
  status: TicketStatus;
  bookingDate: Date;
  reschedulingCounter: number;
  created: Date;
  dueDate: Date;
  estimatedDate?: Date;
  streetAddress: string;
  fullAddress: string;
  apartmentNumber: string;
  failure?: Failure[];
  overDue: boolean;
  fullName: string;
  tenantId: string;
  email: string;
  mobile: string;
  attachments: Attachment[];
  commentCount: number;
  assignee: Assignee;
  reporter: { displayName: string };
};

export enum ProjectCountryCode {
  MTN = "NO",
  MTD = "DK",
  MTI = "IS",
}

export enum RequestTypesNamesEnum {
  OUTDOOR = "OUTDOOR",
  UNIT = "UNIT",
  COMMON = "COMMON",
  GENERAL = "GENERAL",
  COMPLAINTS = "COMPLAINTS",
  TERMINATION = "TERMINATION",
  CONTRACT = "CONTRACT",
}

export enum CustomerComplaintReason {
  NEIGHBOUR_COMPLAINT = "neighbourComplaint",
  COMPLAINT_REGARDING_HEIMSTADEN = "complaintRegardingHeimstaden",
  SMOKE_ODOR = "smokeOdor",
  SUGGESTIONS_FROM_IMPROVEMENTS = "suggestionForImprovements",
  NOISE_FROM_CONSTRUCTION_WORK = "noiseFromConstructionWork",
  GARBAGE = "grabage",
  OTHER = "other",
}

export type Attachment = {
  id: string;
  name: string;
  url: string;
};

export type Failure = {
  value: string;
  id: string;
};

export enum TicketStatus {
  OPEN = "Open",
  IN_PROGRESS = "In Progress",
  ON_HOLD = "On hold",
  AWAITING_TENANT = "Awaiting tenant",
  TASK_IS_SENT_TO_EXTERNAL_PROVIDER = "Task is sent to external provider",
  CLOSED = "Closed",
}

export enum TicketPriority {
  HIGHEST = "Highest",
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  LOWEST = "Lowest",
}

export type TicketCreator = {
  displayName: string;
  accountId: string;
};

export type GetTicketsResponse = {
  total: number;
  maxResults: number;
  startAt: number;
  tickets: Ticket[];
};

export type Filter = {
  status?: TicketStatus;
  isOverdue?: boolean;
};

export type AttachmentContent = {
  type: string;
  base64: string;
  userId?: string;
  appCreated?: boolean;
  url?: string;
  name?: string;
};

export type Message = {
  message: string;
  date: Date;
  isAuthor: boolean;
  isPublic: boolean;
  is3rdParty: boolean;
};

export type FailureData = { id: string; value: string };
export type TicketQuery = {
  query?: string;
  address?: string;
  countryCode?: string;
  status?: TicketStatus;
};

export type QueryTicketsResponse = {
  query?: string;
  tickets: Ticket[];
};

export type Assignee = {
  fullName: string;
};

export type UserSuggestionsQuery = {
  query?: string;
  ticketKey?: string;
};

export type GetUserSuggestionsData = {
  query?: string;
  ticketKey?: string;
  userSuggestions: UserSuggestion[];
};

export type UserSuggestion = {
  displayName: string;
  accountId: string;
  avatarUrls: {
    "48x48": string;
    "32x32": string;
    "24x24": string;
    "16x16": string;
  };
};

export type AddMessageRequestData = {
  message: string;
  isPublic: boolean;
  taggedUsers?: TaggedUser[];
};

export type TaggedUser = { userId: string; text: string };
export type AttachmentForUpload = { value: string; name: string };
