import React, { createRef, useEffect } from "react";
import { Box } from "@mui/material";

import { DashboardFilter } from "../DashboardFilter";
import { DashboardTicketList } from "../DashboardTicketList";
import { sxProps } from "./dashboard-screen.style";
import { SearchBox } from "../SearchBox";
import { TicketProvider } from "../../../shared/provider/ticketProvider";
import { LocationChip } from "../LocationChip";

const scrollableRef = createRef<HTMLUListElement>();
const ticketProvider = new TicketProvider();

export const DashboardScreen = () => {
  //when component is mounted, set scroll to hide input field
  useEffect(() => {
    if (scrollableRef.current) scrollableRef.current.scrollTo({ top: 68 });
  }, []);

  return (
    <Box>
      <DashboardFilter />
      <Box sx={sxProps.dashboardListWrapper} ref={scrollableRef}>
        <SearchBox ticketProvider={ticketProvider} />
        <LocationChip />
        <DashboardTicketList ticketProvider={ticketProvider} />
      </Box>
    </Box>
  );
};
