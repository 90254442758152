import React, { createContext } from "react";
import { Outlet, useParams } from "react-router";
import { Box } from "@mui/material";

import { ServiceRequestNavbar } from "../ServiceRequestNavbar";
import { Ticket } from "../../../shared/types/Ticket";
import { TicketContextProvider } from "../../ticketContextProvider";
import { sxProps } from "./service-request-page.style";

export const Context = createContext<Ticket | undefined>(undefined);

export const TicketDetailsContext = React.createContext<Ticket | undefined>(
  undefined
);

export const ServiceRequestPage = () => {
  const { ticketKey } = useParams();

  return (
    <Box sx={sxProps.serviceRequestWrapper}>
      {/* we are using context here, because we cannot pass ticket as a props to outlet.
       */}
      <TicketContextProvider ticketKey={ticketKey}>
        <ServiceRequestNavbar serviceRequestKey={ticketKey} />
        <Outlet />
      </TicketContextProvider>
    </Box>
  );
};
