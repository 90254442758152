import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardTicketListItemStatusIndicator: {
    maxWidth: (theme) => theme.spacing(1),
    padding: (theme) => theme.spacing(1, 1, 1, 0),
    top: (theme) => theme.spacing(1),
    right: (theme) => theme.spacing(1),
    bottom: (theme) => theme.spacing(1),
    position: "absolute",
    borderRadius: (theme) => theme.spacing(0.75),
  },
};
