import { AxiosRequestConfig } from "axios";
import { AuthApi, AuthenticationTypeEnum } from "../../../../connectors/user";
import { AuthType, AuthUser } from "../../types/AuthUser";
import { getEnvValue } from "../../utils/env.helper";
import { createInstance } from "../../utils/axios.helper";

export class AuthClient {
  private client;

  constructor() {
    const path = getEnvValue("MIE_API_PATH");
    const miaInstance = createInstance({
      headers: { "Content-Type": "application/json" },
    });
    this.client = new AuthApi(undefined, path, miaInstance);
  }

  //this is the first step in multistep authentication which will redirect
  //to azure login form. After success authentication there, user will be redirected
  //back to the app to `redirectUri`
  public loginByProvider = (authType: AuthenticationTypeEnum) => {
    const provider = authType.toUpperCase();
    const authorizeUrl = getEnvValue(`${provider}_AUTHORIZE_URL`);
    const redirectUri = window.origin + authorizeUrl;

    const origin = getEnvValue("MIE_API_PATH");
    const url = origin + authorizeUrl;
    window.location.href = `${url}?redirect_uri=${redirectUri}`;
  };

  //this is the second step in multistep authentication which will be triggered
  //by user returning to app from azure login page. Url will contain parameteres
  //which will be send back to Mia api and exchanged for token,
  //which can be later used for authenticationg user.
  public authorize = async (
    authType: AuthenticationTypeEnum,
    requestConfig: AxiosRequestConfig
  ) => {
    const response = await this.client.tokenAuthAuthTypeTokenGet(
      authType,
      requestConfig
    );
    const { access_token, refresh_token, id_token } = response.data;
    const authUser: AuthUser = {
      type: AuthType.AZURE,
      accessToken: access_token,
      refreshToken: refresh_token,
      idToken: id_token,
    };
    return authUser;
  };

  //when access token is expired, this action is used to refresh it
  public refresh = async (authUser: AuthUser) => {
    try {
      const response = await this.client.tokenRefreshAuthRefreshGet(
        authUser.refreshToken,
        authUser.idToken
      );
      const { access_token, id_token } = response.data;
      return { accessToken: access_token, idToken: id_token };
    } catch (error) {
      return undefined;
    }
  };

  public logout = async (authType?: AuthType) => {
    try {
      switch (authType) {
        case AuthType.AZURE:
          return await this.client.azureLogoutAuthAuthTypeLogoutGet(
            AuthenticationTypeEnum.Azure
          );
        case AuthType.EMAIL_AND_PASSWORD:
          return await this.client.azureLogoutAuthAuthTypeLogoutGet(
            AuthenticationTypeEnum.Internal
          );
      }
      //fails silently
    } catch {}
  };
}
