import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  loaderWrapper: {
    width: "100%",
    height: "4px",
    display: "block",
  },
};
