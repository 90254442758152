import React, { MouseEvent, ReactNode, useState } from "react";
import {
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useTranslation } from "react-i18next";

import { sxProps } from "../../select-status.style";
import { TicketStatus } from "../../../../types/Ticket";
import { getPossibleTransition, statusSelectPickerItems } from "../../util";
import { StatusMenuItem } from "../../components/StatusMenuItem";
import { useAppSelector } from "../../../../store/hooks";
import { selectWorkflow } from "../../../../store/ticketSlice";

export type SelectStatusTransition = {
  status: TicketStatus;
  transitionId: number;
};

export type SelectStatusPickerProps = {
  selectedStatus: TicketStatus;
  setSelectedStatus: (transition: SelectStatusTransition) => void;
  disabled: boolean;
  alignListSx?: SxProps<Theme>;
};

export const SelectStatusPicker = ({
  disabled,
  setSelectedStatus,
  selectedStatus,
  alignListSx,
}: SelectStatusPickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const workflow = useAppSelector((state) => selectWorkflow(state));

  const handleChange = (e: SelectChangeEvent<number>) => {
    if (!workflow) return;
    const index = e.target.value;
    const newStatus = statusSelectPickerItems[index as number].status;
    const transition = getPossibleTransition(
      workflow,
      selectedStatus,
      newStatus
    );
    if (transition) setSelectedStatus(transition);
  };

  const getMenuItemFromStatus = () => {
    let index = statusSelectPickerItems.findIndex(
      (item) => item.status === selectedStatus
    );
    return { index, menuItem: statusSelectPickerItems[index] };
  };

  const renderSelectMenu = () => {
    if (!workflow) return;
    let selectMenu: ReactNode[] = [];
    statusSelectPickerItems.forEach(({ status, sx, labelKey }, i) => {
      //if select component is status changer, we only want to render menu items,
      //that represent status (and not All tickets from dashboard select component)
      const isSelected = status === selectedStatus;
      const isHidden = !getPossibleTransition(workflow, selectedStatus, status);
      selectMenu = [
        ...selectMenu,
        <MenuItem
          value={i}
          key={`select-menu-picker-${i * 2}`}
          sx={isHidden ? sxProps.hiddenItem : sxProps.menuItem}
        >
          <StatusMenuItem
            label={t(labelKey)}
            status={status}
            selected={isSelected}
            showCircles={!isSelected}
          />
        </MenuItem>,
        <Divider
          orientation="horizontal"
          variant="middle"
          key={`select-menu-picker-${i * 2 + 1}`}
          sx={isHidden ? sxProps.hiddenItem : sxProps.divider}
        />,
      ];
    });
    return selectMenu;
  };

  //need to handle opening and closing programatically
  //because part of app is not clickable, and disable
  //click when divider is cliced
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    if (
      !disabled &&
      e.target instanceof Element &&
      !e.target.classList.contains("MuiDivider-root")
    ) {
      setOpen(!open);
    }
  };

  return (
    <Select
      onClick={onClick}
      variant="filled"
      MenuProps={{
        sx: {
          ...sxProps.statusSelectMenu,
          ...(alignListSx ? alignListSx : {}),
        },
      }}
      inputProps={{
        sx: sxProps.statusSelectInput,
      }}
      sx={{
        ...sxProps.statusSelectColored,
        ...getMenuItemFromStatus().menuItem.sx,
      }}
      disableUnderline
      value={getMenuItemFromStatus().index}
      onChange={handleChange}
      open={open}
    >
      {renderSelectMenu()}
    </Select>
  );
};
