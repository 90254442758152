import React, { useMemo } from "react";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

import { TicketStatus } from "../../../../../shared/types/Ticket";
import { sxProps } from "./list-item-status-indicator.style";
import { globalSxProps } from "../../../../../shared/style";

export type ListItemStatusIndicatorProps = {
  status: TicketStatus;
};

export const ListItemStatusIndicator = ({
  status,
}: ListItemStatusIndicatorProps) => {
  //depending of ticket status different background collor is given to status indicator
  const statusIndicatorSxProps = useMemo<SxProps<Theme>>(() => {
    switch (status) {
      case TicketStatus.OPEN:
        return {
          ...sxProps.dashboardTicketListItemStatusIndicator,
          ...globalSxProps.statusOpen,
        };
      case TicketStatus.IN_PROGRESS:
        return {
          ...sxProps.dashboardTicketListItemStatusIndicator,
          ...globalSxProps.statusInProgress,
        };
      case TicketStatus.CLOSED:
        return {
          ...sxProps.dashboardTicketListItemStatusIndicator,
          ...globalSxProps.statusClosed,
        };
      case TicketStatus.ON_HOLD:
        return {
          ...sxProps.dashboardTicketListItemStatusIndicator,
          ...globalSxProps.statusOnHold,
        };
      case TicketStatus.AWAITING_TENANT:
        return {
          ...sxProps.dashboardTicketListItemStatusIndicator,
          ...globalSxProps.statusAwaitingTenant,
        };
      case TicketStatus.TASK_IS_SENT_TO_EXTERNAL_PROVIDER:
        return {
          ...sxProps.dashboardTicketListItemStatusIndicator,
          ...globalSxProps.statusAwaitingExternal,
        };
      default:
        return sxProps.dashboardTicketListItemStatusIndicator;
    }
  }, [status]);

  return (
    <Box
      sx={statusIndicatorSxProps}
      bgcolor={(theme) => theme.palette.success.main}
    />
  );
};
