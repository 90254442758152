import React from "react";
import { Box, Typography } from "@mui/material";

import { JiraProfile } from "../../../shared/types/Profile";
import { sxProps } from "./profile-details.style";

export type ProfileDetailsProps = {
  profile: JiraProfile;
};

export const ProfileDetails = ({ profile }: ProfileDetailsProps) => {
  return (
    <Box sx={sxProps.profileDetailsBox}>
      <Typography variant="h1">{profile.displayName}</Typography>
      <Typography variant="body1" sx={sxProps.profileDetailsEmail}>
        {profile.email}
      </Typography>
    </Box>
  );
};
