import React, { useState, useEffect } from "react";
import { Box, Theme } from "@mui/material";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import { getEnvValue } from "../../utils/env.helper";
import { SxProps } from "@mui/system";
import { sxProps } from "./show-location-map.style";
import { getCoordinatesForLocation } from "./util";
import { NavigateButton } from "./components/NavigateButton";
import { postalCodes } from "./postal-codes-map";

export type ShowLocationMapType = {
  address: string;
  containerSx: SxProps<Theme>;
};

export const ShowLocationMap = ({
  address,
  containerSx,
}: ShowLocationMapType) => {
  const [center, setCenter] = useState({ lat: 59.91, lng: 10.75 });
  const [marker, setMarker] = useState<{ lat: number; lng: number }>();
  const [accurateAddress, setAccurateAddress] = useState(address);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: getEnvValue("GOOGLE_API_KEY"),
  });

  useEffect(() => {
    const property = postalCodes.find(
      (property) => property.propertyName.trim() === address.trim()
    );
    if (property) {
      setAccurateAddress(`${address} ${property.city} ${property.postalCode} `);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCoordinatesForLocation(accurateAddress).then((coordinates) => {
      if (coordinates) {
        setCenter(coordinates);
        setMarker(coordinates);
      }
    });
  }, [isLoaded, accurateAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMarker = () => {
    return marker ? <Marker position={marker} /> : <></>;
  };

  const renderNavigateButton = () => {
    return (
      <NavigateButton
        labelKey="+serviceRequest.details.actions.navigate"
        buttonSx={sxProps.navigateButton}
        address={address}
      />
    );
  };

  return isLoaded ? (
    <Box sx={{ ...sxProps.mapContainer, ...containerSx }}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={center}
        zoom={14}
        options={{
          streetViewControl: false,
          zoomControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {renderMarker()}
      </GoogleMap>
      {renderNavigateButton()}
    </Box>
  ) : (
    <></>
  );
};
