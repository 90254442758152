import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  modal: {
    margin: "3vh 5vw 3vh 5vw",
  },

  modalContent: {
    position: "relative",
    maxHeight: "94vh",
    overflowY: "scroll",
  },

  modalLabel: {
    color: (theme) => theme.palette.primary.main,
  },

  modalTitle: {
    marginBottom: (theme) => theme.spacing(3),
  },

  cancelButton: {
    marginLeft: (theme) => theme.spacing(2),
  },

  confirmButton: {
    marginRight: (theme) => theme.spacing(2),
  },

  buttonIcon: {
    marginRight: (theme) => theme.spacing(1),
  },

  inputField: {
    marginBottom: (theme) => theme.spacing(2),
  },

  messageFormControl: {
    width: "100%",
  },

  messageButtons: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: (theme) => theme.spacing(1, 0),
  },

  messageTextArea: {
    marginBottom: (theme) => `-${theme.spacing(2)}`,
  },

  timeSpentBox: {
    display: "flex",
    padding: (theme) => theme.spacing(2, 0),
  },

  timeInput: {
    marginRight: (theme) => theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
  },
};
