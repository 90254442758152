import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttachmentContent, Ticket } from "../types/Ticket";
import type { RootState } from "./index";

export type AttachmentRecord = Record<string, AttachmentContent>;
export type AttachmentsState = {
  attachments: Record<string, AttachmentRecord>;
};

const initialState: AttachmentsState = {
  attachments: {},
};

//attachment state will contain all attachments that are donwloaded from jira
const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    addAttachmentRecord: (
      state,
      action: PayloadAction<{
        ticketId: string;
        attachmentRecord: AttachmentRecord;
      }>
    ) => {
      const { ticketId, attachmentRecord } = action.payload;
      const newAttachments = { ...state.attachments };
      const newRecord = {
        ...(newAttachments[ticketId] || {}),
        ...attachmentRecord,
      };
      newAttachments[ticketId] = newRecord;
      state.attachments = newAttachments;
    },
    removeAttachments: (
      state,
      action: PayloadAction<{
        ticketId: string;
        attachmentIds: string[];
      }>
    ) => {
      const { ticketId, attachmentIds } = action.payload;
      const newAttachments = { ...state.attachments };
      const newRecord = {
        ...(newAttachments[ticketId] || {}),
      };
      for (const id of attachmentIds) {
        delete newRecord[id];
      }
      newAttachments[ticketId] = newRecord;
      state.attachments = newAttachments;
    },
  },
});

export const { addAttachmentRecord, removeAttachments } =
  attachmentsSlice.actions;
export const selectAttachmentsContentMapForTicket = (
  state: RootState,
  ticket: Ticket | undefined
) => (ticket ? state.attachments.attachments[ticket.id] : undefined);

export default attachmentsSlice.reducer;
