import React, {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { Grid, Box, Typography, SvgIconTypeMap, Button } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { sxProps } from "./detail-information-appointment.style";
import { useTranslation } from "react-i18next";
import { AppointmentModal } from "./AppointmentModal";

export type IconType =
  | React.FC<React.SVGProps<SVGSVGElement>>
  | ((props: ComponentProps<"svg">) => JSX.Element)
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>;

export type DetailInformationAppointmentProps = {
  label: string;
  information?: string;
  appointment: Date;
  setAppointment: Dispatch<SetStateAction<Date>>;
  icon: IconType;
};

export const DetailInformationAppointment = ({
  label,
  information,
  appointment,
  setAppointment,
  icon,
}: DetailInformationAppointmentProps) => {
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const { t } = useTranslation();
  const scheduleAppointment = () => {
    setAppointmentModalOpen(true);
  };

  if (!information) {
    return (
      <Grid
        container
        key={label}
        sx={sxProps.detailInformationAppointmentGridContainer}
      >
        <Grid item>
          <Box component={icon} sx={sxProps.detailInformationAppointmentIcon} />
        </Grid>
        <Grid item>
          <Button
            onClick={scheduleAppointment}
            color="primary"
            variant="outlined"
            sx={sxProps.serviceRequestAppointmentButton}
          >
            {t("+serviceRequest.details.scheduleAppointment")}
          </Button>
          <AppointmentModal
            open={appointmentModalOpen}
            setOpen={setAppointmentModalOpen}
            appointment={appointment}
            setAppointment={setAppointment}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      key={label}
      sx={sxProps.detailInformationAppointmentGridContainer}
    >
      <Grid item>
        <Box component={icon} sx={sxProps.detailInformationAppointmentIcon} />
      </Grid>
      <Grid item>
        <Typography
          variant="overline"
          sx={sxProps.detailInformationAppointmentLabel}
        >
          {label}
        </Typography>
        <Typography
          variant="body1"
          sx={sxProps.detailInformationAppointmentText}
        >
          {information}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={scheduleAppointment}
          color="primary"
          variant="outlined"
          sx={sxProps.serviceRequestAppointmentButton}
        >
          {t("+serviceRequest.details.rescheduleAppointment")}
        </Button>
        <AppointmentModal
          open={appointmentModalOpen}
          setOpen={setAppointmentModalOpen}
          appointment={appointment}
          setAppointment={setAppointment}
        />
      </Grid>
    </Grid>
  );
};
