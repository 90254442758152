import { store } from "../store";
import { setLoading } from "../store/loadingSlice";
import {
  Ticket,
  UserSuggestionsQuery,
  GetUserSuggestionsData,
} from "../types/Ticket";
import { JiraServiceClient } from "./clients/jira-service.client";
import { executeWithRefresh, getLoggedUser } from "./authProvider";
import { addMessages } from "../store/messageSlice";
import { storeMentions } from "../store/mentionSlice";

export class MessagesProvider {
  private client: JiraServiceClient;

  constructor() {
    this.client = new JiraServiceClient();
  }

  fetchMessages = async (ticket: Ticket) => {
    const authUser = getLoggedUser();
    store.dispatch(setLoading(true));
    const messages = await executeWithRefresh({
      authUser,
      func: this.client.getMessages,
      args: [ticket.id],
    });
    if (messages) {
      store.dispatch(addMessages({ ticketId: ticket.id, messages }));
    }
    store.dispatch(setLoading(false));
  };

  sendMessage = async (ticket: Ticket, message: string, isPublic: boolean) => {
    const authUser = getLoggedUser();
    store.dispatch(setLoading(true));
    await executeWithRefresh<void>({
      authUser,
      func: this.client.postMessage,
      args: [ticket.id, message, isPublic],
    });
    await this.fetchMessages(ticket);
    store.dispatch(setLoading(false));
  };

  fetchSuggestedMentions = async (query: UserSuggestionsQuery) => {
    const authUser = getLoggedUser();
    const response = await executeWithRefresh<GetUserSuggestionsData>({
      authUser,
      func: this.client.getSuggestions,
      args: [query],
    });
    if (response && store.getState().mention.query === response.query) {
      store.dispatch(storeMentions(response.userSuggestions));
    }
    store.dispatch(setLoading(false));
  };

  fetchMentions = async (query: UserSuggestionsQuery) => {
    const authUser = getLoggedUser();
    const response = await executeWithRefresh<GetUserSuggestionsData>({
      authUser,
      func: this.client.getSuggestions,
      args: [query],
    });
    if (response) {
      return response.userSuggestions;
    }
  };
}
