import axios from "axios";

import { getEnvValue } from "../../utils/env.helper";
import {
  AttachmentContent,
  AttachmentForUpload,
  GetTicketsResponse,
  GetUserSuggestionsData,
  Message,
  QueryTicketsResponse,
  TaggedUser,
  Ticket,
  TicketQuery,
  TicketStatus,
  UserSuggestionsQuery,
} from "../../types/Ticket";
import { JiraProfile } from "../../types/Profile";
import { Workflow } from "../../types/Workflow";

import { createInstance } from "../../utils/axios.helper";
import { handleError } from "../../utils/error.helper";
import { RequestsTypes } from "../../types/Requests";

export class JiraServiceClient {
  private client;

  constructor() {
    this.client = createInstance();
  }

  public getTickets = async (
    bearerToken: string,
    status?: TicketStatus,
    offset?: number
  ) => {
    const response = await this.client
      .get<GetTicketsResponse>("/mywork/tickets", {
        params: {
          status,
          offset,
        },
        ...this.getConfig(bearerToken),
      })
      .catch((err) => {
        handleError(RequestsTypes.GET_TICKET, err.response.status);
        throw err;
      });
    return response.data;
  };

  public queryTickets = async (bearerToken: string, params: TicketQuery) => {
    const response = await this.client
      .get<QueryTicketsResponse>("/mywork/queryTickets", {
        params,
        ...this.getConfig(bearerToken),
      })
      .catch((err) => {
        handleError(RequestsTypes.GET_TICKET, err.response.status);
        throw err;
      });
    return response.data;
  };

  public getTicket = async (bearerToken: string, key: string) => {
    const response = await this.client
      .get<Ticket | undefined>(
        `/mywork/ticket/${key}`,
        this.getConfig(bearerToken)
      )
      .catch((err) => {
        handleError(RequestsTypes.GET_TICKET, err.response.status);
        throw err;
      });
    return response.data;
  };

  public getAttachment = async (bearerToken: string, url: string) => {
    const response = await this.client
      .get<AttachmentContent>(url, this.getHeaders(bearerToken))
      .catch((err) => {
        handleError(RequestsTypes.GET_ATTACHMENT, err.response.status);
        throw err;
      });
    return response.data;
  };

  public postAttachment = async (
    bearerToken: string,
    ticketId: string,
    attachments: AttachmentForUpload[]
  ) => {
    await this.client
      .post(
        `/mywork/ticket/${ticketId}/attachment`,
        { attachments },
        this.getConfig(bearerToken)
      )
      .catch((err) => {
        handleError(RequestsTypes.GET_ATTACHMENT, err.response.status);
        throw err;
      });
    return;
  };

  public postBookingDate = async (
    bearerToken: string,
    ticketId: string,
    date: Date,
    reschedule: number
  ) => {
    await this.client
      .post(
        `/mywork/ticket/${ticketId}/booking`,
        { date, reschedule },
        this.getConfig(bearerToken)
      )
      .catch((err) => {
        handleError(RequestsTypes.POST_BOOKING, err.response.status);
        throw err;
      });
    return;
  };

  public getMessages = async (bearerToken: string, ticketId: string) => {
    const response = await this.client
      .get<Message[]>(
        `/mywork/ticket/${ticketId}/message`,
        this.getConfig(bearerToken)
      )
      .catch((err) => {
        handleError(RequestsTypes.GET_MESSAGE, err.response.status);
        throw err;
      });
    return response.data;
  };

  public postMessage = async (
    bearerToken: string,
    ticketId: string,
    message: string,
    isPublic: boolean,
    taggedUsers: TaggedUser[]
  ) => {
    await this.client
      .post(
        `/mywork/ticket/${ticketId}/message`,
        { message, isPublic, taggedUsers },
        this.getConfig(bearerToken)
      )
      .catch((err) => {
        handleError(RequestsTypes.POST_MESSAGE, err.response.status);
        throw err;
      });
    return;
  };

  public getWorkflow = async (bearerToken: string) => {
    const response = await this.client
      .get<Workflow>(`/mywork/workflow`, this.getConfig(bearerToken))
      .catch((err) => {
        handleError(RequestsTypes.GET_WORKFLOW, err.response.status);
        throw err;
      });
    return response.data;
  };

  //change status might encounter an error 405, method forbidden
  //which means that additional data is required for this transition,
  //and form should be show to the user.
  //if nothing is returned, this method is successfully executed
  public changeStatus = async (
    bearerToken: string,
    ticketId: string,
    transitionId: number
  ) => {
    try {
      await this.client.post(
        `/mywork/ticket/${ticketId}/transition`,
        { transitionId },
        this.getConfig(bearerToken)
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        let status = error.response?.status;
        if (status === 405 && error.response?.data) {
          return error.response.data;
        }
      }
      throw error;
    }
  };

  public changeStatusWithTransitionData = async (
    bearerToken: string,
    url: string,
    transitionId: number,
    transitionData: any
  ) => {
    await this.client
      .post(url, { transitionId, transitionData }, this.getConfig(bearerToken))
      .catch((err) => {
        handleError(
          RequestsTypes.TRANSITION_TO_STATUS,
          err.response.status,
          err.response.data.message
        );
        throw err;
      });
  };

  public getJiraProfile = async (bearerToken: string) => {
    const response = await this.client
      .get<JiraProfile>(`/mywork/profile`, this.getConfig(bearerToken))
      .catch((err) => {
        handleError(RequestsTypes.GET_PROFILE, err.response.status);
        throw err;
      });
    return response.data;
  };

  public getSuggestions = async (
    bearerToken: string,
    params: UserSuggestionsQuery
  ) => {
    const response = await this.client
      .get<GetUserSuggestionsData>("/mywork/user/suggestions", {
        params,
        ...this.getConfig(bearerToken),
      })
      .catch((err) => {
        handleError(RequestsTypes.QUERY_USER_SUGGESTION, err.response.status);
        throw err;
      });
    return response.data;
  };

  private getConfig = (bearerToken: string) => {
    const path = getEnvValue("JIRA_SERVICE_API_PATH");
    return {
      baseURL: path,
      ...this.getHeaders(bearerToken),
    };
  };

  private getHeaders = (bearerToken: string) => {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    };
  };
}
