import React from "react";
import { Grid } from "@mui/material";

import { sxProps } from "./dashboard-filter.style";
import {
  selectFilter,
  setStatusFilter,
} from "../../../shared/store/ticketSlice";
import { useAppDispatch, useAppSelector } from "../../../shared/store/hooks";
import { DashboardFilterCheckbox } from "./components/DashboardFilterCheckbox";
import { selectLoading } from "../../../shared/store/loadingSlice";
import { SelectStatusFilter } from "../../../shared/components";

export const DashboardFilter = () => {
  const filter = useAppSelector((state) => selectFilter(state));
  const isLoading = useAppSelector((state) => selectLoading(state));
  const dispatch = useAppDispatch();

  return (
    <Grid container sx={sxProps.dashboardFilter}>
      <Grid item container sx={sxProps.dashboardFilterSelect}>
        <SelectStatusFilter
          disabled={isLoading}
          setSelectedStatus={(status) => {
            dispatch(setStatusFilter(status));
          }}
          selectedStatus={filter.status}
          alignListSx={sxProps.alignSelectList}
        />
      </Grid>
      <Grid item container sx={sxProps.dashboardFilterCheckbox}>
        <DashboardFilterCheckbox selected={Boolean(filter.isOverdue)} />
      </Grid>
    </Grid>
  );
};
