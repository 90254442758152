import { store } from "../store";
import { setLoading } from "../store/loadingSlice";

import {
  addTickets,
  selectHasMore,
  selectWorkflow,
  setQueriedTickets,
  setWorkflow,
} from "../store/ticketSlice";
import {
  Filter,
  GetTicketsResponse,
  QueryTicketsResponse,
  Ticket,
  TicketQuery,
} from "../types/Ticket";
import { Workflow, Transition, TransitionFormFormat } from "../types/Workflow";

import { JiraServiceClient } from "./clients/jira-service.client";
import { executeWithRefresh, getLoggedUser } from "./authProvider";
import { SanityProvider } from "./sanityProvider";
import { isQuerySet } from "../utils/ticket.helper";

const minNumberOfTicketPerCategory = 5;

export class TicketProvider {
  private client: JiraServiceClient;
  private sanityProvider: SanityProvider;

  constructor() {
    this.client = new JiraServiceClient();
    this.sanityProvider = new SanityProvider();
  }

  fetchTicket = async (key: string, params?: { silentFail?: boolean }) => {
    const authUser = getLoggedUser();
    await this.fetchWorkflow();
    store.dispatch(setLoading(true));
    const ticket = await executeWithRefresh<Ticket | undefined>({
      authUser,
      func: this.client.getTicket,
      args: [key],
      silentFail: params?.silentFail,
    });
    if (ticket) {
      await this.sanityProvider.fetchRequestTypeAndFailures([ticket]);
      store.dispatch(addTickets({ tickets: [ticket] }));
    }
    store.dispatch(setLoading(false));
    return ticket;
  };

  //method for fetching tickets
  fetchTickets = async ({ status }: Filter, offset?: number) => {
    if (
      !selectHasMore(store.getState(), status) ||
      isQuerySet(store.getState().ticket.ticketQuery)
    )
      return;
    const authUser = getLoggedUser();
    await this.fetchWorkflow();
    store.dispatch(setLoading(true));
    const response = await executeWithRefresh<GetTicketsResponse>({
      authUser,
      func: this.client.getTickets,
      args: [status, offset],
    });
    if (response) {
      const { tickets, total } = response;
      await this.sanityProvider.fetchRequestTypeAndFailures(tickets);
      if (tickets) store.dispatch(addTickets({ tickets, total, status }));
    }
    store.dispatch(setLoading(false));
  };

  //method for fetching all tickets with some query, or by address
  //used in search box and profile page
  queryTickets = async (query: TicketQuery) => {
    const authUser = getLoggedUser();
    await this.fetchWorkflow();
    store.dispatch(setLoading(true));
    const response = await executeWithRefresh<QueryTicketsResponse>({
      authUser,
      func: this.client.queryTickets,
      args: [query],
    });
    if (
      response &&
      store.getState().ticket.ticketQuery?.query === response.query
    ) {
      store.dispatch(setQueriedTickets(response.tickets));
    }
    store.dispatch(setLoading(false));
  };

  onFilterChange = (filter: Filter, filteredTickets: Ticket[]) => {
    //if ticket query is set, then on filter change we should query based on this information
    const ticketQuery = store.getState().ticket.ticketQuery;
    if (isQuerySet(ticketQuery)) {
      return this.queryTickets(ticketQuery!);
    }

    //if there is too few tickets in specific category, we will force loading of more tickets
    if (
      filteredTickets.length < minNumberOfTicketPerCategory &&
      filter.status
    ) {
      this.fetchTickets(filter, filteredTickets.length);
    }
  };

  changeTicketStatus = async (ticket: Ticket, transition: Transition) => {
    store.dispatch(setLoading(true));
    const authUser = getLoggedUser();
    const response = await executeWithRefresh<TransitionFormFormat | undefined>(
      {
        authUser,
        func: this.client.changeStatus,
        args: [ticket.id, transition.transitionId],
      }
    );
    store.dispatch(setLoading(false));
    return response;
  };

  changeTicketStatusWithTransitionData = async (
    url: string,
    transition: Transition,
    transitionData: any
  ) => {
    store.dispatch(setLoading(true));
    const authUser = getLoggedUser();
    const response = await executeWithRefresh<void>({
      authUser,
      func: this.client.changeStatusWithTransitionData,
      args: [url, transition.transitionId, transitionData],
    });
    store.dispatch(setLoading(false));
    return response;
  };

  fetchWorkflow = async () => {
    //if workflow is not loaded in application state, laod it
    if (!selectWorkflow(store.getState())) {
      const authUser = getLoggedUser();
      store.dispatch(setLoading(true));
      const response = await executeWithRefresh<Workflow>({
        authUser,
        func: this.client.getWorkflow,
      });
      if (response) {
        store.dispatch(setWorkflow(response));
      }
      store.dispatch(setLoading(false));
    }
  };

  setBookingDate = async (ticket: Ticket, date: string, reschedule: number) => {
    const authUser = getLoggedUser();
    store.dispatch(setLoading(true));
    await executeWithRefresh<void>({
      authUser,
      func: this.client.postBookingDate,
      args: [ticket.id, date, reschedule],
    });
    store.dispatch(setLoading(false));
  };
}
