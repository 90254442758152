import { store } from "../store";
import { addFailures } from "../store/ticketSlice";
import { Ticket } from "../types/Ticket";
import { SanityClient } from "./clients/sanity.client";

export class SanityProvider {
  private client: SanityClient;

  constructor() {
    this.client = new SanityClient();
  }

  fetchRequestTypeAndFailures = async (tickets: Ticket[]) => {
    const ids = this.extractAreasAndFailures(tickets);
    await Promise.all([
      this.fetchRequestType(ids.areaIds),
      this.fetchFailures(ids.failureIds),
    ]);
  };

  private fetchFailures = async (failureIds: string[]) => {
    const failures = await this.client.fetchFailures(failureIds);
    store.dispatch(addFailures(failures));
  };

  private fetchRequestType = async (areaIds: string[]) => {
    await Promise.all(
      areaIds.map(async (areaId) => {
        const failure = await this.client.fetchRequestType(areaId);
        if (!failure) return;
        store.dispatch(addFailures([failure]));
      })
    );
  };

  private extractAreasAndFailures = (tickets: Ticket[]) => {
    //creating two arrays, one for areas, which are fetched one by one
    //and second one is for failures, which are fetched in bulk
    const areaIds: string[] = [];
    const failureIds: string[] = [];
    tickets.forEach((t) => {
      if (!t.failure || !Array.isArray(t.failure) || t.failure.length === 0)
        return;
      //add only first id to area ids
      areaIds.push(t.failure[0].id);
      //add all ids except first one to failure ids
      failureIds.push(...t.failure.filter((f, i) => i !== 0).map((f) => f.id));
    });
    return { areaIds, failureIds };
  };
}
