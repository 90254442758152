import React from "react";
import { CardContent, Typography, CardActions } from "@mui/material";
import { useTranslation } from "react-i18next";

import { loginAzure } from "../../../shared/provider/authProvider";
import { setLoading } from "../../../shared/store/loadingSlice";
import { useAppDispatch } from "../../../shared/store/hooks";
import { MicrosoftButton } from "../../../shared/components/FormComponents";
import { globalSxProps } from "../../../shared/style";
import { sxProps } from "./welcome-card-content.style";

export const WelcomeCardContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const login = async () => {
    dispatch(setLoading(true));
    await loginAzure();
  };

  return (
    <>
      <CardContent>
        <Typography gutterBottom variant="h1" component="div">
          {t("+login.welcomeCard.title")}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={sxProps.welcomeTextPadding}
        >
          {t("+login.welcomeCard.message.firstPart")}
          <Typography sx={globalSxProps.textBold} display="inline">
            {t("+login.welcomeCard.message.secondPart")}
          </Typography>
          .
        </Typography>
      </CardContent>
      <CardActions>
        <MicrosoftButton onClick={login} />
      </CardActions>
    </>
  );
};
