import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  detailInformationsGridContainer: {
    padding: (theme) => theme.spacing(0.75, 0),
  },

  detailInformationLabel: {
    color: (theme) => theme.palette.grey[500],
  },

  detailInformationText: {
    marginTop: "-5px",
  },

  detailInformationError: {
    color: (theme) => theme.palette.error.main,
  },

  detailInformationIcon: {
    color: (theme) => theme.palette.common.black,
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(1.25, 1.125, 0, 0),
    "& path": {
      strokeWidth: "1.5px",
      stroke: "currentcolor",
    },
  },
};
