import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { sxProps } from "./spinner.style";

export const Spinner = () => {
  return (
    <Box sx={sxProps.spinnerWrapper}>
      <CircularProgress />
    </Box>
  );
};
