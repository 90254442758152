import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

import { globalSxProps } from "../../../../style/globalStyles";

export const sxProps: Record<string, SxProps<Theme>> = {
  menuItem: {
    padding: (theme) => theme.spacing(0.2, 0, 0, 1.5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  menuItemText: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "250%",
    paddingLeft: (theme) => theme.spacing(1),
  },

  selectedMenuItemText: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: "150%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: (theme) => theme.spacing(20),
    paddingLeft: (theme) => theme.spacing(1),
  },

  menuItemLabel: {
    color: (theme) => theme.palette.primary.contrastText,
    "&.Mui-focused": {
      color: (theme) => theme.palette.primary.contrastText,
    },
  },

  menuItemSingleCircleBox: {
    padding: (theme) => theme.spacing(0, 0.5, 0.1, 1.25),
  },

  menuItemAllCirclesBox: {
    padding: (theme) => theme.spacing(0, 0.5, 0.1, 0.5),
  },

  menuItemCircle: {
    width: (theme) => theme.spacing(1.25),
    height: (theme) => theme.spacing(1.25),
    borderRadius: "50%",
  },

  allCircles: {
    display: "inline",
    position: "absolute",
  },

  allCirclesContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: (theme) => theme.spacing(2),
  },

  openCircle: {
    zIndex: 1,
    left: (theme) => theme.spacing(0.25),
    ...globalSxProps.statusOpen,
  },
  inProgressCircle: {
    zIndex: 2,
    left: (theme) => theme.spacing(0.5),
    ...globalSxProps.statusInProgress,
  },
  onHold: {
    zIndex: 3,
    left: (theme) => theme.spacing(0.75),
    ...globalSxProps.statusOnHold,
  },
  awaitingTenant: {
    zIndex: 4,
    left: (theme) => theme.spacing(1),
    ...globalSxProps.statusAwaitingTenant,
  },
  awaitingExternal: {
    zIndex: 5,
    left: (theme) => theme.spacing(1.25),
    ...globalSxProps.statusAwaitingExternal,
  },
  closedCircle: {
    zIndex: 6,
    left: (theme) => theme.spacing(1.5),
    ...globalSxProps.statusClosed,
  },
};
