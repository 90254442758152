import FolderIcon from "@mui/icons-material/FolderOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NewReleasesIcon from "@mui/icons-material/NewReleasesOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

import { TicketStatus } from "../../../../shared/types/Ticket";
import { sxProps } from "./ticket-count.style";
import { TicketCountKey } from "../../../../shared/types/Profile";

export const getTicketCountProps = (
  ticketCountKey: TicketCountKey
):
  | {
      icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
      iconStyle: SxProps<Theme>;
      labelKey: string;
    }
  | undefined => {
  switch (ticketCountKey) {
    case "All":
      return {
        icon: ListAltIcon,
        iconStyle: sxProps.ticketCountAllIcon,
        labelKey: "+profile.requestCount.all",
      };
    case "Overdue":
      return {
        icon: NewReleasesIcon,
        iconStyle: sxProps.ticketCountOverdueIcon,
        labelKey: "+profile.requestCount.overdue",
      };
    case TicketStatus.OPEN:
      return {
        icon: FolderIcon,
        iconStyle: sxProps.ticketCountOpenIcon,
        labelKey: "+profile.requestCount.open",
      };
    case TicketStatus.IN_PROGRESS:
      return {
        icon: RefreshIcon,
        iconStyle: sxProps.ticketCountInProgressIcon,
        labelKey: "+profile.requestCount.inProgress",
      };
    case TicketStatus.CLOSED:
      return {
        icon: DoneAllIcon,
        iconStyle: sxProps.ticketCountClosedIcon,
        labelKey: "+profile.requestCount.closed",
      };
    default:
      return undefined;
  }
};
