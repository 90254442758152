import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as idGenerator } from "uuid";
import { Snackbar, SnackbarSeverity } from "../types/Snackbar";
import type { RootState } from "./index";

export interface SnackbarState {
  snackbars: Snackbar[];
}

const generateNewSnackbar = (message: string, severity: SnackbarSeverity) => {
  const snackbar: Snackbar = {
    id: idGenerator(),
    message,
    severity,
  };
  return snackbar;
};

const initialState: SnackbarState = { snackbars: [] };

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addInfoSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(action.payload, SnackbarSeverity.INFO),
      ];
    },
    addWarningSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(action.payload, SnackbarSeverity.WARNING),
      ];
    },
    addErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(action.payload, SnackbarSeverity.ERROR),
      ];
    },
    addSuccessSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(action.payload, SnackbarSeverity.SUCCESS),
      ];
    },
    removeSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = state.snackbars.filter((s) => s.id !== action.payload);
    },
  },
});

export const {
  addErrorSnackbar,
  addInfoSnackbar,
  addSuccessSnackbar,
  addWarningSnackbar,
  removeSnackbar,
} = snackbarSlice.actions;
export const selectSnackbars = (state: RootState) => state.snackbar.snackbars;

export default snackbarSlice.reducer;
