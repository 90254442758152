import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import qs, { ParsedQuery } from "query-string";

import { ImageBackground } from "../ImageBackground";
import { AuthenticationTypeEnum } from "../../../../connectors/user";
import { useAppDispatch } from "../../../shared/store/hooks";
import { setLoading } from "../../../shared/store/loadingSlice";
import { authorizationAzure } from "../../../shared/provider/authProvider";
import { login } from "../../../shared/store/authSlice";
import { paths } from "../../../shared/routes/paths";

export const AuthCallbackScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authType } = useParams();

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    authorize(authType, queryParams);
  });

  const authorize = async (
    provider: string,
    queryParams: ParsedQuery<string>
  ) => {
    dispatch(setLoading(true));
    switch (provider) {
      case AuthenticationTypeEnum.Azure:
        try {
          const authUser = await authorizationAzure(queryParams);
          dispatch(login(authUser));
        } catch (e) {
          dispatch(setLoading(false));
        }
        break;
    }
    navigate(paths.root);
  };

  return <ImageBackground />;
};
