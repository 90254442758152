import React, { Dispatch, SetStateAction } from "react";
import { Modal, Box } from "@mui/material";
import { Close, FilePresentOutlined } from "@mui/icons-material";
import Carousel, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sxProps } from "./attachment-view-modal.style";
import { AttachmentContent } from "../../../types/Ticket";

export type AttachmentViewModalProps = {
  attachments: AttachmentContent[];
  startIndex: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const AttachmentViewModal = ({
  attachments,
  startIndex,
  open,
  setOpen,
}: AttachmentViewModalProps) => {
  const settings: Settings = {
    dots: false,
    swipe: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: startIndex,
  };

  return (
    <Modal open={open}>
      <Box sx={sxProps.carouselWrapper}>
        <Box
          component={Close}
          sx={sxProps.closeIcon}
          onClick={() => {
            setOpen(false);
          }}
        />
        <Carousel {...settings}>
          {attachments.map((a, i) => {
            const src = `data:${a.type};base64, ${a.base64}`;
            if (a.type !== "image/jpeg") {
              return (
                <Box sx={sxProps.imageContainer} key={i}>
                  <FilePresentOutlined sx={sxProps.attachmentIcon} />
                </Box>
              );
            } else {
              return (
                <Box sx={sxProps.imageContainer} key={i}>
                  <img src={src} alt={a.type} />
                </Box>
              );
            }
          })}
        </Carousel>
      </Box>
    </Modal>
  );
};
