import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import { sxProps } from "./logo-header.style";
import { ReactComponent as LogoIcon } from "./assets/logo-header.svg";
import { NotificationBell } from "../Notifications/NotificationBell";
import { paths } from "../../routes/paths";
import { Loader } from "../Loader/loader.component";
import { selectLoading } from "../../store/loadingSlice";
import { useAppSelector } from "../../store/hooks";

// Topmost part of the app.
// When user logs in, notification bell is to be added
// Shows loader when loading app state is set to true
export const LogoHeader = () => {
  const isLoading = useAppSelector((state) => selectLoading(state));

  return (
    <Grid container direction="column" sx={sxProps.headerWrapper}>
      <Grid item sx={sxProps.logoHeaderContainer}>
        <Box sx={sxProps.linkLogoWrapper} component={Link} to={paths.root}>
          <LogoIcon />
        </Box>
        <Box sx={sxProps.notificationBellWrapper}>
          <NotificationBell />
        </Box>
      </Grid>
      <Grid item sx={sxProps.loaderHeaderContainer}>
        <Loader loading={isLoading} />
      </Grid>
    </Grid>
  );
};
