//this object contains data needed for displaying navbar tabs

import { paths } from "../../../shared/routes/paths";
import {
  ServiceRequestNavbarItem,
  ServiceRequestNavbarItemValue,
} from "./types";

//and passing them specific properties
export const serviceRequestNavbarProps: {
  [key in ServiceRequestNavbarItemValue]: ServiceRequestNavbarItem;
} = {
  [ServiceRequestNavbarItemValue.ATTACHMENTS]: {
    labelKey: "+serviceRequest.tabs.attachments",
    value: ServiceRequestNavbarItemValue.ATTACHMENTS,
    path: paths.serviceRequestAttachments,
  },
  [ServiceRequestNavbarItemValue.DETAILS]: {
    labelKey: "+serviceRequest.tabs.details",
    value: ServiceRequestNavbarItemValue.DETAILS,
    path: paths.serviceRequestDetails,
  },
  [ServiceRequestNavbarItemValue.MESSAGES]: {
    labelKey: "+serviceRequest.tabs.messages",
    value: ServiceRequestNavbarItemValue.MESSAGES,
    path: paths.serviceRequestMessages,
  },
};
