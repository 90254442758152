import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Ticket,
  Filter,
  TicketStatus,
  TicketQuery,
  FailureData,
} from "../types/Ticket";
import { Workflow } from "../types/Workflow";
import { compareTicket, isQuerySet } from "../utils/ticket.helper";
import type { RootState } from "./index";

export interface TicketState {
  ticketsMap: { [key: string]: Ticket };
  tickets: Ticket[];
  filteredTickets: Ticket[];
  filter: Filter;
  hasMore: Record<TicketStatus | "ALL", boolean>;
  workflow?: Workflow;
  failures: Record<string, string>;
  ticketQuery?: TicketQuery;
}

const initialState: TicketState = {
  //this map is used for easier updating of state
  ticketsMap: {},
  //tickets will always be an array that is a results of Object.values(ticketsMap)
  //it's used for tracking app state using state selectors. We are using this because
  //Object.values(ticketsMap) will always return different object and can't be used in state selectors.
  tickets: [],
  filteredTickets: [],
  filter: {},
  hasMore: {
    ALL: true,
    [TicketStatus.OPEN]: true,
    [TicketStatus.IN_PROGRESS]: true,
    [TicketStatus.ON_HOLD]: true,
    [TicketStatus.AWAITING_TENANT]: true,
    [TicketStatus.TASK_IS_SENT_TO_EXTERNAL_PROVIDER]: true,
    [TicketStatus.CLOSED]: true,
  },
  failures: {},
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    addTickets: (
      state,
      action: PayloadAction<{
        tickets: Ticket[];
        total?: number;
        status?: TicketStatus;
      }>
    ) => {
      const { tickets, total, status } = action.payload;
      const newTicketMap: { [key: string]: Ticket } = {};
      tickets.forEach((newTicket) => {
        newTicketMap[newTicket.id] = newTicket;
      });
      const newTicketsMap = { ...state.ticketsMap, ...newTicketMap };
      state.ticketsMap = newTicketsMap;
      //we need tickets to be sorted
      state.tickets = Object.values(newTicketsMap).sort(compareTicket);
      state.filteredTickets = filterTickets(state.tickets, state.filter);
      if (total) {
        const totalTickets = status
          ? state.tickets.filter((t) => t.status === status).length
          : state.tickets.length;
        state.hasMore[status ? status : "ALL"] = totalTickets < total;
      }
    },

    removeTicket: (state, action: PayloadAction<string>) => {
      const newTicketMap: { [key: string]: Ticket } = {};
      Object.keys(state.ticketsMap).forEach((k) => {
        if (k !== action.payload) {
          newTicketMap[k] = state.ticketsMap[k];
        }
      });
      state.ticketsMap = newTicketMap;
      state.tickets = Object.values(newTicketMap).sort(compareTicket);
      state.filteredTickets = filterTickets(state.tickets, state.filter);
    },

    setQueriedTickets: (state, action: PayloadAction<Ticket[]>) => {
      const tickets = action.payload;
      const newTicketMap: { [key: string]: Ticket } = {};
      tickets.forEach((newTicket) => {
        newTicketMap[newTicket.id] = newTicket;
      });
      state.ticketsMap = { ...state.ticketsMap, ...newTicketMap };
      //we need tickets to be sorted
      state.tickets = Object.values(newTicketMap).sort(compareTicket);
      state.filteredTickets = filterTickets(tickets, state.filter);
    },

    setTicketQuery: (state, action: PayloadAction<TicketQuery | undefined>) => {
      state.ticketQuery = action.payload;
      if (!isQuerySet(state.ticketQuery)) {
        //if query is unset, we want to show all available tickets filtered and sorted
        state.tickets = Object.values(state.ticketsMap).sort(compareTicket);
        state.filteredTickets = filterTickets(state.tickets, state.filter);
      }
    },

    setOverdueFilter: (state, action: PayloadAction<boolean>) => {
      state.filter.isOverdue = action.payload;
      state.filteredTickets = filterTickets(state.tickets, state.filter);
    },

    setStatusFilter: (
      state,
      action: PayloadAction<TicketStatus | undefined>
    ) => {
      state.filter.status = action.payload;
      state.ticketQuery = { ...state.ticketQuery, status: action.payload };
      state.filteredTickets = filterTickets(state.tickets, state.filter);
    },
    setWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.workflow = action.payload;
    },
    addFailures: (state, action: PayloadAction<FailureData[]>) => {
      const newFailures = { ...state.failures };
      action.payload.forEach((fd) => (newFailures[fd.id] = fd.value));
      state.failures = newFailures;
    },
  },
});

const filterTickets = (tickets: Ticket[], filter: Filter) => {
  return tickets.filter((ticket) => {
    //if overdue flag is set, we will not show any ticket that is not overdue
    if (filter.isOverdue && !ticket.overDue) {
      return false;
    }
    //filter status can be undefined, in which case all tickets except done
    if (filter.status) {
      return ticket.status === filter.status;
    } else {
      return ticket.status !== TicketStatus.CLOSED;
    }
  });
};

export const {
  addTickets,
  setOverdueFilter,
  setStatusFilter,
  setWorkflow,
  removeTicket,
  addFailures,
  setQueriedTickets,
  setTicketQuery,
} = ticketSlice.actions;
export const selectTickets = (state: RootState) => state.ticket.tickets;
export const selectTicketByKey = (state: RootState, key: string) =>
  state.ticket.tickets.find((t) => t.key === key);
export const selectFilter = (state: RootState) => state.ticket.filter;
export const selectTicketQuery = (state: RootState) => state.ticket.ticketQuery;
export const selectFilteredTickets = (state: RootState) =>
  state.ticket.filteredTickets;
export const selectHasMore = (state: RootState, status?: TicketStatus) => {
  return (
    state.ticket.hasMore[status ? status : "ALL"] ||
    !isQuerySet(state.ticket.ticketQuery)
  );
};
export const selectWorkflow = (state: RootState) => state.ticket.workflow;
export const selectFailures = (state: RootState) => state.ticket.failures;

export default ticketSlice.reducer;
