import { Box, Stack, Typography } from "@mui/material";
import { sxProps } from "./dashboard-no-ticket-screen.style";
import { useTranslation } from "react-i18next";
import { ReactComponent as NoTicketImage } from "./assets/no-tickets-image.svg";

export const DashboardNoTicketScreen = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack sx={sxProps.noTicketsStack}>
        <Box sx={sxProps.noTicketsImage}>
          <NoTicketImage />
        </Box>
        <Box sx={sxProps.noTicketsText}>
          <Typography variant={"h1"} sx={sxProps.noTicketsHeadline}>
            {t("+dashboard.ticketList.noTickets.headline")}
          </Typography>
          <Typography variant={"body1"}>
            {t("+dashboard.ticketList.noTickets.message.firstPart")}
          </Typography>
          <Typography variant={"body1"}>
            {t("+dashboard.ticketList.noTickets.message.secondPart")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
