import React from "react";
import { LinearProgress, Box } from "@mui/material";

import { sxProps } from "./loader.style";

export type LoaderProps = {
  loading: boolean;
};

export const Loader = (props: LoaderProps) => {
  return (
    <Box sx={sxProps.loaderWrapper}>
      {props.loading ? <LinearProgress /> : null}
    </Box>
  );
};
