import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message, Ticket } from "../types/Ticket";
import { compareMessage } from "../utils/ticket.helper";
import type { RootState } from "./index";

export type MessageState = {
  messages: Record<string, Message[]>;
};

const initialState: MessageState = {
  messages: {},
};

//attachment state will contain all attachments that are donwloaded from jira
const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    addMessages: (
      state,
      action: PayloadAction<{
        ticketId: string;
        messages: Message[];
      }>
    ) => {
      const { ticketId, messages } = action.payload;
      //we are checking for message duplicates and not adding them
      const messagesToAdd = messages.filter((m) => {
        return state.messages[ticketId]
          ? !state.messages[ticketId].find(
              (sm) => sm.date === m.date && sm.message === m.message
            )
          : true;
      });
      const newMessageState = { ...state.messages };
      const oldMessages = newMessageState[ticketId]
        ? newMessageState[ticketId]
        : [];
      const newMessagesForTicket = [...messagesToAdd, ...oldMessages];
      newMessageState[ticketId] = newMessagesForTicket.sort(compareMessage);
      state.messages = newMessageState;
    },
  },
});

export const { addMessages } = attachmentsSlice.actions;
export const selectMessagesForTicket = (
  state: RootState,
  ticket: Ticket | undefined
) => (ticket ? state.messages.messages[ticket.id] : undefined);

export default attachmentsSlice.reducer;
