import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  appContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    maxWidth: "600px",
    padding: (theme) => ({ xs: 0, sm: theme.spacing(0, 3) }),
    backgroundColor: (theme) => theme.palette.background.paper,
  },
};
