import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactQuill, { Quill } from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "./newTheme.css";
import { MessagesProvider } from "../../provider/messagesProvider";
import { useTranslation } from "react-i18next";
import { icons } from "./util";
import { fileToBase64 } from "../Attachment/AttachmentUpload/util";
import { useTicket } from "../../../+serviceRequest/ticketContextProvider";
import { addErrorSnackbar } from "../../store/snackbarSlice";
import { useAppDispatch } from "../../store/hooks";

export type RichTextEditorProps = {
  setMessage: Dispatch<SetStateAction<string>>;
  setMentions: Dispatch<SetStateAction<boolean>>;
  setAttachments: Dispatch<SetStateAction<boolean>>;
  message: string;
};

export const RichTextEditorComponent = ({
  setMessage,
  setMentions,
  setAttachments,
  message,
}: RichTextEditorProps) => {
  const ticket = useTicket();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [mentionsOpened, setMentionsOpened] = useState(false);
  const editor = useRef(null);
  const cameraInput = useRef(null);
  const imageInput = useRef(null);

  const newIcons = Quill.import("ui/icons");
  newIcons["image"] = icons.image;
  newIcons["video"] = icons.camera;
  const messagesProvider = new MessagesProvider();
  const fetchUsers = async (query: string) => {
    return await messagesProvider.fetchMentions({
      query: query,
      ticketKey: ticket?.key,
    });
  };

  const getEditor = () => {
    // @ts-ignore
    return editor?.current?.getEditor();
  };

  const hasMentions = (element: any) => {
    return element.insert.hasOwnProperty("mention");
  };

  const hasImages = (element: any) => {
    return element.insert.hasOwnProperty("image");
  };

  const handleChange = (html: string) => {
    setMessage(html);
    setMentions(getEditor().getContents().ops.some(hasMentions));
    setAttachments(getEditor().getContents().ops.some(hasImages));
  };

  useEffect(() => {
    getEditor().focus();
  });

  useEffect(() => {
    const quillElement = document.getElementsByClassName(
      "quill"
    )[0] as HTMLElement;
    if (mentionsOpened) {
      quillElement.classList.add("ql-mentions-opened");
    } else {
      quillElement.classList.remove("ql-mentions-opened");
    }
  }, [mentionsOpened]);

  useEffect(() => {
    getEditor()
      .getModule("toolbar")
      .addHandler("video", () => {
        // @ts-ignore
        cameraInput.current?.click();
      });
  }, []);

  useEffect(() => {
    getEditor()
      .getModule("toolbar")
      .addHandler("image", () => {
        // @ts-ignore
        imageInput.current?.click();
      });
  }, []);

  const handleCameraInput = async (event: any) => {
    const value = (await fileToBase64(event?.target.files[0])) as string;
    const currentIndex = getEditor().getLength();
    getEditor().insertEmbed(currentIndex, "image", `${value}`, "api");
  };

  const handleMultipleFileInput = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;
    const currentIndex = getEditor().getLength();
    if (files && files.length > 0) {
      try {
        for (let i = 0; i < files.length; i++) {
          const value = (await fileToBase64(files[i])) as string;
          getEditor().insertEmbed(currentIndex + i, "image", `${value}`, "api");
        }
      } catch (error) {
        dispatch(addErrorSnackbar(error as string));
      }
    }
    getEditor().setSelection(currentIndex + files?.length, 0, "api");
  };

  const source = useCallback(
    async (searchTerm: string, renderList: any, mentionChar: string) => {
      if (searchTerm.length === 0) {
        return;
      }
      const mentions = await fetchUsers(searchTerm);
      const matches = mentions?.map((mention, index) => {
        return {
          id: mention.accountId,
          value: mention.displayName,
          avatar: mention.avatarUrls["48x48"],
        };
      });
      await renderList(matches, searchTerm);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const renderItem = useCallback((item, searchTerm) => {
    return `<img class="ql-avatar" alt=${item.value} src=${item.avatar} /> ${item.value}`;
  }, []);

  const loader = useCallback(() => {
    return `<div class='loader'>${icons.spinner}</div>`;
  }, []);

  const setHeight = (mentionsNumber: number, container: HTMLElement) => {
    if (mentionsNumber > 3) {
      Object.assign(container.style, {
        height: "30vh",
        top: "-30vh",
      });
    } else if (mentionsNumber > 0) {
      Object.assign(container.style, {
        height: `${mentionsNumber * 10}vh`,
        top: `-${mentionsNumber * 10}vh`,
      });
    } else {
      Object.assign(container.style, {
        height: "10vh",
        top: "-10vh",
      });
    }
  };

  const onOpenCallback = useCallback(() => {
    setMentionsOpened(true);
    const target = document.getElementsByClassName(
      "ql-mention-list-container"
    )[0];

    function callback(mutationRecord: any) {
      for (let i = 0, len = mutationRecord.length; i < len; i += 1) {
        if (mutationRecord[i].type === "childList") {
          const mentions =
            document.getElementById("quill-mention-list")?.childNodes;
          if (mentions) {
            setHeight(mentions.length, target as HTMLElement);
          }
        }
      }
    }

    const observer = new MutationObserver(callback);
    const config = {
      childList: true,
    };
    observer.observe(target, config);
  }, []);

  const onCloseCallback = useCallback(() => {
    setMentionsOpened(false);
  }, []);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", { list: "ordered" }, { list: "bullet" }],
      ["video", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
    mention: {
      minChars: 1,
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ["@", "#"],
      source: source,
      fixMentionsToQuill: true,
      defaultMenuOrientation: "top",
      positioningStrategy: "normal",
      renderItem,
      renderLoading: loader,
      onOpen: onOpenCallback,
      onClose: onCloseCallback,
    },
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "image",
    "mention",
  ];

  return (
    <>
      <input
        ref={cameraInput}
        id={"cameraFileInput"}
        type={"file"}
        accept={"image/*"}
        capture={"environment"}
        onChange={handleCameraInput}
        style={{ visibility: "hidden", position: "absolute", height: 0 }}
      />
      <input
        ref={imageInput}
        id={"multipleFilesInput"}
        type={"file"}
        accept={"image/*"}
        multiple={true}
        onChange={handleMultipleFileInput}
        style={{ visibility: "hidden", position: "absolute", height: 0 }}
      />
      <ReactQuill
        ref={editor}
        placeholder={t("+serviceRequest.messages.messageInputLabel")}
        modules={modules}
        formats={formats}
        theme="snow"
        value={message}
        onChange={handleChange}
      />
    </>
  );
};
