import React from "react";
import { SxProps } from "@mui/system";
import { Button, Theme } from "@mui/material";
import { Navigation } from "@mui/icons-material";

import { sxProps } from "./navigate-button.style";
import { mapsSelector } from "../../util";
import { useTranslation } from "react-i18next";

export type NavigateButtonProps = {
  labelKey: string;
  buttonSx: SxProps<Theme>;
  address: string;
};

export const NavigateButton = ({
  buttonSx,
  labelKey,
  address,
}: NavigateButtonProps) => {
  const { t } = useTranslation();

  const onClick = async () => {
    mapsSelector(address);
  };

  return (
    <Button variant="outlined" color="primary" sx={buttonSx} onClick={onClick}>
      <Navigation sx={sxProps.navigateButtonIcon} />
      {t(labelKey)}
    </Button>
  );
};
