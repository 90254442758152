import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  serviceRequestDetailsIdLabel: {
    color: (theme) => theme.palette.grey[600],
    paddingLeft: (theme) => theme.spacing(0.25),
  },
  serviceRequestDetailsId: {
    color: (theme) => theme.palette.primary.main,
  },

  serviceRequestDetailsBox: {
    padding: (theme) => theme.spacing(3, 0, 0, 1),
  },

  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
};
