import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  headerWrapper: {
    background: (theme) => theme.palette.divider,
    height: (theme) => theme.custom.headerWrapperHeight,
    position: "sticky",
    top: 0,
  },

  logoHeaderContainer: {
    background: (theme) => theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: (theme) => theme.spacing(1),
    paddingBottom: (theme) => theme.spacing(1),

    minHeight: (theme) => theme.custom.headerContainerHeight,
    left: 0,
    right: 0,
    top: 0,
  },

  linkLogoWrapper: {
    flex: 1,
    textAlign: "center",
    paddingLeft: (theme) => theme.spacing(4),
  },

  notificationBellWrapper: {
    paddingRight: (theme) => theme.spacing(2.5),
  },

  loaderHeaderContainer: {
    position: "fixed",
    left: 0,
    right: 0,
    top: (theme) => theme.custom.headerContainerHeight,
  },
};
