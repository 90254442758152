/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthenticationTypeEnum } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { LoginOutputSerializer } from '../models';
// @ts-ignore
import { MeSerializer } from '../models';
// @ts-ignore
import { RefreshSerializer } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeAuthAuthTypeAuthorizeGet: async (authType: AuthenticationTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authType' is not null or undefined
            assertParamExists('authorizeAuthAuthTypeAuthorizeGet', 'authType', authType)
            const localVarPath = `/auth/{auth_type}/authorize`
                .replace(`{${"auth_type"}}`, encodeURIComponent(String(authType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Azure Logout
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        azureLogoutAuthAuthTypeLogoutGet: async (authType: AuthenticationTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authType' is not null or undefined
            assertParamExists('azureLogoutAuthAuthTypeLogoutGet', 'authType', authType)
            const localVarPath = `/auth/{auth_type}/logout`
                .replace(`{${"auth_type"}}`, encodeURIComponent(String(authType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost: async (username: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'password', password)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuthLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meAuthMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAuthAuthTypeTokenGet: async (authType: AuthenticationTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authType' is not null or undefined
            assertParamExists('tokenAuthAuthTypeTokenGet', 'authType', authType)
            const localVarPath = `/auth/{auth_type}/token`
                .replace(`{${"auth_type"}}`, encodeURIComponent(String(authType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token Refresh
         * @param {string} refreshToken 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshAuthRefreshGet: async (refreshToken: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('tokenRefreshAuthRefreshGet', 'refreshToken', refreshToken)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('tokenRefreshAuthRefreshGet', 'idToken', idToken)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authorize
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeAuthAuthTypeAuthorizeGet(authType: AuthenticationTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeAuthAuthTypeAuthorizeGet(authType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Azure Logout
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async azureLogoutAuthAuthTypeLogoutGet(authType: AuthenticationTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.azureLogoutAuthAuthTypeLogoutGet(authType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAuthLoginPost(username: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginOutputSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuthLoginPost(username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutAuthLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutAuthLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meAuthMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meAuthMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenAuthAuthTypeTokenGet(authType: AuthenticationTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginOutputSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenAuthAuthTypeTokenGet(authType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token Refresh
         * @param {string} refreshToken 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenRefreshAuthRefreshGet(refreshToken: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenRefreshAuthRefreshGet(refreshToken, idToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Authorize
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeAuthAuthTypeAuthorizeGet(authType: AuthenticationTypeEnum, options?: any): AxiosPromise<any> {
            return localVarFp.authorizeAuthAuthTypeAuthorizeGet(authType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Azure Logout
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        azureLogoutAuthAuthTypeLogoutGet(authType: AuthenticationTypeEnum, options?: any): AxiosPromise<any> {
            return localVarFp.azureLogoutAuthAuthTypeLogoutGet(authType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost(username: string, password: string, options?: any): AxiosPromise<LoginOutputSerializer> {
            return localVarFp.loginAuthLoginPost(username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuthLogoutPost(options?: any): AxiosPromise<any> {
            return localVarFp.logoutAuthLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meAuthMeGet(options?: any): AxiosPromise<MeSerializer> {
            return localVarFp.meAuthMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token
         * @param {AuthenticationTypeEnum} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAuthAuthTypeTokenGet(authType: AuthenticationTypeEnum, options?: any): AxiosPromise<LoginOutputSerializer> {
            return localVarFp.tokenAuthAuthTypeTokenGet(authType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token Refresh
         * @param {string} refreshToken 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshAuthRefreshGet(refreshToken: string, idToken: string, options?: any): AxiosPromise<RefreshSerializer> {
            return localVarFp.tokenRefreshAuthRefreshGet(refreshToken, idToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Authorize
     * @param {AuthenticationTypeEnum} authType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeAuthAuthTypeAuthorizeGet(authType: AuthenticationTypeEnum, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authorizeAuthAuthTypeAuthorizeGet(authType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Azure Logout
     * @param {AuthenticationTypeEnum} authType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public azureLogoutAuthAuthTypeLogoutGet(authType: AuthenticationTypeEnum, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).azureLogoutAuthAuthTypeLogoutGet(authType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAuthLoginPost(username: string, password: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAuthLoginPost(username, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logoutAuthLogoutPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logoutAuthLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public meAuthMeGet(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).meAuthMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token
     * @param {AuthenticationTypeEnum} authType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public tokenAuthAuthTypeTokenGet(authType: AuthenticationTypeEnum, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).tokenAuthAuthTypeTokenGet(authType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token Refresh
     * @param {string} refreshToken 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public tokenRefreshAuthRefreshGet(refreshToken: string, idToken: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).tokenRefreshAuthRefreshGet(refreshToken, idToken, options).then((request) => request(this.axios, this.basePath));
    }
}
