import { Navigate, PartialRouteObject } from "react-router";
import { DashboardScreen } from "../../+dashboard";
import { AuthCallbackScreen, LoginScreen } from "../../+login";
import { ProfileScreen } from "../../+profile";
import {
  ServiceRequestPage,
  ServiceRequestAttachments,
  ServiceRequestDetails,
  ServiceRequestMessages,
} from "../../+serviceRequest";
import { GuardedRoute } from "./GuardedRoute";
import { paths } from "./paths";
import { PublicRoute } from "./PublicRoute";

export const getRoutes = (): PartialRouteObject[] => [
  {
    path: paths.root,
    element: (
      <PublicRoute>
        <LoginScreen />
      </PublicRoute>
    ),
    children: [
      {
        path: "*",
        element: <Navigate to={paths.root} />,
      },
    ],
  },
  {
    path: paths.authCallback,
    children: [
      {
        path: ":authType/authorize",
        element: (
          <PublicRoute>
            <AuthCallbackScreen />
          </PublicRoute>
        ),
      },
    ],
  },

  {
    path: paths.dashboard,
    element: (
      <GuardedRoute>
        <DashboardScreen />
      </GuardedRoute>
    ),
    children: [
      {
        path: "*",
        element: <Navigate to={paths.dashboard} />,
      },
    ],
  },
  {
    path: paths.serviceRequest,
    children: [
      {
        path: ":ticketKey/",
        element: (
          <GuardedRoute>
            <ServiceRequestPage />
          </GuardedRoute>
        ),
        children: [
          {
            path: paths.serviceRequestDetails,
            element: <ServiceRequestDetails />,
          },
          {
            path: paths.serviceRequestAttachments,
            element: <ServiceRequestAttachments />,
          },
          {
            path: paths.serviceRequestMessages,
            element: <ServiceRequestMessages />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to={`${paths.serviceRequest}/`} />,
      },
    ],
  },
  {
    path: paths.createServiceRequest,
    element: <GuardedRoute></GuardedRoute>,
    children: [
      {
        path: "*",
        element: <Navigate to={paths.createServiceRequest} />,
      },
    ],
  },
  {
    path: paths.profile,
    element: (
      <GuardedRoute>
        <ProfileScreen />
      </GuardedRoute>
    ),
    children: [
      {
        path: "*",
        element: <Navigate to={paths.profile} />,
      },
    ],
  },
];
