import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  imageContainer: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100vw !important",
    height: "100vh !important",
    "& img": {
      objectFit: "cover",
      width: "100%",
    },
  },

  attachmentIcon: {
    color: (theme) => theme.palette.grey[100],
    width: (theme) => theme.spacing(20),
    height: (theme) => theme.spacing(20),
  },

  carouselWrapper: {
    outline: "none",
    position: "relative",
    height: "100vh",
    width: "100vw",
    background: (theme) => theme.palette.background.default,
  },

  closeIcon: {
    position: "absolute",
    color: (theme) => theme.palette.primary.contrastText,
    top: (theme) => theme.spacing(2),
    right: (theme) => theme.spacing(2),
    zIndex: 2,
  },
};
