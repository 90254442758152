export const icons = {
  image:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H21.75C22.5784 0.75 23.25 1.42157 23.25 2.25V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H2.25C1.42157 23.25 0.75 22.5784 0.75 21.75V2.25Z" stroke="#333132" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M5.25 17.25L8.712 12.634C8.97285 12.286 9.37191 12.0683 9.80575 12.0374C10.2396 12.0065 10.6655 12.1654 10.973 12.473L12 13.5L15.3 9.1C15.5833 8.72229 16.0279 8.5 16.5 8.5C16.9721 8.5 17.4167 8.72229 17.7 9.1L20.37 12.66" stroke="#333132" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 8.25C7.41053 8.25 8.25 7.41053 8.25 6.375C8.25 5.33947 7.41053 4.5 6.375 4.5C5.33947 4.5 4.5 5.33947 4.5 6.375C4.5 7.41053 5.33947 8.25 6.375 8.25Z" stroke="#333132" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M0.75 17.25H23.25" stroke="#333132" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    "</svg>",
  camera:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 18C17.1495 18 19.5 15.6495 19.5 12.75C19.5 9.85051 17.1495 7.5 14.25 7.5C11.3505 7.5 9 9.85051 9 12.75C9 15.6495 11.3505 18 14.25 18Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 6L18.415 3.829C18.1608 3.32078 17.6412 2.99983 17.073 3H11.427C10.8588 2.99983 10.3392 3.32078 10.085 3.829L9 6H2.25C1.42157 6 0.75 6.67157 0.75 7.5V19.5C0.75 20.3284 1.42157 21 2.25 21H21.75C22.5784 21 23.25 20.3284 23.25 19.5V7.5C23.25 6.67157 22.5784 6 21.75 6H19.5Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M4.125 9C4.33211 9 4.5 9.16789 4.5 9.375C4.5 9.58211 4.33211 9.75 4.125 9.75C3.91789 9.75 3.75 9.58211 3.75 9.375C3.75 9.16789 3.91789 9 4.125 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M5.25 6V4.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    "</svg>",
  spinner:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="48px" height="48px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">\n' +
    '<g transform="rotate(0 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(30 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(60 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(90 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(120 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(150 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(180 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(210 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(240 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(270 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(300 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    '</g><g transform="rotate(330 50 50)">\n' +
    '  <rect x="46.5" y="24" rx="3.5" ry="6" width="7" height="12" fill="#6d6d6d">\n' +
    '    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>\n' +
    "  </rect>\n" +
    "</g>\n" +
    "</svg>",
};
