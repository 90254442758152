import { Box, Button, Link } from "@mui/material";
import React from "react";
import { IconType } from "../DetailInformation";
import { sxProps } from "./detail-information-link.style";

export type DetailInformationLinkProps = {
  link: string;
  information: string;
  icon: IconType;
};

export const DetailInformationLink = ({
  link,
  information,
  icon,
}: DetailInformationLinkProps) => {
  if (!link || !information) {
    return <></>;
  }

  return (
    <Link href={link} sx={sxProps.serviceRequestDetailsActionLink}>
      <Button
        color="primary"
        variant="outlined"
        sx={sxProps.serviceRequestDetailsButton}
      >
        <Box component={icon} sx={sxProps.serviceRequestDetailsButtonsIcon} />
        {information}
      </Button>
    </Link>
  );
};
