import React, { useEffect } from "react";
import { Container } from "@mui/material";

import { ProfileProvider } from "../../../shared/provider/profileProvider";
import { useAppSelector } from "../../../shared/store/hooks";
import { selectProfile } from "../../../shared/store/profileSlice";
import { ProfileImage } from "../ProfileImage";
import { ProfileDetails } from "../ProfileDetails";
import { ProfileServiceRequests } from "../ProfileServiceRequests";
import { sxProps } from "./profile-screen.style";
import { ProfileUnits } from "../ProfileUnits";

const profileProvider = new ProfileProvider();

export const ProfileScreen = () => {
  const profile = useAppSelector((state) => selectProfile(state));

  useEffect(() => {
    profileProvider.fetchProfile();
  }, []);

  if (!profile) return <></>;
  return (
    <Container sx={sxProps.profileScreen}>
      <ProfileImage profile={profile} />
      <ProfileDetails profile={profile} />
      <ProfileServiceRequests profile={profile} />
      <ProfileUnits profile={profile} />
    </Container>
  );
};
