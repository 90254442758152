import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  topItemBorder: {
    paddingTop: (theme) => theme.spacing(2),
    borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
    justifyContent: "center",
  },
  leftItemBorder: {
    paddingTop: (theme) => theme.spacing(2),
    borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
    borderRight: (theme) => `1px solid ${theme.palette.grey[400]}`,
    justifyContent: "center",
  },
  rightItemBorder: {
    paddingTop: (theme) => theme.spacing(2),
    borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
    justifyContent: "center",
  },
};
