import React from "react";
import * as Sentry from "@sentry/react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { getRoutes } from "./routes/routes.const";

import {
  AppContainer,
  Menu,
  LogoHeader,
  SnackbarProviderWrapper,
} from "./components";
import { theme } from "./style";
import { initTransaltion } from "./translation";
import "./App.css";

initTransaltion();

function App() {
  const routes = getRoutes();
  const content = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProviderWrapper>
        <AppContainer>
          <LogoHeader />
          {content}
          <Menu />
        </AppContainer>
      </SnackbarProviderWrapper>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
