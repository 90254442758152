import React from "react";
import { Grid } from "@mui/material";

import { JiraProfile } from "../../../shared/types/Profile";
import { TicketCount } from "./TicketCount";
import { sxProps } from "./profile-service-requests.style";
import { TicketStatus } from "../../../shared/types/Ticket";

export type ProfileServiceRequestsProps = {
  profile: JiraProfile;
};

export const ProfileServiceRequests = ({
  profile,
}: ProfileServiceRequestsProps) => {
  return (
    <Grid container direction="column">
      <Grid item container xs={12} sx={sxProps.topItemBorder}>
        <TicketCount count={profile.ticketCount.All} ticketCountKey="All" top />
      </Grid>
      <Grid item container xs={12}>
        <Grid item container xs={6} sx={sxProps.leftItemBorder}>
          <TicketCount
            count={profile.ticketCount.Open}
            ticketCountKey={TicketStatus.OPEN}
          />
        </Grid>
        <Grid item container xs={6} sx={sxProps.rightItemBorder}>
          <TicketCount
            count={profile.ticketCount["In Progress"]}
            ticketCountKey={TicketStatus.IN_PROGRESS}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container xs={6} sx={sxProps.leftItemBorder}>
          <TicketCount
            count={profile.ticketCount.Overdue}
            ticketCountKey="Overdue"
          />
        </Grid>
        <Grid item container xs={6} sx={sxProps.rightItemBorder}>
          <TicketCount
            count={profile.ticketCount.Closed}
            ticketCountKey={TicketStatus.CLOSED}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
