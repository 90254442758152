export enum Platfrom {
  ANDROID,
  IOS,
  LINUX,
  WINDOWS,
  MACOS,
}

export const detectPlatform = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return Platfrom.MACOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return Platfrom.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return Platfrom.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    return Platfrom.ANDROID;
  } else {
    return Platfrom.LINUX;
  }
};

export const isApplePlatform = (platform: Platfrom) => {
  return platform === Platfrom.IOS || platform === Platfrom.MACOS;
};
