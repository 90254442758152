export type UserDetails = {
  preferedUsername: string;
  fullName: string;
  familyName: string;
  givenName: string;
};

export enum AuthType {
  AZURE,
  EMAIL_AND_PASSWORD,
}

export type AuthUser = {
  type: AuthType;
  idToken: string;
  accessToken: string;
  refreshToken: string;
  details?: UserDetails;
};
