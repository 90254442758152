import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardFilterCheckbox: {
    color: (theme) => theme.palette.grey["400"],
    "&.Mui-checked": {
      color: (theme) => theme.palette.error.main,
    },
  },

  dashboardFilterCheckboxLabel: {
    color: (theme) => theme.palette.error.contrastText,
    paddingLeft: (theme) => theme.spacing(1.5),
  },
};
