import { MAX_FILE_SIZE } from "../../../utils/const";
import { handleError } from "../../../utils/sentry.helper";

export const fileToBase64 = (file: File): Promise<FileReader["result"]> =>
  new Promise((resolve, reject) => {
    if (file.size > MAX_FILE_SIZE) {
      reject("error.fileTooBig");
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => {
      handleError(error);
      reject("error.fileProblem");
    };
  });
