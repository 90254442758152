import React from "react";
import { Grid } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import {
  Counter,
  CounterTypes,
  ServiceRequestNavbarItem,
  ServiceRequestNavbarItemValue,
} from "./types";
import { serviceRequestNavbarProps } from "./util";
import { sxProps } from "./service-request-navbar.style.component";
import { useLocation, useNavigate } from "react-router";
import { ServiceRequestNavbarButton } from "./components/ServiceRequestNavbarItem/";
import { paths } from "../../../shared/routes/paths";
import { useTicket } from "../../ticketContextProvider";

const navbarItems: ServiceRequestNavbarItem[] = [
  serviceRequestNavbarProps.DETAILS,
  serviceRequestNavbarProps.ATTACHMENTS,
  serviceRequestNavbarProps.MESSAGES,
];

export type ServiceRequestNavbarProps = {
  serviceRequestKey: string;
};

export const ServiceRequestNavbar = (props: ServiceRequestNavbarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const ticket = useTicket();
  const createCounter = (navbarItem: ServiceRequestNavbarItem): Counter => {
    switch (navbarItem.value) {
      case ServiceRequestNavbarItemValue.ATTACHMENTS:
        return {
          type: CounterTypes.SECONDARY,
          value: ticket?.attachments.length || 0,
        };
      case ServiceRequestNavbarItemValue.MESSAGES:
        return { type: CounterTypes.PRIMARY, value: ticket?.commentCount || 0 };
      default:
        return { type: CounterTypes.DISABLED, value: 0 };
    }
  };

  const createNavbarItem = (navbarItem: ServiceRequestNavbarItem) => {
    const path = `${paths.serviceRequest}/${props.serviceRequestKey}${navbarItem.path}`;
    const active = path === location.pathname;
    const counter = createCounter(navbarItem);
    return (
      <Grid item key={navbarItem.value}>
        <ServiceRequestNavbarButton
          label={t(navbarItem.labelKey)}
          path={path}
          active={active}
          counter={counter}
        />
      </Grid>
    );
  };

  const onBackButtonClick = () => {
    navigate(paths.dashboard);
  };

  return (
    <Grid container sx={sxProps.serviceRequestNavbar}>
      <Grid item onClick={onBackButtonClick}>
        <ArrowBackIos sx={sxProps.backButton} />
      </Grid>
      {navbarItems.map((item) => {
        return createNavbarItem(item);
      })}
    </Grid>
  );
};
