import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentUploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: (theme) => theme.palette.grey[200],
  },

  attachmentUploadLabel: {
    color: (theme) => theme.palette.grey[800],
  },

  attachmentUploadIcon: {
    color: (theme) => theme.palette.grey[300],
    width: (theme) => theme.spacing(8),
    height: (theme) => theme.spacing(7),
  },
};
