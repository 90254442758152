import React, { useMemo } from "react";
import { Box, Typography, Grid } from "@mui/material";

import { sxProps } from "./ticket-count.style";
import { TicketCountKey } from "../../../../shared/types/Profile";
import { getTicketCountProps } from "./util";
import { useTranslation } from "react-i18next";

export type TicketCountProps = {
  count: number;
  ticketCountKey: TicketCountKey;
  top?: boolean;
};
export const TicketCount = ({
  count,
  ticketCountKey,
  top,
}: TicketCountProps) => {
  const { t } = useTranslation();
  const ticketCountProps = useMemo(
    () => getTicketCountProps(ticketCountKey),
    [ticketCountKey]
  );
  if (!ticketCountProps) return <></>;
  const columnsLeft = top ? 3 : 1;
  const columnsRight = top ? 9 : 10;

  return (
    <>
      <Grid item xs={columnsLeft} />
      <Grid item xs={columnsRight}>
        <Box sx={sxProps.ticketCountBox}>
          <Box component="span" sx={ticketCountProps.iconStyle}>
            <Box component={ticketCountProps.icon} sx={sxProps.iconStyle} />
          </Box>
          <Box component="span">
            <Typography variant="h1" sx={sxProps.ticketCountNumber}>
              {count}
            </Typography>
            <Typography variant="overline" sx={sxProps.ticketCountLabel}>
              {t(ticketCountProps.labelKey)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
