import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  profileImageBox: {
    marginTop: (theme) => theme.spacing(6.25),
  },

  profileImage: {
    borderRadius: "50%",
    width: "100%",
  },
};
