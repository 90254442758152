import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Modal,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { CheckCircleOutline, CancelOutlined } from "@mui/icons-material";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import {
  TransitionFormFormat,
  Transition,
  TransitionFormField,
} from "../../../../../../../shared/types/Workflow";
import { sxProps } from "./status-transition-modal.style";
import { removeStartingZero, statusTransitionFormValidation } from "./util";
import {
  useChangeTicketStatusWithTransitionData,
  useRefreshTicket,
} from "../../../../../../ticketContextProvider";
import { useAppSelector } from "../../../../../../../shared/store/hooks";
import { selectLoading } from "../../../../../../../shared/store/loadingSlice";
import { TaggableTextFieldComponent } from "../../../../../../../shared/components";
import {
  AddMessageRequestData,
  TaggedUser,
} from "../../../../../../../shared/types/Ticket";

export type StatusTransitionFormModal = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  formFormat?: TransitionFormFormat;
  transition?: Transition;
};

export const StatusTransitionModal = ({
  open,
  setOpen,
  formFormat,
  transition,
}: StatusTransitionFormModal) => {
  const { t } = useTranslation();
  const changeTicketStatusWithTransitionData =
    useChangeTicketStatusWithTransitionData();
  const refreshTicket = useRefreshTicket();
  const [formData, setFormData] = useState<any>();
  const [error, setError] = useState<Record<string, string | undefined>>({});
  const [taggedUsers, setTaggedUsers] = useState<TaggedUser[]>([]);
  const [message, setMessage] = useState("");
  const [timeSpent, setTimeSpent] = useState<Record<string, number>>({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const isLoading = useAppSelector((state) => selectLoading(state));

  //setting default form data when modal is opened
  useEffect(() => {
    if (!formFormat) {
      setFormData(undefined);
      return;
    }
    const data: any = {};
    formFormat.fields.forEach((field) => {
      switch (field.type) {
        case "datepicker":
          data[field.key] = dateFormat(new Date(), "yyyy-mm-dd");
          break;
        case "select":
          data[field.key] = field.options ? field.options[0].value : "";
          break;
        case "timetracking":
          data[field.key] = calculateTime();
          break;
      }
    });
    if (formFormat.message) {
      const messageData: AddMessageRequestData = {
        message: "",
        isPublic: true,
        taggedUsers: [],
      };
      data.message = messageData;
    }
    setFormData(data);
  }, [formFormat]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = async (
    formFormat: TransitionFormFormat,
    transition: Transition
  ) => {
    const error = statusTransitionFormValidation(formData, formFormat);
    if (!_.isEmpty(error)) {
      return setError(error);
    }
    if (changeTicketStatusWithTransitionData && refreshTicket) {
      await changeTicketStatusWithTransitionData(
        formFormat.url,
        transition,
        formData
      );
      refreshTicket();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!formData?.message) return;
    const newFormData = { ...formData };
    newFormData.message.message = message;
    newFormData.message.taggedUsers = taggedUsers;
    newFormData.message.isPublic = taggedUsers.length === 0;
    setFormData(newFormData);
    setError({ ...error, comment: undefined });
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!formData?.hasOwnProperty("timeSpentInSeconds")) return;
    const newFormData = { ...formData };
    newFormData.timeSpentInSeconds = calculateTime();
    setFormData(newFormData);
    setError({ ...error, timeSpentInSeconds: undefined });
  }, [timeSpent]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (fieldName: string, e: string | number) => {
    setFormData({ ...formData, [fieldName]: e });
    setError({ ...error, [fieldName]: undefined });
  };

  const calculateTime = () => {
    return (
      timeSpent.days * 24 * 60 * 60 +
      timeSpent.hours * 60 * 60 +
      timeSpent.minutes * 60
    );
  };

  const onTimeSet = (fieldName: string, e: number) => {
    setTimeSpent({ ...timeSpent, [fieldName]: e });
  };

  const onRadioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formData };
    newFormData.message.isPublic = e.target.value === "true";
    setFormData(newFormData);
  };

  const renderSelectField = (field: TransitionFormField) => {
    return (
      <FormControl
        fullWidth
        key={field.key}
        color="secondary"
        sx={sxProps.inputField}
      >
        <InputLabel id={`selectFieldLabel${field.key}`}>
          {t(
            `+serviceRequest.details.actions.statusTransitionForm.${field.key}`
          )}
        </InputLabel>
        <Select
          labelId={`selectFieldLabel${field.key}`}
          value={formData[field.key]}
          label={t(
            `+serviceRequest.details.actions.statusTransitionForm.${field.key}`
          )}
          onChange={(e) => onChange(field.key, e.target.value)}
        >
          {field.options?.map((option) => {
            return (
              <MenuItem value={option.value} key={option.key}>
                {t(
                  `+serviceRequest.details.actions.statusTransitionForm.${option.key}`
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderDatepickerField = (field: TransitionFormField) => {
    return (
      <TextField
        key={field.key}
        sx={sxProps.inputField}
        fullWidth
        type="date"
        onChange={(e) => onChange(field.key, e.target.value)}
        label={t(
          `+serviceRequest.details.actions.statusTransitionForm.${field.key}`
        )}
        value={formData[field.key]}
      />
    );
  };

  const renderCommentSection = () => {
    return (
      <Box>
        <Typography variant="overline" gutterBottom sx={sxProps.modalLabel}>
          {t(
            `+serviceRequest.details.actions.statusTransitionForm.messageHeader`
          )}
        </Typography>
        <FormControl color="secondary" sx={sxProps.messageFormControl}>
          <RadioGroup
            sx={sxProps.messageButtons}
            aria-labelledby="status-transition-message-radio-group-id"
            name="controlled-radio-buttons-group"
            value={formData.message.isPublic}
            onChange={onRadioButtonChange}
          >
            <FormControlLabel
              value={true}
              disabled={taggedUsers.length > 0}
              control={<Radio />}
              label={t(
                `+serviceRequest.details.actions.statusTransitionForm.messagePublic`
              )}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t(
                `+serviceRequest.details.actions.statusTransitionForm.messageInternal`
              )}
            />
          </RadioGroup>
        </FormControl>
        <TaggableTextFieldComponent
          sx={sxProps.messageTextArea}
          disabled={false}
          message={message}
          setMessage={setMessage}
          taggedUsers={taggedUsers}
          setTaggedUsers={setTaggedUsers}
          disablePortal={true}
          minRows={3}
          maxRows={3}
          multiline={true}
          fullWidth={true}
          label={t(
            `+serviceRequest.details.actions.statusTransitionForm.message`
          )}
          error={Boolean(error.comment)}
          helperText={error.comment ? t(error.comment) : ""}
        />
      </Box>
    );
  };

  const renderTimeInput = (field: TransitionFormField) => {
    return (
      <Box key={field.key}>
        <Typography variant="overline" gutterBottom sx={sxProps.modalLabel}>
          {t(`+serviceRequest.details.actions.statusTransitionForm.timeSpent`)}
        </Typography>
        <Box sx={sxProps.timeSpentBox}>
          {Object.keys(timeSpent).map((key) => {
            return (
              <TextField
                key={key}
                type={"number"}
                sx={sxProps.timeInput}
                onChange={(e) => {
                  removeStartingZero(e);
                  if (!e.target.value) {
                    e.target.value = "0";
                  }
                  onTimeSet(key, parseInt(e.target.value));
                }}
                label={t(
                  `+serviceRequest.details.actions.statusTransitionForm.${key}`
                )}
                variant={"outlined"}
                value={timeSpent[key]}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  const renderTransitionForm = (formFormat: TransitionFormFormat) => {
    return (
      <Box>
        {formFormat.fields.map((field) => {
          if (field.type === "select") {
            return renderSelectField(field);
          }
          if (field.type === "datepicker") {
            return renderDatepickerField(field);
          }
          if (field.type === "timetracking") {
            return renderTimeInput(field);
          }
          return <Box key={field.key}></Box>;
        })}
        {formFormat.message ? <Box>{renderCommentSection()}</Box> : null}
      </Box>
    );
  };

  if (!formFormat || !transition || !formData) {
    return <></>;
  }

  return (
    <Modal open={open} sx={sxProps.modal}>
      <Card sx={sxProps.modalContent}>
        <CardContent>
          <Typography variant="overline" gutterBottom sx={sxProps.modalLabel}>
            {t(
              `+serviceRequest.details.actions.statusTransitionForm.changeStatusLabel`
            )}
          </Typography>
          <Typography variant="h1" component="div" sx={sxProps.modalTitle}>
            {transition.status}
          </Typography>
          {renderTransitionForm(formFormat)}
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            sx={sxProps.cancelButton}
            onClick={() => setOpen(false)}
            disabled={isLoading}
          >
            <CancelOutlined sx={sxProps.buttonIcon} />
            {t(
              `+serviceRequest.details.actions.statusTransitionForm.cancelButton`
            )}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={sxProps.confirmButton}
            onClick={() => {
              submitForm(formFormat, transition);
            }}
            disabled={isLoading}
          >
            <CheckCircleOutline sx={sxProps.buttonIcon} />
            {t(
              `+serviceRequest.details.actions.statusTransitionForm.confirmButton`
            )}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
