import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";

import { ServiceRequestHeader } from "../ServiceRequestHeader";
import { ServiceRequestDetailsActions } from "./components/ServiceRequestDetailsActions";
import { ServiceRequestDetailsInformation } from "./components/ServiceRequestDetailsInformation";
import { sxProps } from "./service-request-details.style";
import { ShowLocationMap } from "../../../shared/components";
import { useTicket } from "../../ticketContextProvider";
import Carousel, { Settings } from "react-slick";
import {
  selectFilteredTickets,
  selectTickets,
} from "../../../shared/store/ticketSlice";
import { paths } from "../../../shared/routes/paths";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../shared/store/hooks";

export const ServiceRequestDetails = () => {
  const ticket = useTicket();
  const navigate = useNavigate();
  const filteredTickets = useAppSelector((state) =>
    selectFilteredTickets(state)
  );
  const unfliteredTickets = useAppSelector((state) => selectTickets(state));
  const [orderedTicketKeys, setOrderedTicketKeys] = useState<string[]>([]);

  //we want this effect only to be run when component is just loaded
  //it will create list of ordered keys that represents order of tickets,
  //even if they change state
  useEffect(() => {
    if (orderedTicketKeys.length === 0) {
      setOrderedTicketKeys(filteredTickets.map((t) => t.key));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTicketIndexByKey = (ticketKey: string | undefined) => {
    return orderedTicketKeys.findIndex((key: string) => {
      return key === ticketKey;
    });
  };

  const getTicketByKey = (ticketKey: string | undefined) => {
    return unfliteredTickets.find((t) => t.key === ticketKey);
  };

  const settings: Settings = {
    dots: false,
    adaptiveHeight: true,
    swipe: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: getTicketIndexByKey(ticket?.key),
    beforeChange(currentSlide: number, nextSlide) {
      const nextTicket = getTicketByKey(orderedTicketKeys[nextSlide]);
      if (nextTicket) {
        navigate(
          `${paths.serviceRequest}/${nextTicket.key}${paths.serviceRequestDetails}`
        );
      }
    },
  };

  if (!ticket || orderedTicketKeys.length === 0) return null;
  return (
    <Box sx={sxProps.serviceRequestDetailsBox}>
      <Carousel {...settings}>
        {orderedTicketKeys.map((key: string, i) => {
          const t = getTicketByKey(key);
          if (!t) return null;
          return (
            <Box key={i}>
              <Container>
                <ServiceRequestHeader ticket={t} />
                <ServiceRequestDetailsActions ticket={t} />
                <ServiceRequestDetailsInformation ticket={t} />
              </Container>
              <ShowLocationMap
                address={t.fullAddress || t.streetAddress}
                containerSx={sxProps.serviceRequestDetailsMap}
              />
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};
