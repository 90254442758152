import { getEnvValue } from "../../utils/env.helper";
import { createInstance } from "../../utils/axios.helper";

export class GoogleMapsClient {
  private client;

  constructor() {
    this.client = createInstance();
  }

  public getCoordinates = async (address: string) => {
    const path = getEnvValue("GOOGLE_API_URL");
    const key = getEnvValue("GOOGLE_API_KEY");
    try {
      const response = await this.client.get<any>(path, {
        params: {
          address,
          key,
        },
      });
      if (response.data.results[0]) {
        return response.data.results[0].geometry.location;
      }
      //fails silently
    } catch {}
  };
}
