import { RequestsTypes } from "../types/Requests";
import { addErrorSnackbar } from "../store/snackbarSlice";
import { store } from "../store";

export const handleError = (
  cause: RequestsTypes,
  code: number,
  message?: string
) => {
  switch (cause) {
    case RequestsTypes.GET_TICKET:
      switch (code) {
        case 404:
          store.dispatch(addErrorSnackbar("error.ticketNotFound"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotLoadTicket"));
      }
      break;
    case RequestsTypes.ADD_COMMENT:
      switch (code) {
        case 404:
          store.dispatch(addErrorSnackbar("error.ticketNotFound"));
          break;
        case 403:
          store.dispatch(addErrorSnackbar("error.noPermission"));
          break;
        case 405:
          store.dispatch(addErrorSnackbar("error.notAllowed"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotAddComment"));
      }
      break;
    case RequestsTypes.GET_ATTACHMENT:
      switch (code) {
        case 404:
          store.dispatch(addErrorSnackbar("error.attachmentNotFound"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotLoadAttachment"));
      }
      break;
    case RequestsTypes.TRANSITION:
      switch (code) {
        case 400:
          store.dispatch(addErrorSnackbar("error.badTransitionId"));
          break;
        case 404:
          store.dispatch(addErrorSnackbar("error.ticketNotFound"));
          break;
        case 405:
          store.dispatch(addErrorSnackbar("error.notAllowed"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotMakeTransition"));
      }
      break;
    case RequestsTypes.TRANSITION_TO_STATUS:
      switch (code) {
        case 400:
          store.dispatch(addErrorSnackbar(message || "error.badTransitionId"));
          break;
        case 404:
          store.dispatch(addErrorSnackbar("error.ticketNotFound"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotMakeTransition"));
      }
      break;
    case RequestsTypes.GET_PROFILE:
      switch (code) {
        case 404:
          store.dispatch(addErrorSnackbar("error.userNotFound"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotAuthenticate"));
      }
      break;
    case RequestsTypes.QUERY_USER_SUGGESTION:
      switch (code) {
        case 400:
          store.dispatch(addErrorSnackbar("error.queryParameterMissing"));
          break;
        default:
          store.dispatch(addErrorSnackbar("error.cannotFindUser"));
      }
      break;
    case RequestsTypes.POST_BOOKING:
      switch (code) {
        default:
          store.dispatch(addErrorSnackbar("error.cannotPostBooking"));
      }
      break;
    case RequestsTypes.GET_MESSAGE:
      switch (code) {
        default:
          store.dispatch(addErrorSnackbar("error.cannotGetMessage"));
      }
      break;
    case RequestsTypes.POST_MESSAGE:
      switch (code) {
        default:
          store.dispatch(addErrorSnackbar("error.cannotPostMessage"));
      }
      break;
    case RequestsTypes.GET_WORKFLOW:
      switch (code) {
        default:
          store.dispatch(addErrorSnackbar("error.cannotGetWorkflow"));
      }
      break;
    default:
      store.dispatch(addErrorSnackbar("error.apiError"));
  }
};
