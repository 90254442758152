import { SxProps } from "@mui/system/styleFunctionSx";
import { Theme } from "@mui/material/styles/createTheme";

export const sxProps: Record<string, SxProps<Theme>> = {
  mention: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: (theme) => theme.spacing(1),
    backgroundColor: (theme) => theme.palette.common.white,
    borderTop: "1px solid",
    borderColor: (theme) => theme.palette.grey["400"],
    "&:last-child": {
      borderBottom: "1px solid",
    },
  },
  mentionAvatar: {
    margin: (theme) => theme.spacing(0, 2),
    width: (theme) => theme.spacing(6),
    height: (theme) => theme.spacing(6),
  },
};
