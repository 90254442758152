import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import List from "@mui/icons-material/List";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import ExitToApp from "@mui/icons-material/ExitToApp";

import { sxProps } from "./menu.style";
import { MenuItem } from "../MenuItem/menu-item.component";
import { paths } from "../../../routes/paths";
import { useAppSelector } from "../../../store/hooks";
import { selectLoggedUser } from "../../../store/authSlice";
import { logoutUser } from "../../../provider/authProvider";

enum MenuButtons {
  DASHBOARD = "DASHBOARD",
  CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST",
  PROFILE = "PROFILE",
  LOGOUT = "LOGOUT",
}

const menuIcons = {
  [MenuButtons.DASHBOARD]: <List />,
  [MenuButtons.CREATE_SERVICE_REQUEST]: <AddCircleOutline />,
  [MenuButtons.PROFILE]: <PersonOutlined />,
  [MenuButtons.LOGOUT]: <ExitToApp />,
};

export const Menu = () => {
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));
  const location = useLocation();
  const navigation = useNavigate();

  const {
    dashboard: DASHBOARD_PATH,
    createServiceRequest: CREATE_SERVICE_REQUEST_PATH,
    profile: PROFILE_PATH,
    root: ROOT_PATH,
  } = paths;

  const active = useMemo(() => {
    if (location.pathname.includes(DASHBOARD_PATH)) {
      return MenuButtons.DASHBOARD;
    }
    if (location.pathname.includes(CREATE_SERVICE_REQUEST_PATH)) {
      return MenuButtons.CREATE_SERVICE_REQUEST;
    }
    if (location.pathname.includes(PROFILE_PATH)) {
      return MenuButtons.PROFILE;
    }
    return undefined;
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const logoutAction = () => {
    logoutUser(loggedUser);
    navigation(ROOT_PATH);
  };

  const onButtonClick = (buttonClicked: MenuButtons) => {
    switch (buttonClicked) {
      case MenuButtons.DASHBOARD:
        navigation(DASHBOARD_PATH);
        break;
      case MenuButtons.CREATE_SERVICE_REQUEST:
        navigation(CREATE_SERVICE_REQUEST_PATH);
        break;
      case MenuButtons.PROFILE:
        navigation(PROFILE_PATH);
        break;
      case MenuButtons.LOGOUT:
        logoutAction();
        break;
    }
  };

  if (loggedUser) {
    return (
      <BottomNavigation sx={sxProps.bottomNavigation}>
        {Object.values(MenuButtons).map((button) => {
          const isActive = active === button;

          return (
            <BottomNavigationAction
              key={button}
              //disable create service request menu item
              disabled={button === MenuButtons.CREATE_SERVICE_REQUEST}
              icon={<MenuItem active={isActive}>{menuIcons[button]}</MenuItem>}
              sx={sxProps.bottomNavigationAction}
              onClick={() => onButtonClick(button)}
            />
          );
        })}
      </BottomNavigation>
    );
  } else {
    return null;
  }
};
