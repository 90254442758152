import React, { useMemo, ReactNode, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ReactComponent as CalendarIconCheck } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-check-1.svg";
import { ReactComponent as CalendarIconHome } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-home.svg";
import { ReactComponent as CalendarIconFavorite } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-favorite-1.svg";
import { ReactComponent as CalendarIconRefresh } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-refresh.svg";
import { ReactComponent as TouchIdIcon } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/touchid/touch-id-1.svg";
import { ReactComponent as MapsPinIcon } from "@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin-1.svg";
import { ReactComponent as ReporterIcon } from "@heimstaden/icons-library/img/streamline-regular/users/geometric-full-body-single-user-actions-man/single-man-actions-warning.svg";
import { ReactComponent as DoorIcon } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/login-2.svg";

import { MailOpenIcon, PhoneIcon } from "@heroicons/react/solid";
import dateFormat from "dateformat";

import { Ticket } from "../../../../../shared/types/Ticket";
import { sxProps } from "./service-request-details-information.style";
import { DetailInformation } from "./components/DetailInformation";
import {
  generateEmailLink,
  generatePhoneLink,
  getFailureDetails,
} from "./util";
import { DetailInformationLink } from "./components/DetailInformationLink";
import { DetailInformationAppointment } from "./components/DetailInformationAppointment";

export type ServiceRequestDetailsInformationProps = {
  ticket: Ticket;
};

export const ServiceRequestDetailsInformation = ({
  ticket,
}: ServiceRequestDetailsInformationProps) => {
  const { t } = useTranslation();
  const [appointmentDate, setAppointmentDate] = useState<Date>(
    ticket?.bookingDate
  );
  const dateMask = "dd.mm.yyyy";
  const fullDateMask = "dd.mm.yyyy HH:MM";

  const failureDetails = useMemo(() => {
    const failureDetails = getFailureDetails(ticket, t);
    const details: ReactNode[] = failureDetails.map((fd) => {
      return (
        <DetailInformation
          key={fd.key}
          icon={fd.icon}
          information={fd.failureInformation}
          label={t(fd.labelKey)}
        />
      );
    });
    return details;
  }, [ticket]); // eslint-disable-line react-hooks/exhaustive-deps

  const estimatedDate = useMemo(() => {
    if (!ticket.estimatedDate) {
      return <></>;
    }
    return (
      <DetailInformation
        label={t("+serviceRequest.details.estimatedDate")}
        information={dateFormat(ticket.estimatedDate, dateMask)}
        icon={CalendarIconRefresh}
      />
    );
  }, [ticket]); // eslint-disable-line react-hooks/exhaustive-deps

  const bookingDate = useMemo(() => {
    if (!appointmentDate) {
      return;
    } else {
      return dateFormat(appointmentDate, fullDateMask);
    }
  }, [appointmentDate]);

  return (
    <Box sx={sxProps.detailInformationsBox}>
      <DetailInformation
        icon={CalendarIconFavorite}
        information={dateFormat(ticket.created, fullDateMask)}
        label={t("+serviceRequest.details.creationDate")}
      />
      <DetailInformationAppointment
        icon={CalendarIconHome}
        information={bookingDate}
        appointment={appointmentDate}
        setAppointment={setAppointmentDate}
        label={"appointment"}
      />
      <DetailInformation
        icon={CalendarIconCheck}
        information={dateFormat(ticket.dueDate, dateMask)}
        label={t("+serviceRequest.details.dueDate")}
        isError={ticket.overDue}
      />
      {estimatedDate}
      <DetailInformation
        icon={ReporterIcon}
        information={ticket.reporter?.displayName}
        label={t("+serviceRequest.details.reporter")}
      />
      {failureDetails}
      <Box sx={sxProps.detailInformationDescriptionBox}>
        <Typography variant="overline" sx={sxProps.detailInformationHeader}>
          {t("+serviceRequest.details.description")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">{ticket.description}</Typography>
      </Box>
      <Box sx={sxProps.detailInformationCustomerBox}>
        <Typography variant="overline" sx={sxProps.detailInformationHeader}>
          {t("+serviceRequest.details.customerInfo")}
        </Typography>
        <Box sx={sxProps.detailInformationCustomerTitleBox}>
          <Typography variant="h1">{ticket.fullName}</Typography>
        </Box>
        <Box sx={sxProps.detailInformationCustomerButtonsBox}>
          <Stack>
            <DetailInformationLink
              link={generateEmailLink(ticket)}
              information={ticket.email}
              icon={MailOpenIcon}
            />
            <DetailInformationLink
              link={generatePhoneLink(ticket)}
              information={ticket.mobile}
              icon={PhoneIcon}
            />
          </Stack>
        </Box>
        <Box sx={sxProps.detailInformationCustomerDetailsBox}>
          <DetailInformation
            icon={TouchIdIcon}
            information={ticket.tenantId}
            label={t("+serviceRequest.details.customerId")}
          />
          <DetailInformation
            icon={MapsPinIcon}
            information={ticket.fullAddress}
            label={t("+serviceRequest.details.customerAddress")}
          />
          <DetailInformation
            icon={DoorIcon}
            information={ticket.apartmentNumber}
            label={t("+serviceRequest.details.apartmentNumber")}
          />
        </Box>
      </Box>
    </Box>
  );
};
