import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ParsedQuery } from "query-string";

import { AuthClient } from "./clients/auth.client";
import { AuthType, AuthUser } from "../types/AuthUser";
import { LoginDataType } from "../../+login/components/LoginCardContent/const/login-data";
import { AuthenticationTypeEnum } from "../../../connectors/user";
import { store } from "../store";
import {
  logout,
  selectLoggedUser,
  updateAccessAndIdTokens,
} from "../store/authSlice";
import { addErrorSnackbar, addInfoSnackbar } from "../store/snackbarSlice";

const auth = new AuthClient();
type AsyncFunction<T> = (...args: any[]) => Promise<T>;

export type AuthProviderError = {
  messageKey: string;
  type: AuthProviderErrorType;
};

export enum AuthProviderErrorType {
  TOKEN_EXPIRED,
  API_ERROR,
  NOT_LOGGED_ERRROR,
}

// DUMMY START!!
//this is a dummy function for async validation and will be removed when real login logic is implemented
//
// POSSIBLY WILL BE REMOVED
//
export const loginUser = async (data: LoginDataType, remember: boolean) => {
  await new Promise((r) => setTimeout(r, 1500));
  if (data.login !== "test@heimstaden.com" || data.password !== "test1234") {
    throw new Error();
  }
  const dummyUser: AuthUser = {
    idToken: "1234",
    accessToken: "Test",
    refreshToken: "Test",
    type: AuthType.EMAIL_AND_PASSWORD,
  };
  return dummyUser;
};
//DUMMY END!!

//first step in Azure login
//which redirects to Azure login page
export const loginAzure = async () => {
  await auth.loginByProvider(AuthenticationTypeEnum.Azure);
};

//second step in Azure login
//which will be called after Azure redirects back to the application
export const authorizationAzure = async (query: ParsedQuery) => {
  const requestConfig: AxiosRequestConfig = {
    params: { ...query },
  };
  try {
    const authUser = await auth.authorize(
      AuthenticationTypeEnum.Azure,
      requestConfig
    );
    return authUser;
  } catch {
    throw createError(AuthProviderErrorType.API_ERROR);
  }
};

export const logoutUser = async (authUser?: AuthUser) => {
  if (authUser) {
    auth.logout(authUser.type);
  }
  store.dispatch(logout());
};

//when accessToken has expired, this will help refresh it and run function again
export const executeWithRefresh = async <T>(params: {
  authUser: AuthUser;
  func: AsyncFunction<T>;
  args?: any[];
  silentFail?: boolean;
}) => {
  const { authUser, func, silentFail } = params;
  const args = params.args || [];
  let refreshedAccessToken: string | undefined = "";
  try {
    return await func(authUser.accessToken, ...args);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      let status = (error as AxiosError).response?.status;
      if (status === 401) {
        if (!params.silentFail) {
          store.dispatch(addInfoSnackbar("error.refreshing"));
        }
        refreshedAccessToken = await refreshUser(authUser);
        if (!refreshedAccessToken) {
          createError(AuthProviderErrorType.TOKEN_EXPIRED);
        }
      }
    }
  }
  try {
    return await func(refreshedAccessToken, ...args);
  } catch (error) {
    if (
      !window.navigator.onLine &&
      !error.response &&
      error.toJSON().message === "Network Error"
    ) {
      store.dispatch(addErrorSnackbar("error.offline"));
    } else if (!silentFail) {
      createError(AuthProviderErrorType.API_ERROR);
    }
  }
};

export const getLoggedUser = () => {
  const loggedUser = selectLoggedUser(store.getState());
  if (!loggedUser) {
    throw createError(AuthProviderErrorType.NOT_LOGGED_ERRROR);
  } else {
    return loggedUser;
  }
};

//refresh user token. if refresh fails, logout user and show snackbar
const refreshUser = async (authUser: AuthUser) => {
  const refreshedTokens = await auth.refresh(authUser);
  if (refreshedTokens) {
    store.dispatch(updateAccessAndIdTokens(refreshedTokens));
    return refreshedTokens.accessToken;
  } else {
    logoutUser(authUser);
    return undefined;
  }
};

const createError = (type: AuthProviderErrorType) => {
  let messageKey;
  switch (type) {
    case AuthProviderErrorType.TOKEN_EXPIRED:
      messageKey = "error.tokenExpired";
      break;
    case AuthProviderErrorType.API_ERROR:
      messageKey = "error.apiError";
      break;
    case AuthProviderErrorType.NOT_LOGGED_ERRROR:
      messageKey = "error.userNotLogged";
      break;
  }
  store.dispatch(addErrorSnackbar(messageKey));
  const error: AuthProviderError = { messageKey, type };
  return error;
};
