import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  detailInformationsBox: {
    padding: (theme) => theme.spacing(2, 1.5, 0, 1.5),
  },

  detailInformationDescriptionBox: {
    paddingTop: (theme) => theme.spacing(5),
  },

  detailInformationCustomerBox: {
    paddingTop: (theme) => theme.spacing(4.5),
  },

  detailInformationCustomerTitleBox: {
    paddingTop: (theme) => theme.spacing(0.25),
    paddingBottom: (theme) => theme.spacing(1),
  },

  detailInformationCustomerDetailsBox: {
    paddingTop: (theme) => theme.spacing(2),
  },

  detailInformationLabel: {
    color: (theme) => theme.palette.grey[500],
  },

  detailInformationHeader: {
    color: (theme) => theme.palette.primary.main,
  },

  detailInformationText: {
    marginTop: "-5px",
  },

  detailInformationError: {
    color: (theme) => theme.palette.error.main,
  },

  detailInformationIcon: {
    color: (theme) => theme.palette.grey[500],
    width: (theme) => theme.spacing(4.5),
    height: (theme) => theme.spacing(4.5),
    margin: (theme) => theme.spacing(0.6, 2.5, 0, 0),
    "& path": {
      strokeWidth: "1.5px",
    },
  },
};
