import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import SendIcon from "@mui/icons-material/Send";

import { sxProps } from "./message-input.style";
import { useTranslation } from "react-i18next";
import { RichTextEditorComponent } from "../../../../../shared/components";
import { addWarningSnackbar } from "../../../../../shared/store/snackbarSlice";
import { useAppDispatch } from "../../../../../shared/store/hooks";

export type MessageInputProps = {
  send: (isPublic: boolean) => void;
  disabled: boolean;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
};

export const MessageInput = ({
  send,
  disabled,
  message,
  setMessage,
}: MessageInputProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [mentions, setMentions] = useState(false);
  const [attachments, setAttachments] = useState(false);
  const sendMessage = (isPublic: boolean) => {
    if (message.trim()) {
      send(isPublic);
    }
  };

  useEffect(() => {
    if (mentions && attachments) {
      dispatch(addWarningSnackbar(t("error.attachmentAndMentionInMessage")));
    }
  }, [mentions, attachments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <RichTextEditorComponent
        message={message}
        setMessage={setMessage}
        setMentions={setMentions}
        setAttachments={setAttachments}
      />
      <Box sx={sxProps.buttonsBox}>
        <Button
          sx={sxProps.sendInternalButton}
          color="warning"
          variant="contained"
          disabled={disabled || attachments}
          onClick={() => sendMessage(false)}
        >
          <CommentIcon />
          <Typography variant="button" sx={sxProps.buttonText}>
            {t("+serviceRequest.messages.sendInternalButton")}
          </Typography>
        </Button>
        <Button
          sx={sxProps.sendPublicButton}
          color="primary"
          variant="contained"
          disabled={disabled || mentions}
          onClick={() => sendMessage(true)}
        >
          <SendIcon />
          <Typography variant="button" sx={sxProps.buttonText}>
            {t("+serviceRequest.messages.sendPublicButton")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
