import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Paper, Box, Typography, List, Divider } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";

import { sxProps } from "./notification-modal.style";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectNextNotificationPage,
  selectNotifications,
  selectHasNextNotificationPage,
} from "../../../store/notificationSlice";
import {
  groupNotificationsToNotificationItems,
  NotificationItemData,
} from "./util";
import { NotificationProvider } from "../../../provider/notificationProvider";
import { Spinner } from "../..";
import { NotificationItem } from "./components/NotificationItem";
import { TicketProvider } from "../../../provider/ticketProvider";

export type NotificationModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const notificationListId = "notificationListId";
const notificationProvider = new NotificationProvider();
const ticketProvider = new TicketProvider();

export const NotificationModal = ({
  open,
  setOpen,
}: NotificationModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((store) => selectNotifications(store));
  const hasNextNotificationPage = useAppSelector((store) =>
    selectHasNextNotificationPage(store)
  );
  const nextNotificationPage = useAppSelector((store) =>
    selectNextNotificationPage(store)
  );
  const [notificationItems, setNotificationItems] = useState<
    NotificationItemData[]
  >([]);

  useEffect(() => {
    setNotificationItems(groupNotificationsToNotificationItems(notifications));
  }, [notifications]);

  return (
    <Modal open={open} sx={sxProps.modal} onClose={() => setOpen(false)}>
      <Paper sx={sxProps.modalPaper}>
        <Box sx={sxProps.titleBox}>
          <Typography variant="h6">
            {t("shared.notifications.title")}
          </Typography>
        </Box>
        <List sx={sxProps.notificationList} id={notificationListId}>
          <InfiniteScroll
            dataLength={notificationItems.length}
            next={() => {
              notificationProvider.fetchAllNotifications({
                page: nextNotificationPage,
              });
            }}
            loader={<Spinner />}
            hasMore={Boolean(hasNextNotificationPage)}
            scrollableTarget={notificationListId}
          >
            {notificationItems.flatMap((data, i) => {
              return [
                <NotificationItem
                  notificationProvider={notificationProvider}
                  ticketProvider={ticketProvider}
                  data={data}
                  t={t}
                  key={`notification-item-${i * 2}`}
                  navigate={navigate}
                  dispatch={dispatch}
                  closeModal={() => setOpen(false)}
                />,
                <Divider
                  sx={sxProps.divider}
                  variant="fullWidth"
                  key={`notification-item-${i * 2 + 1}`}
                />,
              ];
            })}
          </InfiniteScroll>
        </List>

        <Box sx={sxProps.markAllAsReadBox}>
          <Typography
            variant="button"
            onClick={() => {
              notificationProvider.markAllAsRead();
              setOpen(false);
            }}
          >
            {t("shared.notifications.markAllAsRead")}
          </Typography>
        </Box>
      </Paper>
    </Modal>
  );
};
