import React from "react";
import { Container } from "@mui/material";
import { sxProps } from "./app-container.style";
import { useSnackbarWatcher } from "../../hooks/";

export interface AppContainerProps {
  children: React.ReactNode;
}

// Container for whole application
// Will surround application on screens larger then 600px
// On smaller screens will not be shown
export const AppContainer = (props: AppContainerProps) => {
  useSnackbarWatcher();

  return (
    <Container sx={sxProps.appContainer} maxWidth="sm">
      {props.children}
    </Container>
  );
};
