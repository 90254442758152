import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardTicketListItem: {
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
    background: (theme) => theme.palette.common.white,
    borderRadius: (theme) => theme.spacing(0.5),
    minHeight: (theme) => theme.spacing(12.5),
    margin: (theme) => theme.spacing(0.875, 0.875, 0, 0.875),
    position: "relative",
    "&:last-child": {
      marginBottom: (theme) => theme.spacing(0.875),
    },
  },

  dashboardTicketListItemContentWrapper: {
    padding: (theme) => theme.spacing(1.625, 0, 1.625, 2.375),
    marginRight: (theme) => theme.spacing(3.625),
  },

  dashboardTicketListItemTopText: {
    color: (theme) => theme.palette.grey[500],
    fontWeight: 700,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },

  dashboardTicketListItemCenterText: {
    color: (theme) => theme.palette.text.secondary,
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    marginBottom: (theme) => theme.spacing(1.5),
  },

  dashboardTicketListItemBottomText: {
    paddingTop: (theme) => theme.spacing(1.5),
    color: (theme) => theme.palette.text.primary,
  },

  dashboardTicketListItemIcon: {
    width: (theme) => theme.spacing(2),
    height: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(-0.5),
    "& path": {
      strokeWidth: "1.5px",
      stroke: (theme) => theme.custom.icons,
    },
  },

  dashboardTicketListItemAddressBox: {
    marginBottom: (theme) => theme.spacing(0.5),
  },

  dashboardTicketListDoorIcon: {
    marginLeft: (theme) => theme.spacing(2),
  },

  dashboardTicketListItemOverdue: {
    color: (theme) => theme.palette.error.main,
  },

  dashboardTicketListItemDetail: {
    marginRight: (theme) => theme.spacing(2),
  },
};
