export enum RequestsTypes {
  GET_TICKET,
  ADD_COMMENT,
  GET_ATTACHMENT,
  TRANSITION,
  TRANSITION_TO_STATUS,
  GET_PROFILE,
  QUERY_USER_SUGGESTION,
  POST_BOOKING,
  GET_MESSAGE,
  POST_MESSAGE,
  GET_WORKFLOW,
}
