import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router";

import { NotificationProvider } from "../../../../../provider/notificationProvider";
import { sxProps } from "./notification-item.style";
import { useAppSelector } from "../../../../../store/hooks";
import { selectTicketByKey } from "../../../../../store/ticketSlice";
import { setNotificationAsRead } from "../../../../../store/notificationSlice";
import { TicketProvider } from "../../../../../provider/ticketProvider";
import { NotificationItemData } from "../../util";
import { globalSxProps } from "../../../../../style";
import { getSpecificItemProps, SpecificItemProps } from "./util";
import { AppDispatch } from "../../../../../store";

export type NotificationItemProps = {
  data: NotificationItemData;
  notificationProvider: NotificationProvider;
  ticketProvider: TicketProvider;
  t: TFunction;
  navigate: NavigateFunction;
  dispatch: AppDispatch;
  closeModal: () => void;
};

export const NotificationItem = ({
  data: { type, ticketKey, notificationObjects },
  notificationProvider,
  ticketProvider,
  navigate,
  dispatch,
  t,
  closeModal,
}: NotificationItemProps) => {
  const ticket = useAppSelector((store) => selectTicketByKey(store, ticketKey));

  const fetchTicket = useCallback(async () => {
    //for each notification item, we will try to fetch related ticket
    const ticket = await ticketProvider.fetchTicket(ticketKey, {
      silentFail: true,
    });
    //if ticket does not exist, we will remove this notification from state, and mark it as read
    //so it does not appear anymore. This happens when ticket is removed, but there are still notifications
    //that are connected to it
    if (!ticket) {
      notificationObjects.forEach((n) => dispatch(setNotificationAsRead(n.id)));
      await notificationProvider.markAsRead(notificationObjects);
    }
  }, [
    ticketKey,
    dispatch,
    notificationObjects,
    notificationProvider,
    ticketProvider,
  ]);

  useEffect(() => {
    if (!ticket) {
      fetchTicket();
    }
  }, [fetchTicket, ticket]);

  //based on notification types, creates object that will be used to properly display notification
  const notification = useMemo<SpecificItemProps | undefined>(() => {
    if (!ticket) return;
    return getSpecificItemProps({
      type,
      ticket,
      t,
      notificationObjects,
      navigate,
      dispatch,
    });
  }, [type, t, ticket, notificationObjects, navigate, dispatch]);

  const onClick = useCallback(async () => {
    if (!notification) return;
    await notification.onClick();
    notificationProvider.markAsRead(notificationObjects);
    closeModal();
  }, [notification, notificationProvider, closeModal, notificationObjects]);

  if (!notification) {
    return null;
  }
  return (
    <Box sx={sxProps.notificationItemWrapper} onClick={onClick}>
      <Box sx={sxProps.notificationIconWrapper}>{notification.icon}</Box>
      <Box sx={sxProps.notificationTextWrapper}>
        <Typography variant="subtitle2" sx={globalSxProps.singleLineOverflow}>
          {notification.text}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            ...sxProps.notificationLabel,
            ...globalSxProps.singleLineOverflow,
          }}
        >
          {notification.label}
        </Typography>
      </Box>
      <Divider sx={sxProps.divider} variant="fullWidth" />
    </Box>
  );
};
