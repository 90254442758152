import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardTicketList: {
    minHeight: (theme) => theme.custom.dashboardListHeight,
    height: (theme) => theme.custom.dashboardListHeight,
    paddingTop: 0,
    overflow: "scroll",
  },
};
