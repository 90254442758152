import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  statusSelectSimple: {
    width: "100%",
    zIndex: 2,
    height: (theme) => theme.spacing(3),
    borderRadius: "8px !important",
    background: (theme) => `${theme.palette.common.white} !important`,
  },

  statusSelectColored: {
    width: "100%",
    zIndex: 2,
    height: (theme) => theme.spacing(5.2),
    borderRadius: "16px !important",
    color: (theme) => theme.palette.common.white,
    "& .Mui-disabled": {
      WebkitTextFillColor: (theme) =>
        `${theme.palette.common.white} !important`,
    },
    "& svg": {
      color: (theme) => `${theme.palette.common.white} !important`,
    },
  },

  statusSelectInput: {
    padding: 0,
    background: "none !important",
  },

  statusSelectMenu: {
    zIndex: 1,
    "& .MuiList-root": {
      padding: (theme) => theme.spacing(2.5, 0, 0.5, 0),
      paddingTop: (theme) => theme.spacing(2.5),
      paddingBottom: (theme) => theme.spacing(0.5),
      borderRadius: "8px",
      "& hr:last-child": {
        display: "none",
      },
    },
  },

  menuItem: {
    minHeight: (theme) => theme.spacing(3),
    padding: (theme) => theme.spacing(0),
    background: (theme) => `${theme.palette.common.white} !important`,
  },

  hiddenItem: {
    display: "none",
  },

  divider: {
    marginBottom: "0 !important",
    marginTop: "0 !important",
  },

  openMenuBackground: {
    backgroundColor: (theme) => `${theme.palette.grey[500]} !important`,
  },
  inProgressMenuBackground: {
    backgroundColor: (theme) => `${theme.palette.info.main} !important`,
  },
  onHoldMenuBackground: {
    backgroundColor: (theme) => `${theme.palette.error.main} !important`,
  },
  awaitingTenenatMenuBackground: {
    backgroundColor: (theme) => `${theme.custom.activeReading} !important`,
  },
  awaitingExternalMenuBackground: {
    backgroundColor: (theme) => `${theme.custom.waitingExternal} !important`,
  },
  closedMenuBackground: {
    backgroundColor: (theme) => `${theme.palette.success.main} !important`,
  },
  subheaderProps: {
    padding: (theme) => theme.spacing(0, 0, 0, 2.5),
    color: (theme) => theme.palette.grey[500],
    lineHeight: "3rem",
  },
};
