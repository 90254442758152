import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";
import { theme } from "../../../../../shared/style";

export const sxProps: Record<string, SxProps<Theme>> = {
  messageInput: {
    width: "100%",
    marginBottom: (theme) => theme.spacing(2),
  },
  mentionsBox: {
    display: "block",
    width: "100%",
    backgroundColor: (theme) => theme.palette.common.white,
  },
  buttonsBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: (theme) => theme.spacing(1.5),
  },
  sendInternalButton: {
    color: (theme) => theme.palette.primary.contrastText,
    height: (theme) => theme.spacing(5.25),
    marginRight: theme.spacing(1),
    flex: 4,
  },
  sendPublicButton: {
    height: (theme) => theme.spacing(5.25),
    flex: 3,
  },
  buttonText: {
    marginLeft: (theme) => theme.spacing(0.25),
  },
};
