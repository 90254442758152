import { store } from "../store";
import { setLoading } from "../store/loadingSlice";
import { Attachment, AttachmentForUpload, Ticket } from "../types/Ticket";
import { JiraServiceClient } from "./clients/jira-service.client";
import { executeWithRefresh, getLoggedUser } from "./authProvider";
import {
  addAttachmentRecord,
  AttachmentRecord,
  removeAttachments,
  selectAttachmentsContentMapForTicket,
} from "../store/attachmentSlice";

export class AttachmentProvider {
  private client: JiraServiceClient;

  constructor() {
    this.client = new JiraServiceClient();
  }

  fetchAttachments = async (ticket: Ticket) => {
    store.dispatch(setLoading(true));
    const authUser = getLoggedUser();
    //we only want to fetch attachments that are not already loaded
    //we want to remove attachments that are not existing on ticket anymore
    let attachmentsToLoad = this.getAttachmentToLoad(ticket);
    let attachmentsToRemove = this.getAttachmentToRemove(ticket);
    let attachmentsCounter = 0;
    const attachmentRecord: AttachmentRecord = {};
    attachmentsToLoad.map(async (a) => {
      const attachmentContent = await executeWithRefresh({
        authUser,
        func: this.client.getAttachment,
        args: [a.url],
      });
      if (attachmentContent) {
        attachmentRecord[a.id] = attachmentContent;
        attachmentsCounter++;
      }
      store.dispatch(
        addAttachmentRecord({ ticketId: ticket.id, attachmentRecord })
      );
      // we have to keep loader true until all attachments are loaded, even if other processes are setting it to false in the meantime
      if (attachmentsToLoad.length === attachmentsCounter) {
        store.dispatch(setLoading(false));
      } else {
        store.dispatch(setLoading(true));
      }
    });
    store.dispatch(
      removeAttachments({
        ticketId: ticket.id,
        attachmentIds: attachmentsToRemove,
      })
    );
  };

  uploadAttachment = async (
    ticket: Ticket,
    attachments: AttachmentForUpload[]
  ) => {
    const authUser = getLoggedUser();
    store.dispatch(setLoading(true));
    await executeWithRefresh<void>({
      authUser,
      func: this.client.postAttachment,
      args: [ticket.id, attachments],
    });
    store.dispatch(setLoading(false));
  };

  private getAttachmentToLoad = (ticket: Ticket) => {
    const alreadyLoadedAttachments = selectAttachmentsContentMapForTicket(
      store.getState(),
      ticket
    );
    if (!alreadyLoadedAttachments) return ticket.attachments;
    const attachmentsToLoad: Attachment[] = ticket.attachments.filter(
      (a) => !alreadyLoadedAttachments[a.id]
    );
    return attachmentsToLoad;
  };

  private getAttachmentToRemove = (ticket: Ticket) => {
    const alreadyLoadedAttachments = selectAttachmentsContentMapForTicket(
      store.getState(),
      ticket
    );
    if (!alreadyLoadedAttachments) return [];
    const attachmentIdsToRemove: string[] = Object.keys(
      alreadyLoadedAttachments
    ).filter((id) => !ticket.attachments.find((a) => a.id === id));
    return attachmentIdsToRemove;
  };
}
