import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JiraProfile } from "../types/Profile";
import type { RootState } from "./index";

export interface ProfileState {
  profile?: JiraProfile;
}

const initialState: ProfileState = {};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setJiraProfile: (state, action: PayloadAction<JiraProfile>) => {
      state.profile = action.payload;
    },
  },
});

export const { setJiraProfile } = profileSlice.actions;
export const selectProfile = (state: RootState) => state.profile.profile;

export default profileSlice.reducer;
