import { TFunction } from "react-i18next";

import { store } from "../../../../../shared/store";
import {
  RequestTypesNamesEnum,
  Ticket,
} from "../../../../../shared/types/Ticket";
import {
  customerComplaintKey,
  failure,
  generalKey,
  problemKey,
} from "../../../../../shared/utils/failure.helper";
import { IconType } from "./components/DetailInformation";
import { getEmailBody } from "../../../../../shared/translation/email";

export const getFailureDetails = (ticket: Ticket, t: TFunction) => {
  const { failures } = store.getState().ticket;
  const details: {
    key: string;
    icon: IconType;
    failureInformation: string;
    labelKey: string;
  }[] = [];
  if (!ticket.failure) return [];
  switch (ticket.requestType.type) {
    case RequestTypesNamesEnum.COMPLAINTS:
      ticket.failure.forEach((f, i) => {
        const failureData = failure[i === 0 ? customerComplaintKey : f.id];
        let failureInformationKey: string =
          i === 0 ? "failure.customerComplaint" : `failure.${f.id}`;
        if (failureData && failureInformationKey) {
          details.push({
            key: f.id,
            icon: failureData.icon,
            failureInformation: t(failureInformationKey),
            labelKey: failureData.categoryKey,
          });
        }
      });
      break;
    case RequestTypesNamesEnum.GENERAL:
      const failureData = failure[generalKey];
      details.push({
        key: RequestTypesNamesEnum.GENERAL,
        icon: failureData.icon,
        failureInformation: t("failure.general"),
        labelKey: failureData.categoryKey,
      });
      break;
    case RequestTypesNamesEnum.OUTDOOR:
    case RequestTypesNamesEnum.UNIT:
    case RequestTypesNamesEnum.COMMON:
      for (const f of ticket.failure) {
        const failureInformation = failures[f.id];
        const failureData = failure[f.id] ? failure[f.id] : failure[problemKey];
        if (failureData && failureInformation) {
          details.push({
            key: f.id,
            icon: failureData.icon,
            failureInformation,
            labelKey: failureData.categoryKey,
          });
        }
      }
      break;
  }

  return details;
};

export const generatePhoneLink = (ticket: Ticket) => {
  return `tel:${ticket.mobile}`;
};

export const generateEmailLink = (ticket: Ticket) => {
  const body = encodeURIComponent(getEmailBody(ticket));
  return `mailto:${ticket.email}?subject=${ticket.key} - ${ticket.summary}&body=${body}`;
};
