import {
  Notification,
  ServiceRequestAssignedNotification,
  ServiceRequestNewAttachmentNotification,
  ServiceRequestNonAssigneeCommentAddedNotification,
  ServiceRequestOverdueNotification,
  ServiceRequestStatusChangedNotification,
  ServiceRequesUnassignedNotification,
} from "../../../generated/clients/notifications/data-contracts";
import { ValidNotificationTypes } from "../types/Notification";

export const extractTaskIdFromNotification = (n: Notification) => {
  switch (n.metadata.type) {
    case ValidNotificationTypes.SERVICE_REQUEST_ASSIGNED:
      return (n.metadata as ServiceRequestAssignedNotification).taskId;
    case ValidNotificationTypes.SERVICE_REQUEST_NEW_ATTACHMENT:
      return (n.metadata as ServiceRequestNewAttachmentNotification).taskId;
    case ValidNotificationTypes.SERVICE_REQUEST_NON_ASSIGNEE_COMMENT_ADDED:
      return (n.metadata as ServiceRequestNonAssigneeCommentAddedNotification)
        .taskId;
    case ValidNotificationTypes.SERVICE_REQUEST_OVERDUE:
      return (n.metadata as ServiceRequestOverdueNotification).taskId;
    case ValidNotificationTypes.SERVICE_REQUEST_STATUS_CHANGED:
      return (n.metadata as ServiceRequestStatusChangedNotification).taskId;
    case ValidNotificationTypes.SERVICE_REQUEST_UNASSIGNED:
      return (n.metadata as ServiceRequesUnassignedNotification).taskId;
  }
};
