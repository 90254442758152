import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { sxProps } from "./status-menu-item.style";
import { TicketStatus } from "../../../../types/Ticket";
import { globalSxProps } from "../../../../style";

export type StatusMenuItemProps = {
  label: string;
  status?: TicketStatus;
  selected: boolean;
  showCircles: boolean;
};

export const StatusMenuItem = ({
  label,
  status,
  selected,
  showCircles,
}: StatusMenuItemProps) => {
  const circles = useMemo(() => {
    switch (status) {
      case TicketStatus.OPEN:
        return (
          <Box sx={{ ...sxProps.menuItemCircle, ...sxProps.openCircle }} />
        );
      case TicketStatus.IN_PROGRESS:
        return (
          <Box
            sx={{ ...sxProps.menuItemCircle, ...sxProps.inProgressCircle }}
          />
        );
      case TicketStatus.ON_HOLD:
        return <Box sx={{ ...sxProps.menuItemCircle, ...sxProps.onHold }} />;
      case TicketStatus.AWAITING_TENANT:
        return (
          <Box sx={{ ...sxProps.menuItemCircle, ...sxProps.awaitingTenant }} />
        );
      case TicketStatus.TASK_IS_SENT_TO_EXTERNAL_PROVIDER:
        return (
          <Box
            sx={{ ...sxProps.menuItemCircle, ...sxProps.awaitingExternal }}
          />
        );
      case TicketStatus.CLOSED:
        return (
          <Box sx={{ ...sxProps.menuItemCircle, ...sxProps.closedCircle }} />
        );
      default:
        const allCircleSx = {
          ...sxProps.allCircles,
          ...sxProps.menuItemCircle,
        };

        return (
          <Box sx={sxProps.allCirclesContainer}>
            <Box
              sx={{
                ...allCircleSx,
                ...sxProps.openCircle,
                ...globalSxProps.statusOpen,
              }}
            />
            <Box sx={{ ...allCircleSx, ...sxProps.inProgressCircle }} />
            <Box sx={{ ...allCircleSx, ...sxProps.onHold }} />
            <Box sx={{ ...allCircleSx, ...sxProps.awaitingTenant }} />
            <Box sx={{ ...allCircleSx, ...sxProps.awaitingExternal }} />
          </Box>
        );
    }
  }, [status]);

  const showCirclesBox = useMemo(() => {
    return showCircles ? (
      <Grid item>
        <Box
          sx={
            status
              ? sxProps.menuItemSingleCircleBox
              : sxProps.menuItemAllCirclesBox
          }
        >
          {circles}
        </Box>
      </Grid>
    ) : (
      <></>
    );
  }, [showCircles, circles, status]);

  return (
    <Grid sx={sxProps.menuItem} container>
      {showCirclesBox}
      <Grid item>
        <Typography
          sx={selected ? sxProps.selectedMenuItemText : sxProps.menuItemText}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
