import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";

import { ServiceRequestHeader } from "../ServiceRequestHeader";
import { sxProps } from "./service-request-messages.style";
import { MessagesProvider } from "../../../shared/provider/messagesProvider";
import { selectMessagesForTicket } from "../../../shared/store/messageSlice";
import { useAppSelector } from "../../../shared/store/hooks";
import { selectLoading } from "../../../shared/store/loadingSlice";
import { MessageInput } from "./components/MessageInput";
import { MessageList } from "./components/MessageList";
import { useRefreshTicket, useTicket } from "../../ticketContextProvider";
import { imagesToUpload, parseHTMLMessage } from "./util";
import { AttachmentProvider } from "../../../shared/provider/attachmentProvider";
import { AttachmentForUpload } from "../../../shared/types/Ticket";

const messagesProvider = new MessagesProvider();
const attachmentProvider = new AttachmentProvider();

export const ServiceRequestMessages = () => {
  const ticket = useTicket();
  const refreshTicket = useRefreshTicket();
  const isLoading = useAppSelector((state) => selectLoading(state));
  const messages = useAppSelector((state) =>
    selectMessagesForTicket(state, ticket)
  );
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (ticket) {
      messagesProvider.fetchMessages(ticket);
    }
  }, [ticket]);

  const uploadImagesFromMessage = async (
    attachmentsForUpload: AttachmentForUpload[]
  ) => {
    if (!ticket || !refreshTicket || !attachmentsForUpload?.length) return;
    await attachmentProvider.uploadAttachment(ticket, attachmentsForUpload);
    await refreshTicket();
  };

  const sendMessage = async (isPublic: boolean) => {
    if (ticket) {
      const parsedMessage = parseHTMLMessage(message);
      await uploadImagesFromMessage(imagesToUpload);
      await messagesProvider.sendMessage(ticket, parsedMessage, isPublic);
      setMessage("");
    }
  };

  if (!ticket) return null;
  return (
    <Container sx={sxProps.serviceRequestMessagesBox}>
      <Grid
        container
        direction="column"
        sx={sxProps.serviceRequestMessagesContainer}
      >
        <Grid item flex={1}>
          <ServiceRequestHeader ellipsis={true} ticket={ticket} />
        </Grid>
        <Grid item flex={10} sx={sxProps.messageList}>
          <MessageList messages={messages} />
        </Grid>
        <Grid item flex={1}>
          <MessageInput
            disabled={isLoading}
            send={sendMessage}
            message={message}
            setMessage={setMessage}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
