import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  profileDetailsBox: {
    marginTop: (theme) => theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  profileDetailsEmail: {
    marginTop: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.primary.main,
  },
};
