import { Notification } from "../../../../../generated/clients/notifications/data-contracts";
import { extractTaskIdFromNotification } from "../../../utils/notification.helper";

export type NotificationItemData = {
  type: string;
  ticketKey: string;
  notificationObjects: Notification[];
};

//creating list of notifications to be displayed in modal
export const groupNotificationsToNotificationItems = (
  notifications: Record<string, Notification>
) => {
  const groupedNotifications: Record<
    string,
    Record<string, Notification[]>
  > = {};
  //for every value in record, that is not read, we will add
  //to the grouped notifications
  Object.values(notifications)
    .filter((n) => !n.readAt)
    .forEach((n) => {
      const {
        metadata: { type },
      } = n;
      //taskId is the key of the ticket that this notification is connected to
      const taskId = extractTaskIdFromNotification(n);
      if (!taskId) return;
      if (!groupedNotifications[taskId]) {
        groupedNotifications[taskId] = {};
      }
      if (!groupedNotifications[taskId][type]) {
        groupedNotifications[taskId][type] = [];
      }
      groupedNotifications[taskId][type].push(n);
    });
  const preparedNotifications: NotificationItemData[] = [];
  Object.keys(groupedNotifications).forEach((ticketKey) => {
    const notificationTypesWithSameId = groupedNotifications[ticketKey];
    Object.keys(notificationTypesWithSameId).forEach((type) => {
      preparedNotifications.push({
        ticketKey,
        type,
        notificationObjects: notificationTypesWithSameId[type],
      });
    });
  });
  return preparedNotifications;
};
