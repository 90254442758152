import React, { useEffect, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box } from "@mui/material";

import { NotificationProvider } from "../../../provider/notificationProvider";
import { useAppSelector } from "../../../store/hooks";
import { selectLoggedUser } from "../../../store/authSlice";
import { selectIsAlert } from "../../../store/notificationSlice";
import { NotificationModal } from "../NotificationModal";
import { sxProps } from "./notification-bell.style";
import { NOTIFICATION_LOADING_INTERVAL } from "../../../utils/const";

const notificationProvider = new NotificationProvider();
let intervalId: NodeJS.Timeout | undefined;

export const NotificationBell = () => {
  const [openModal, setOpenModal] = useState(false);
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));
  const isAlert = useAppSelector((state) => selectIsAlert(state));

  //if user is logged, this effect will trigger notification loading
  //once every NOTIFICATION_LOADING_INTERVAL milliseconds
  useEffect(() => {
    if (!intervalId && loggedUser) {
      notificationProvider.fetchAllNotifications();
      intervalId = setInterval(() => {
        notificationProvider.fetchAllNotifications();
      }, NOTIFICATION_LOADING_INTERVAL);

      return () => {
        if (intervalId) clearInterval(intervalId);
      };
    }
  }, [loggedUser]);

  return loggedUser ? (
    <Box sx={sxProps.bellWrapper}>
      <NotificationModal open={openModal} setOpen={setOpenModal} />
      {isAlert ? (
        <NotificationsActiveIcon
          sx={sxProps.activeBellIcon}
          onClick={() => setOpenModal(true)}
        />
      ) : (
        <NotificationsNoneIcon sx={sxProps.inactiveBellIcon} />
      )}
    </Box>
  ) : (
    <></>
  );
};
