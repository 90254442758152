import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardFilter: {
    background: (theme) => theme.palette.background.default,
  },

  dashboardFilterSelect: {
    flex: 2,
    padding: (theme) => theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },

  dashboardFilterCheckbox: {
    justifyContent: "center",
    flex: 1,
    color: (theme) => theme.palette.primary.contrastText,
  },

  alignSelectList: {
    left: (theme) => `-${theme.spacing(1)}`,
    top: (theme) => `-${theme.spacing(2)}`,
  },
};
