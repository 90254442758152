import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  detailInformationAppointmentGridContainer: {
    padding: (theme) => theme.spacing(0.75, 0),
    alignItems: "center",
  },

  detailInformationAppointmentLabel: {
    color: (theme) => theme.palette.grey[500],
  },

  detailInformationAppointmentText: {
    marginTop: "-5px",
    marginRight: (theme) => theme.spacing(2),
  },

  detailInformationAppointmentIcon: {
    color: (theme) => theme.palette.common.black,
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(1.25, 1.125, 0, 0),
    "& path": {
      strokeWidth: "1.5px",
      stroke: "currentcolor",
    },
  },
  serviceRequestAppointmentButtonIcon: {
    height: (theme) => theme.spacing(3),
    paddingRight: (theme) => theme.spacing(1.25),
  },

  serviceRequestAppointmentButton: {
    width: "auto",
    height: "auto",
    fontSize: "0.813rem",
    wordBreak: "break-all",
  },
};
