import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Ticket } from "../../../../../shared/types/Ticket";
import {
  TransitionFormFormat,
  Transition,
} from "../../../../../shared/types/Workflow";
import { sxProps } from "./service-request-details-actions.style";
import {
  SelectStatusPicker,
  SelectStatusTransition,
} from "../../../../../shared/components";
import {
  useChangeTicketStatus,
  useRefreshTicket,
} from "../../../../ticketContextProvider";
import { useAppSelector } from "../../../../../shared/store/hooks";
import { selectLoading } from "../../../../../shared/store/loadingSlice";
import { StatusTransitionModal } from "./components/StatutsTransitionModal";
import ChatAlt2Icon from "@heroicons/react/solid/ChatAlt2Icon";
import { useLocation, useNavigate } from "react-router";
import { paths } from "../../../../../shared/routes/paths";

export type ServiceRequestDetailsActionsProps = {
  ticket: Ticket;
};

export const ServiceRequestDetailsActions = ({
  ticket,
}: ServiceRequestDetailsActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [statusTransitionModalOpen, setStatusTransitionModalOpen] =
    useState(false);
  const [statusTransitionFormFormat, setStatusTransitionFormFormat] =
    useState<TransitionFormFormat>();
  const [transition, setTransition] = useState<Transition>();
  const isLoading = useAppSelector((state) => selectLoading(state));
  const changeTicketStatus = useChangeTicketStatus();
  const refreshTicket = useRefreshTicket();

  useEffect(() => {
    changeStatus(transition);
    // }
  }, [transition]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeStatus = async (transition?: Transition) => {
    if (changeTicketStatus && refreshTicket && transition) {
      const resp = await changeTicketStatus(ticket, transition as Transition);
      if (resp) {
        setStatusTransitionFormFormat(resp);
        setStatusTransitionModalOpen(true);
      } else {
        await refreshTicket();
      }
    }
  };

  const statusChangeHandler = async (
    selectStatusTransition: SelectStatusTransition
  ) => {
    setTransition({ ...selectStatusTransition });
  };

  const path = `${paths.serviceRequest}/${ticket.key}/messages`;
  const active = path === location.pathname;

  const onClick = () => {
    return active ? undefined : navigate(path);
  };

  return (
    <Box sx={sxProps.serviceRequestDetailsActions}>
      <Box sx={sxProps.serviceRequestDetailsButtonsBox}>
        <Button
          color="primary"
          variant="contained"
          sx={sxProps.serviceRequestDetailsButton}
          onClick={onClick}
        >
          <Box
            component={ChatAlt2Icon}
            sx={sxProps.serviceRequestDetailsButtonsIcon}
          />
          {t("+serviceRequest.details.actions.message")}
        </Button>
      </Box>
      <Box sx={sxProps.serviceRequestDetailsSelect}>
        <Typography variant="overline" sx={sxProps.selectStatusLabel}>
          {t("+serviceRequest.details.actions.statusSelect.label")}
        </Typography>
        <SelectStatusPicker
          disabled={isLoading}
          setSelectedStatus={statusChangeHandler}
          selectedStatus={ticket.status}
        />
        <StatusTransitionModal
          formFormat={statusTransitionFormFormat}
          open={statusTransitionModalOpen}
          setOpen={setStatusTransitionModalOpen}
          transition={transition}
        />
      </Box>
    </Box>
  );
};
