import React from "react";
import { useSnackbar } from "notistack";
import { removeSnackbar, selectSnackbars } from "../store/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";

//list of id's of displayed messages in snackbar
//when new is added, id will be added to this list,
//and on exit event, id will be removed.
//when app state changes, it will be compared to this list
//to see if there is something changed, and in the end this list
//will be updated to match new app state
let displayedIds: string[] = [];

export const useSnackbarWatcher = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let snackbars = useAppSelector((state) => selectSnackbars(state));

  const storeDisplayed = (snackbarId: string) => {
    displayedIds = [...displayedIds, snackbarId];
  };

  const removeDisplayed = (snackbarId: string) => {
    displayedIds = [...displayedIds.filter((id) => id !== snackbarId)];
  };

  React.useEffect(() => {
    snackbars.forEach(({ message, id, ...options }) => {
      // do nothing if snackbar with this id is already displayed
      if (displayedIds.includes(id)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key: id,
        ...options,
        onExited: (event, myKey) => {
          // remove this snackbar id from displayed id list
          removeDisplayed(myKey.toString());
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey.toString()));
        },
      });
      // keep track of snackbars that we've displayed
      storeDisplayed(id);
    });
  }, [snackbars, dispatch, enqueueSnackbar]);
};
