import React, { ReactNode } from "react";
import { SnackbarProvider } from "notistack";

import { useAppSelector } from "../../../store/hooks";
import { selectSnackbars } from "../../../store/snackbarSlice";
import { CustomSnackbar } from "../CustomSnackbar/custom-snackbar.component";

export type SnackbarProviderWrapperProps = {
  children: ReactNode;
};

export const SnackbarProviderWrapper = (
  props: SnackbarProviderWrapperProps
) => {
  const snackbars = useAppSelector((state) => selectSnackbars(state));

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      content={(key) => {
        const snackbar = snackbars.find((s) => {
          return s.id === key;
        });
        if (!snackbar) {
          return null;
        }
        return <CustomSnackbar {...snackbar} key={key} />;
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};
