import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  spinnerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: (theme) => theme.palette.primary.main,
  },
};
