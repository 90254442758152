import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  serviceRequestMessagesBox: {
    height: (theme) => theme.custom.dashboardListHeight,
  },

  serviceRequestMessagesContainer: {
    height: "100%",
    flexWrap: "nowrap",
    overflowY: "scroll",
  },

  messageList: {
    margin: (theme) => theme.spacing(3, 0, 1.5, 0),
    width: "100%",
    overflow: "scroll",
  },
};
