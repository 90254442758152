import React, { useRef, ChangeEvent } from "react";
import { Box, Typography } from "@mui/material";
import { CameraAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { sxProps } from "./attachment-upload.style";
import { attachmentSxProps } from "../attachment.style";
import { fileToBase64 } from "./util";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addErrorSnackbar } from "../../../store/snackbarSlice";
import { selectLoading } from "../../../store/loadingSlice";
import { AttachmentForUpload } from "../../../types/Ticket";

export type AttachmentUploadProps = {
  onFileSelected: (attachments: AttachmentForUpload[]) => Promise<void>;
};

export const AttachmentUpload = ({ onFileSelected }: AttachmentUploadProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => selectLoading(state));
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClickHandler = () => {
    if (!loading) {
      fileInputRef.current?.click();
    }
  };

  const onSelectHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const attachments: AttachmentForUpload[] = [];
      try {
        for (let i = 0; i < files.length; i++) {
          const value = (await fileToBase64(files[i])) as string;
          const name = encodeURIComponent(files[i].name);
          attachments.push({ value, name });
        }

        await onFileSelected(attachments);
      } catch (error) {
        dispatch(addErrorSnackbar(error as string));
      }
    }
  };

  return (
    <Box
      onClick={onClickHandler}
      sx={{
        ...sxProps.attachmentUploadBox,
        ...attachmentSxProps.attachmentBorder,
      }}
    >
      <input
        type="file"
        hidden
        accept="image/*"
        ref={fileInputRef}
        onChange={onSelectHandler}
        multiple={true}
      />
      <CameraAlt sx={sxProps.attachmentUploadIcon} />
      <Typography variant="overline" sx={sxProps.attachmentUploadLabel}>
        {t("shared.attachmentUpload.label")}
      </Typography>
    </Box>
  );
};
