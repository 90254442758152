import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import { UserSuggestion } from "../types/Ticket";

export interface MentionState {
  query: string;
  mentions: UserSuggestion[];
}

const initialState: MentionState = { query: "", mentions: [] };

const mentionSlice = createSlice({
  name: "mention",
  initialState,
  reducers: {
    storeQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    storeMentions: (state, action: PayloadAction<UserSuggestion[]>) => {
      state.mentions = action.payload;
    },
  },
});

export const { storeQuery, storeMentions } = mentionSlice.actions;
export const selectQuery = (state: RootState) => state.mention.query;
export const selectMentions = (state: RootState) => state.mention.mentions;

export default mentionSlice.reducer;
