import { GoogleMapsClient } from "../../provider/clients/googleMaps.client";
import { detectPlatform, isApplePlatform } from "../../utils/platfrom.helper";

const googleMapsClient = new GoogleMapsClient();

export const mapsSelector = (address: string) => {
  const platform = detectPlatform();
  if (isApplePlatform(platform))
    /* else Apple maps */
    window.open(`maps://maps.google.com/maps?daddr=${address}&amp;ll=`);
  /* else use Google mpas */ else
    window.open(`https://maps.google.com/maps?daddr=${address}&amp;ll=`);
};

export const getCoordinatesForLocation = async (address: string) => {
  const coordinates = await googleMapsClient.getCoordinates(address);
  return coordinates;
};
