import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardSearchBoxWrapper: {
    padding: (theme) => theme.spacing(1, 1, 0, 1),
  },
  dashboardSearchField: {
    background: (theme) => theme.palette.common.white,
    borderRadius: (theme) => theme.spacing(2),
  },
};
