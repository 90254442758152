import React, { useCallback, useEffect } from "react";
import { List } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

import { useAppSelector } from "../../../shared/store/hooks";
import { sxProps } from "./dashboard-ticket-list.style";
import { DashboardTicketListItem } from "./components/DashboardTicketListItem";
import { TicketProvider } from "../../../shared/provider/ticketProvider";
import {
  selectFilter,
  selectFilteredTickets,
} from "../../../shared/store/ticketSlice";
import { useTranslation } from "react-i18next";
import { DashboardNoTicketScreen } from "./components/DashboardNoTicketScreen";
import { TicketStatus } from "../../../shared/types/Ticket";
import { selectLoading } from "../../../shared/store/loadingSlice";

//listId is used to connect List component and infinite scroll
const listId = "dashboardTicketListId";

export type DashboardTicketListProps = {
  ticketProvider: TicketProvider;
};

export const DashboardTicketList = ({
  ticketProvider,
}: DashboardTicketListProps) => {
  const { t } = useTranslation();
  const filter = useAppSelector((state) => selectFilter(state));
  const filteredTickets = useAppSelector((state) =>
    selectFilteredTickets(state)
  );
  const isLoading = useAppSelector((state) => selectLoading(state));
  const loadTickets = useCallback(
    async (offset?: number) => {
      await ticketProvider.fetchTickets(filter, offset);
    },
    [filter, ticketProvider]
  );

  //when component is mounted, load tickets
  useEffect(() => {
    loadTickets();
  }, [loadTickets]);

  //when filter is changed, provider has to check if more ticket should be loaded
  useEffect(() => {
    ticketProvider.onFilterChange(filter, filteredTickets);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    !isLoading &&
    filter.status !== TicketStatus.CLOSED &&
    filteredTickets.length < 1
  ) {
    return <DashboardNoTicketScreen />;
  }

  //infinite scroll is used to load more data when user scrolls more then 80% of the page
  return (
    <List sx={sxProps.dashboardTicketList} id={listId}>
      <InfiniteScroll
        dataLength={filteredTickets.length}
        next={() => {
          loadTickets(filteredTickets.length);
        }}
        loader={null}
        hasMore={true}
        scrollableTarget={listId}
      >
        {filteredTickets.map((ticket) => {
          return (
            <DashboardTicketListItem key={ticket.id} ticket={ticket} t={t} />
          );
        })}
      </InfiniteScroll>
    </List>
  );
};
