export type ServiceRequestNavbarItem = {
  labelKey: string;
  value: ServiceRequestNavbarItemValue;
  path: string;
};

export type Counter = {
  type: CounterTypes;
  value: number;
};

export enum CounterTypes {
  PRIMARY = "Primary",
  SECONDARY = "Secondary",
  DISABLED = "Disabled",
}

export enum ServiceRequestNavbarItemValue {
  DETAILS = "DETAILS",
  ATTACHMENTS = "ATTACHMENTS",
  MESSAGES = "MESSAGES",
}
