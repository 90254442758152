import React, { ComponentProps } from "react";
import { Grid, Box, Typography, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { sxProps } from "./detail-information.style";

export type IconType =
  | React.FC<React.SVGProps<SVGSVGElement>>
  | ((props: ComponentProps<"svg">) => JSX.Element)
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>;

export type DetailInformationProps = {
  label: string;
  information: string;
  icon: IconType;
  isError?: boolean;
};

export const DetailInformation = ({
  label,
  information,
  icon,
  isError = false,
}: DetailInformationProps) => {
  const getSxTextProps = (isError: boolean) => {
    return isError
      ? { ...sxProps.detailInformationText, ...sxProps.detailInformationError }
      : sxProps.detailInformationText;
  };

  const getSxIconProps = (isError: boolean) => {
    return isError
      ? { ...sxProps.detailInformationIcon, ...sxProps.detailInformationError }
      : sxProps.detailInformationIcon;
  };

  if (!information) {
    return <></>;
  }

  return (
    <Grid container key={label} sx={sxProps.detailInformationsGridContainer}>
      <Grid item>
        <Box component={icon} sx={getSxIconProps(isError)} />
      </Grid>
      <Grid item>
        <Typography variant="overline" sx={sxProps.detailInformationLabel}>
          {label}
        </Typography>
        <Typography variant="body1" sx={getSxTextProps(isError)}>
          {information}
        </Typography>
      </Grid>
    </Grid>
  );
};
