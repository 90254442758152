import React, { ChangeEvent, useCallback } from "react";
import { TextField, Box } from "@mui/material";

import { sxProps } from "./search-box.style";
import { useTranslation } from "react-i18next";
import { TicketProvider } from "../../../shared/provider/ticketProvider";
import {
  selectFilter,
  selectTicketQuery,
  setTicketQuery,
} from "../../../shared/store/ticketSlice";
import { useAppSelector } from "../../../shared/store/hooks";
import { useDispatch } from "react-redux";
import { TicketQuery } from "../../../shared/types/Ticket";
import { isQuerySet } from "../../../shared/utils/ticket.helper";

export type SearchBoxProps = {
  ticketProvider: TicketProvider;
};

export const SearchBox = ({ ticketProvider }: SearchBoxProps) => {
  const { t } = useTranslation();
  const ticketQuery = useAppSelector((state) => selectTicketQuery(state));
  const filter = useAppSelector((state) => selectFilter(state));

  const dispatch = useDispatch();
  const onSearchBoxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const originalTicketQuery = { ...ticketQuery };
      const query = e.target.value;
      const updatedQuery: TicketQuery = { ...ticketQuery, query };
      dispatch(setTicketQuery(updatedQuery));
      if (isQuerySet(updatedQuery)) {
        ticketProvider.queryTickets(updatedQuery);
      } else {
        if (isQuerySet(originalTicketQuery)) {
          //this is the case where updated ticket query is less then 3 characters, and old one is not
          //in this situation we should trigger ticket quierying based on filter
          ticketProvider.fetchTickets(filter);
        }
      }
    },
    [dispatch, ticketProvider, ticketQuery, filter]
  );
  return (
    <Box sx={sxProps.dashboardSearchBoxWrapper}>
      <TextField
        onChange={onSearchBoxChange}
        fullWidth
        value={ticketQuery?.query || ""}
        sx={sxProps.dashboardSearchField}
        label={t("+dashboard.searchBox.label")}
      />
    </Box>
  );
};
