import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  mapContainer: {
    position: "relative",
  },

  navigateButton: {
    background: (theme) => theme.palette.primary.contrastText,
    position: "absolute",
    right: "26px",
    bottom: "24px",
    zIndex: 2,
    padding: (theme) => theme.spacing(1, 2),
    "&:hover": {
      background: (theme) => theme.palette.primary.contrastText,
    },
  },
};
