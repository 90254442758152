import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentContainer: {
    height: (theme) => theme.custom.dashboardListHeight,
    overflow: "scroll",
  },

  otherAttachmentsBox: {
    marginTop: (theme) => theme.spacing(3.875),
  },

  ownerAttachmentsBox: {
    marginTop: (theme) => theme.spacing(4.375),
    marginBottom: (theme) => theme.spacing(4.375),
  },

  attachmentGridContainer: {
    justifyContent: "space-between",
    alignItems: "center",
  },

  attachmentContainerItem: {
    padding: (theme) => theme.spacing(1.5, 1, 0, 1),
  },

  attachmentContainerLabel: {
    color: (theme) => theme.palette.primary.main,
    paddingLeft: (theme) => theme.spacing(1),
  },
};
