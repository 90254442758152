import { Message, Ticket, TicketQuery, TicketStatus } from "../types/Ticket";
import { MIN_QUERY_SIZE } from "./const";

export const compareTicket = (a: Ticket, b: Ticket) => {
  const { id: idA, status: statusA } = a;
  const { id: idB, status: statusB } = b;
  const ticketStatusCompare = compareTicketStatus(statusA, statusB);
  if (ticketStatusCompare === 0) {
    //if tickets have same status we want to show oldest first,
    //but if the status is closed, then we want to show newest first
    return idA.localeCompare(idB) * (a.status === TicketStatus.CLOSED ? -1 : 1);
  } else {
    return ticketStatusCompare;
  }
};

const statusOrder = {
  [TicketStatus.OPEN]: 1,
  [TicketStatus.IN_PROGRESS]: 2,
  [TicketStatus.ON_HOLD]: 3,
  [TicketStatus.AWAITING_TENANT]: 4,
  [TicketStatus.TASK_IS_SENT_TO_EXTERNAL_PROVIDER]: 5,
  [TicketStatus.CLOSED]: 6,
};

const compareTicketStatus = (a: TicketStatus, b: TicketStatus) => {
  const orderA = statusOrder[a];
  const orderB = statusOrder[b];
  if (orderA > orderB) return 1;
  else if (orderA === orderB) return 0;
  else return -1;
};

export const compareMessage = (a: Message, b: Message) => {
  const { date: dateA } = a;
  const { date: dateB } = b;
  if (dateB > dateA) return -1;
  else return 1;
};

export const isQuerySet = (query?: TicketQuery) => {
  if (query) {
    if (query.address) return true;
    if (query.query && query.query.length >= MIN_QUERY_SIZE) return true;
  }
  return false;
};
