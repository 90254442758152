import React from "react";
import { Box } from "@mui/material";

import { sxProps } from "./microsoft-button.style";
import { ReactComponent as MsButton } from "./assets/microsoft-button.svg";

export type MicrosoftButtonProps = {
  onClick: () => void;
};

export const MicrosoftButton = (props: MicrosoftButtonProps) => {
  return (
    <Box sx={sxProps.wrapperBox}>
      <MsButton onClick={props.onClick} style={{ width: "100%" }} />
    </Box>
  );
};
