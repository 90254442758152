import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { sxProps } from "./dashboard-filter-checkbox.style";
import { useAppDispatch } from "../../../../../shared/store/hooks";
import { setOverdueFilter } from "../../../../../shared/store/ticketSlice";

export type DashboardFilterCheckboxProps = {
  selected: boolean;
};

export const DashboardFilterCheckbox = (
  props: DashboardFilterCheckboxProps
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <FormControlLabel
      sx={sxProps.dashboardFilterCheckboxLabel}
      label={
        <Typography variant="button">
          {t("+dashboard.filter.overdue")}
        </Typography>
      }
      control={
        <Checkbox
          sx={sxProps.dashboardFilterCheckbox}
          checked={props.selected}
          onChange={() => {
            dispatch(setOverdueFilter(!props.selected));
          }}
        />
      }
    />
  );
};
