import { Notifications as NotificationsAxios } from "../../../../generated/clients/notifications/Notifications";
import { Notification } from "../../../../generated/clients/notifications/data-contracts";
import { getEnvValue } from "../../utils/env.helper";

export type GetAllNotificationsResponse = {
  notifications?: Notification[];
  hasNextPage?: boolean;
  nextPage?: number | null;
};

export class NotificationServiceClient {
  private client: NotificationsAxios;

  constructor() {
    const baseURL = getEnvValue("NOTIFICATION_SERVICE_API_PATH");
    this.client = new NotificationsAxios({
      baseURL,
    });
  }

  getAllNotifications = async (bearer: string, page: number = 1) => {
    let response = await this.client.getAll(
      { page, unread: true },
      { headers: { Authorization: `Bearer ${bearer}` } }
    );

    const { docs, nextPage, hasNextPage } = response.data;
    const getAllNotificationsResponse: GetAllNotificationsResponse = {
      notifications: docs,
      nextPage: nextPage,
      hasNextPage,
    };
    return getAllNotificationsResponse;
  };

  markNotificationAsRead = async (bearer: string, id: string) => {
    await this.client.markOneAsRead(id, {
      headers: { Authorization: `Bearer ${bearer}` },
    });
  };

  markAllNotificationsAsRead = async (bearer: string) => {
    await this.client.markAllAsRead({
      headers: { Authorization: `Bearer ${bearer}` },
    });
  };
}
