import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { handleError } from "./sentry.helper";

export const createInstance = (config?: AxiosRequestConfig): AxiosInstance => {
  const instance = axios.create(config);

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (e) => {
      handleError(e);
      throw e;
    }
  );
  return instance;
};
