import React, { useMemo } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Theme,
  Divider,
  Stack,
} from "@mui/material";
import { ReactComponent as ApartmentNumberIcon } from "@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg";
import { ReactComponent as DueDateIcon } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-check-1.svg";
import { ReactComponent as AddressIcon } from "@heimstaden/icons-library/img/streamline-regular/maps-navigation/pins/pin-location-1.svg";
import { ReactComponent as MessageIcon } from "@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg";
import { ReactComponent as AttachmentIcon } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/link-unlink/attachment.svg";

import { SxProps } from "@mui/system";
import dateFormat from "dateformat";

import { Ticket } from "../../../../../shared/types/Ticket";
import { sxProps } from "./dashboard-ticket-list-item.style";
import { useNavigate } from "react-router";
import { paths } from "../../../../../shared/routes/paths";
import { ListItemStatusIndicator } from "../ListItemStatusIndicator";
import { createFailureString } from "./util";
import { TFunction } from "react-i18next";

export type DashboardTicketListItemProps = {
  ticket: Ticket;
  t: TFunction;
};

export const DashboardTicketListItem = ({
  ticket,
  t,
}: DashboardTicketListItemProps) => {
  const navigate = useNavigate();

  const overdueSxProps = useMemo<SxProps<Theme>>(() => {
    return ticket.overDue ? sxProps.dashboardTicketListItemOverdue : {};
  }, [ticket.overDue]);

  const failure = useMemo<String>(() => {
    return createFailureString(ticket, t);
  }, [ticket, t]);

  const onClick = () => {
    navigate(
      `${paths.serviceRequest}/${ticket.key}${paths.serviceRequestDetails}`
    );
  };

  return (
    <Paper sx={sxProps.dashboardTicketListItem} onClick={onClick}>
      <Box sx={sxProps.dashboardTicketListItemContentWrapper}>
        <Grid container direction="column" justifyItems="center">
          <Grid item>
            <Typography
              sx={sxProps.dashboardTicketListItemTopText}
              variant="overline"
            >
              {failure}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={sxProps.dashboardTicketListItemCenterText}
              variant="h3"
            >
              {ticket.summary}
            </Typography>
          </Grid>
          <Divider variant="fullWidth" />
          <Stack sx={sxProps.dashboardTicketListItemBottomText}>
            <Grid item sx={sxProps.dashboardTicketListItemAddressBox}>
              <Typography variant="body2">
                <Box
                  component={AddressIcon}
                  sx={sxProps.dashboardTicketListItemIcon}
                />
                {ticket.fullAddress}
                {ticket.apartmentNumber ? (
                  <Box
                    component={ApartmentNumberIcon}
                    sx={{
                      ...sxProps.dashboardTicketListItemIcon,
                      ...sxProps.dashboardTicketListDoorIcon,
                    }}
                  />
                ) : (
                  ""
                )}

                {ticket.apartmentNumber}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              width={"auto"}
              item
            >
              <Grid sx={sxProps.dashboardTicketListItemDetail} item>
                <Typography variant="body2" component="span">
                  <Box
                    component={MessageIcon}
                    sx={sxProps.dashboardTicketListItemIcon}
                  />
                  {ticket.commentCount}
                </Typography>
              </Grid>

              <Grid sx={sxProps.dashboardTicketListItemDetail} item>
                <Typography
                  variant="body2"
                  component="span"
                  sx={overdueSxProps}
                >
                  <Box
                    component={DueDateIcon}
                    sx={sxProps.dashboardTicketListItemIcon}
                  />
                  {dateFormat(ticket.dueDate, "d mmm")}
                </Typography>
              </Grid>

              <Grid sx={sxProps.dashboardTicketListItemDetail} item>
                <Typography variant="body2" component="span">
                  <Box
                    component={AttachmentIcon}
                    sx={sxProps.dashboardTicketListItemIcon}
                  />
                  {ticket.attachments.length}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <ListItemStatusIndicator status={ticket.status} />
    </Paper>
  );
};
