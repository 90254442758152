import React, { MouseEvent, ReactNode, useState } from "react";
import {
  Divider,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useTranslation } from "react-i18next";

import { sxProps } from "../../select-status.style";
import { TicketStatus } from "../../../../types/Ticket";
import { statusSelectFilterItems } from "../../util";
import { StatusMenuItem } from "../StatusMenuItem";

export type SelectStatusFilterProps = {
  selectedStatus?: TicketStatus;
  setSelectedStatus: (ticketStatus?: TicketStatus) => void;
  disabled: boolean;
  alignListSx?: SxProps<Theme>;
};

export const SelectStatusFilter = ({
  disabled,
  setSelectedStatus,
  selectedStatus,
  alignListSx,
}: SelectStatusFilterProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (e: SelectChangeEvent<number>) => {
    const index = e.target.value;
    const newStatus = statusSelectFilterItems[index as number].status;
    setSelectedStatus(newStatus);
  };

  const getMenuItemFromStatus = () => {
    let index = statusSelectFilterItems.findIndex(
      (item) => item.status === selectedStatus
    );
    return { index, menuItem: statusSelectFilterItems[index] };
  };

  const renderSelectMenu = () => {
    let selectMenu: ReactNode[] = [];
    statusSelectFilterItems.forEach(({ status, labelKey }, i) => {
      const isSelected = status === selectedStatus;
      //before first item add Active tickets label
      selectMenu = [
        ...selectMenu,
        <MenuItem
          value={i}
          key={`select-filter-menu-item-${i * 2}`}
          sx={isSelected ? sxProps.hiddenItem : sxProps.menuItem}
        >
          <StatusMenuItem
            label={t(labelKey)}
            status={status}
            selected={isSelected}
            showCircles
          />
        </MenuItem>,
        <Divider
          orientation="horizontal"
          variant="middle"
          key={`select-filter-menu-item-${i * 2 + 1}`}
          sx={isSelected ? sxProps.hiddenItem : sxProps.divider}
        />,
      ];
    });
    //before first item add Active tickets label
    selectMenu.splice(
      0,
      0,
      <ListSubheader key="active-subheader" sx={sxProps.subheaderProps}>
        <Typography variant="button">
          {t("shared.selectStatus.activeSubheader")}
        </Typography>
      </ListSubheader>
    );
    //before last item add Nonactive tickets label
    selectMenu.splice(
      selectMenu.length - 2,
      0,
      <ListSubheader key="non-active-subheader" sx={sxProps.subheaderProps}>
        <Typography variant="button">
          {t("shared.selectStatus.nonActiveSubheader")}
        </Typography>
      </ListSubheader>
    );
    return selectMenu;
  };

  //need to handle opening and closing programatically
  //because part of app is not clickable, and disable
  //click when divider is cliced
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    if (
      !disabled &&
      e.target instanceof Element &&
      !e.target.classList.contains("MuiDivider-root")
    ) {
      setOpen(!open);
    }
  };

  return (
    <Select
      onClick={onClick}
      variant="filled"
      MenuProps={{
        sx: {
          ...sxProps.statusSelectMenu,
          ...(alignListSx ? alignListSx : {}),
        },
      }}
      inputProps={{
        sx: sxProps.statusSelectInput,
      }}
      sx={sxProps.statusSelectSimple}
      disableUnderline
      value={getMenuItemFromStatus().index}
      onChange={handleChange}
      open={open}
    >
      {renderSelectMenu()}
    </Select>
  );
};
