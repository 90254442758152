export const postalCodes = [
  {
    propertyName: "Flintholm I",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Flintholm II",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Mølleparkbo",
    postalCode: 6715,
    city: "Esbjerg",
  },
  {
    propertyName: "Vestbo",
    postalCode: 6715,
    city: "Esbjerg",
  },
  {
    propertyName: "Porcelænshaven",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Rødager Alle",
    postalCode: 2610,
    city: "Rødovre",
  },
  {
    propertyName: "Scherfigs Have",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Grønnegade/Rørkjærsgade",
    postalCode: 6700,
    city: "Esbjerg",
  },
  {
    propertyName: "Fynsgade",
    postalCode: 6700,
    city: "Esbjerg",
  },
  {
    propertyName: "Englandsgade ApS",
    postalCode: 6700,
    city: "Esbjerg",
  },
  {
    propertyName: "Spangsbjerg Møllevej",
    postalCode: 6700,
    city: "Esbjerg",
  },
  {
    propertyName: "Skyttens Kvarter",
    postalCode: 6700,
    city: "Esbjerg",
  },
  {
    propertyName: "Fjordglimt",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Ø-huset",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Bjerglandsbyen",
    postalCode: 2700,
    city: "Brønshøj",
  },
  {
    propertyName: "Helgagård",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Nygården",
    postalCode: 2800,
    city: "Kongens Lyngby",
  },
  {
    propertyName: "Tagensvej",
    postalCode: 2200,
    city: "København N",
  },
  {
    propertyName: "Richsgården",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Bianco Lunos Allé",
    postalCode: 1868,
    city: "Frederiksberg C",
  },
  {
    propertyName: "Kaalundsgade",
    postalCode: 1664,
    city: "København V",
  },
  {
    propertyName: "Oehlenschlægersgade",
    postalCode: 1663,
    city: "København V",
  },
  {
    propertyName: "Carl Bernhards Vej",
    postalCode: 1814,
    city: "Frederiksberg C",
  },
  {
    propertyName: "Sankt Thomas Allé",
    postalCode: 1824,
    city: "Frederiksberg C",
  },
  {
    propertyName: "Bagsværd Have I",
    postalCode: 2880,
    city: "Bagsværd",
  },
  {
    propertyName: "Bagsværd Have II",
    postalCode: 2880,
    city: "Bagsværd",
  },
  {
    propertyName: "Tandrup Kollegiet",
    postalCode: 2720,
    city: "Vanløse",
  },
  {
    propertyName: "Gl. Kongevej",
    postalCode: 1862,
    city: "Frederiksberg C",
  },
  {
    propertyName: "Hospitalsvej",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Kastelsvej",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Frøbelshus",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Flintebakken",
    postalCode: 5560,
    city: "Aarup",
  },
  {
    propertyName: "Bjerggårdsløkken",
    postalCode: 5240,
    city: "Odense NØ",
  },
  {
    propertyName: "Nørrebjerg Runddel III",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Hvenekildeløkken II",
    postalCode: 5240,
    city: "Odense NØ",
  },
  {
    propertyName: "Birkelunddalen",
    postalCode: 5260,
    city: "Odense S",
  },
  {
    propertyName: "Sandkæret II",
    postalCode: 5260,
    city: "Odense S",
  },
  {
    propertyName: "Mørkmoselundsvej",
    postalCode: 5560,
    city: "Aarup",
  },
  {
    propertyName: "Bavnehøj Alle",
    postalCode: 5800,
    city: "Nyborg",
  },
  {
    propertyName: "Porthuset",
    postalCode: 5240,
    city: "Odense NØ",
  },
  {
    propertyName: "Blangstedgård",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Mette Ruds Vej",
    postalCode: 5700,
    city: "Svendborg",
  },
  {
    propertyName: "Walkendorffsvej",
    postalCode: 5700,
    city: "Svendborg",
  },
  {
    propertyName: "Hellet",
    postalCode: 5700,
    city: "Svendborg",
  },
  {
    propertyName: "Harekæret 18-78",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Morten Peders Vej 1-35",
    postalCode: 5771,
    city: "Stenstrup",
  },
  {
    propertyName: "Solparken, Åkirkevej",
    postalCode: 5771,
    city: "Stenstrup",
  },
  {
    propertyName: "Kirkevej 6",
    postalCode: 5772,
    city: "Kværndrup",
  },
  {
    propertyName: "Æblehaven 28-62",
    postalCode: 5560,
    city: "Aarup",
  },
  {
    propertyName: "Hindsløkken II",
    postalCode: 5690,
    city: "Tommerup",
  },
  {
    propertyName: "Kærhaven 4",
    postalCode: 5320,
    city: "Agedrup",
  },
  {
    propertyName: "Thorvaldsvej 1 A-H",
    postalCode: 5471,
    city: "Søndersø",
  },
  {
    propertyName: "Toftekær",
    postalCode: 5471,
    city: "Søndersø",
  },
  {
    propertyName: "Hindsløkken I",
    postalCode: 5690,
    city: "Tommerup",
  },
  {
    propertyName: "Bjergager 5 mf.",
    postalCode: 5854,
    city: "Gislev",
  },
  {
    propertyName: "Møllehaven",
    postalCode: 5853,
    city: "Ørbæk",
  },
  {
    propertyName: "Poppelparken 18-44",
    postalCode: 5550,
    city: "Langeskov",
  },
  {
    propertyName: "Degneløkken 44-48 og 131",
    postalCode: 5540,
    city: "Ullerslev",
  },
  {
    propertyName: "Knastvænget 4,6,9,10,14 A-B",
    postalCode: 5700,
    city: "Svendborg",
  },
  {
    propertyName: "Lervadvej 4 mf.",
    postalCode: 5540,
    city: "Ullerslev",
  },
  {
    propertyName: "Gryagervænget 2-80",
    postalCode: 5884,
    city: "Gudme",
  },
  {
    propertyName: "Brobæklunden 39-97",
    postalCode: 5260,
    city: "Odense S",
  },
  {
    propertyName: "Sandkæret 40-98",
    postalCode: 5260,
    city: "Odense S",
  },
  {
    propertyName: "Frederik Maaløe Vænget 19-35, 30-42",
    postalCode: 5610,
    city: "Assens",
  },
  {
    propertyName: "Østerbyvænget 12-34, 23-29",
    postalCode: 5642,
    city: "Millinge",
  },
  {
    propertyName: "Søstrenes Have 1-13 og 17",
    postalCode: 5560,
    city: "Aarup",
  },
  {
    propertyName: "Jernbanegade 6-8",
    postalCode: 5750,
    city: "Ringe",
  },
  {
    propertyName: "Søballevej 6 B,C,D,E,F,G,H,K,L,M,N",
    postalCode: 5270,
    city: "Odense N",
  },
  {
    propertyName: "Aulkærhaven 1-9, 2-18",
    postalCode: 5260,
    city: "Odense S",
  },
  {
    propertyName: "Brobæklunden 101-147",
    postalCode: 5260,
    city: "Odense S",
  },
  {
    propertyName: "Hvenekildeløkken 1-107",
    postalCode: 5240,
    city: "Odense NØ",
  },
  {
    propertyName: "Koglevænget 1-47, 2-32",
    postalCode: 5700,
    city: "Svendborg",
  },
  {
    propertyName: "Nørrebjerg Runddel 23 A - 55 K",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Kirsebærhaven 2-54",
    postalCode: 5540,
    city: "Ullerslev",
  },
  {
    propertyName: "Honningurten, Druemunken",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Morelgrenen 41-107",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Hørgræsset, Fløjlsgræsset, Frøstjernen",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Dampskibsmolen 2-16",
    postalCode: 5800,
    city: "Nyborg",
  },
  {
    propertyName: "Torvegården 1-10",
    postalCode: 5900,
    city: "Rudkøbing",
  },
  {
    propertyName: "Horsekæret 1-23",
    postalCode: 5953,
    city: "Tranekær",
  },
  {
    propertyName: "Kongelyset, Kongepennen, Løvefoden m.fl.",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Hjulkronevænget 1-15, 2-24",
    postalCode: 5270,
    city: "Odense N",
  },
  {
    propertyName: "Teglhøjen 1-73 (ulige numre)",
    postalCode: 5670,
    city: "Tommerup",
  },
  {
    propertyName: "Fabers Allé",
    postalCode: 5300,
    city: "Kerteminde",
  },
  {
    propertyName: "Vanddråben",
    postalCode: 5240,
    city: "Odense NØ",
  },
  {
    propertyName: "Dalsgårdsvej",
    postalCode: 5350,
    city: "Rynkeby",
  },
  {
    propertyName: "Toldbodgade",
    postalCode: 5800,
    city: "Nyborg",
  },
  {
    propertyName: "Ridderhatten",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Rokkevænget 1-11, 2-24",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Platanhaven",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Cortex House",
    postalCode: 5230,
    city: "Odense M",
  },
  {
    propertyName: "Nørrebjerg Runddel 184-198",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Nytoften 1-37B",
    postalCode: 5220,
    city: "Odense SØ",
  },
  {
    propertyName: "Vesterhavnen 10-12",
    postalCode: 5800,
    city: "Nyborg",
  },
  {
    propertyName: "Vesterhavnen 5-11",
    postalCode: 5800,
    city: "Nyborg",
  },
  {
    propertyName: "Violinen 2-48, Strib",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Strandgårdsvænget",
    postalCode: 5600,
    city: "Faaborg",
  },
  {
    propertyName: "Tobisvænget 1-59, 2-68",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Flyglet 1-42",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Violinen og Klaveret",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "Mejerivænget 1-36 & Tennisvej 1C & 1D",
    postalCode: 5580,
    city: "Nørre Aaby",
  },
  {
    propertyName: "Heimstaden Engvangen ApS",
    postalCode: 5750,
    city: "Ringe",
  },
  {
    propertyName: "Vestergade (Tjærepletten)",
    postalCode: 5500,
    city: "Middelfart",
  },
  {
    propertyName: "K/S Hjortholm Bolig",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "Teglværkshavnen",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "K/S Lindholm Bolig",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "Stævnen Holding ApS",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amager Strandvej 156-158",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Øresundstårnet",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Åsvinget 14 mlf.",
    postalCode: 2665,
    city: "Vallensbæk Strand",
  },
  {
    propertyName: "Strandhaven 5 m.fl.",
    postalCode: 2665,
    city: "Vallensbæk Strand",
  },
  {
    propertyName: "Mariendalsvej 50",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Nitivej 12 - 20",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Nimbusparken 5-9",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Nørre Farimagsgade 66-70",
    postalCode: 1364,
    city: "København K",
  },
  {
    propertyName: "Dalgas Boulevard 37 m.fl.",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Amagerbrogade 117-121 m.fl.",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Kanalkanten",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "Nordkajen",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "Bryggehusene II",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Teglholms Allé - Westside",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "Schades Have",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Vestre Teglgade 6-10 - Holmediget",
    postalCode: 2450,
    city: "København SV",
  },
  {
    propertyName: "Arenahusene I",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Arenahusene II",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Arenahusene III",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Sadolin Have",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Horisonten I",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Horisonten II",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Revyhaven",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Mariendalsvej II",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Åkandehusene",
    postalCode: 2860,
    city: "Søborg",
  },
  {
    propertyName: "Østrovej",
    postalCode: 2600,
    city: "Glostrup",
  },
  {
    propertyName: "Pilehøj Bolig",
    postalCode: 3460,
    city: "Birkerød",
  },
  {
    propertyName: "Pilehøj 1A&1B",
    postalCode: 3460,
    city: "Birkerød",
  },
  {
    propertyName: "Holmegård Bolig I",
    postalCode: 4684,
    city: "Holmegaard",
  },
  {
    propertyName: "Holmegård Bolig II",
    postalCode: 4684,
    city: "Holmegaard",
  },
  {
    propertyName: "Havneholmen",
    postalCode: 1561,
    city: "København V",
  },
  {
    propertyName: "Æblelunden",
    postalCode: 2860,
    city: "Søborg",
  },
  {
    propertyName: "Kirsebærparken",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Zinkhuset",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Pontoppidanparken",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Poseidon",
    postalCode: 1561,
    city: "København V",
  },
  {
    propertyName: "Bøgehaven",
    postalCode: 3500,
    city: "Værløse",
  },
  {
    propertyName: "Vester Voldgade",
    postalCode: 1552,
    city: "København V",
  },
  {
    propertyName: "Kroghsgade",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Upsalagade",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Hostrups Have",
    postalCode: 1954,
    city: "Frederiksberg C",
  },
  {
    propertyName: "Vanløse Allé",
    postalCode: 2720,
    city: "Vanløse",
  },
  {
    propertyName: "Frederikssundsvej",
    postalCode: 2700,
    city: "Brønshøj",
  },
  {
    propertyName: "Ryesgade",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Griffenfeldtsgade",
    postalCode: 2200,
    city: "København N",
  },
  {
    propertyName: "Nørrebrogade",
    postalCode: 2200,
    city: "København N",
  },
  {
    propertyName: "Ringertoften",
    postalCode: 2400,
    city: "København NV",
  },
  {
    propertyName: "Strandvejen",
    postalCode: 2900,
    city: "Hellerup",
  },
  {
    propertyName: "Brombærparken I",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Brombærparken II",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Søborg Bakke",
    postalCode: 2860,
    city: "Søborg",
  },
  {
    propertyName: "Linde Allé",
    postalCode: 2750,
    city: "Ballerup",
  },
  {
    propertyName: "Dyreholm",
    postalCode: 2765,
    city: "Smørum",
  },
  {
    propertyName: "Kongevejen",
    postalCode: 3490,
    city: "Kvistgård",
  },
  {
    propertyName: "Spurvevænget",
    postalCode: 4623,
    city: "Lille Skensved",
  },
  {
    propertyName: "Østre Fælled",
    postalCode: 4100,
    city: "Ringsted",
  },
  {
    propertyName: "Smedelunden",
    postalCode: 4291,
    city: "Ruds Vedby",
  },
  {
    propertyName: "Diget og Højen",
    postalCode: 4100,
    city: "Ringsted",
  },
  {
    propertyName: "Pilevangen",
    postalCode: 8355,
    city: "Solbjerg",
  },
  {
    propertyName: "Holbergsparken",
    postalCode: 6000,
    city: "Kolding",
  },
  {
    propertyName: "Mandelhaven I",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Hørparken I",
    postalCode: 6000,
    city: "Kolding",
  },
  {
    propertyName: "Hørparken II",
    postalCode: 6000,
    city: "Kolding",
  },
  {
    propertyName: "Syrenhuset",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Valnøddehuset",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Bellishuset",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Vosnæsparken",
    postalCode: 8541,
    city: "Skødstrup",
  },
  {
    propertyName: "Mosegårdsparken",
    postalCode: 8380,
    city: "Trige",
  },
  {
    propertyName: "Smedebakken",
    postalCode: 4291,
    city: "Ruds Vedby",
  },
  {
    propertyName: "Gammelsøparken",
    postalCode: 4760,
    city: "Vordingborg",
  },
  {
    propertyName: "Højvangsparken",
    postalCode: 4300,
    city: "Holbæk",
  },
  {
    propertyName: "Højen",
    postalCode: 4100,
    city: "Ringsted",
  },
  {
    propertyName: "Kirsebærvænget",
    postalCode: 2800,
    city: "Kongens Lyngby",
  },
  {
    propertyName: "Søterrasserne",
    postalCode: 2860,
    city: "Søborg",
  },
  {
    propertyName: "Havnehusene",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Valsehuset",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Kløverbladsparken",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Astilbehus",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Akelejehus",
    postalCode: 2500,
    city: "Valby",
  },
  {
    propertyName: "Islev Have",
    postalCode: 2610,
    city: "Rødovre",
  },
  {
    propertyName: "Baunebo",
    postalCode: 2800,
    city: "Kongens Lyngby",
  },
  {
    propertyName: "Amerika Plads",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Isbjerget",
    postalCode: 8000,
    city: "Århus C",
  },
  {
    propertyName: "Kanalfronten",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Søparken",
    postalCode: 2760,
    city: "Måløv",
  },
  {
    propertyName: "Lyngbyvej",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Kollegiet Noden",
    postalCode: 4000,
    city: "Roskilde",
  },
  {
    propertyName: "Frederiksborgvej 73",
    postalCode: 2400,
    city: "København NV",
  },
  {
    propertyName: "Thorshammer",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Kildebjerg Ry",
    postalCode: 8680,
    city: "Ry",
  },
  {
    propertyName: "Stuhrs Brygge",
    postalCode: 9000,
    city: "Ålborg",
  },
  {
    propertyName: "Nordlandsvej",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Kappelvænget",
    postalCode: 8210,
    city: "Århus V",
  },
  {
    propertyName: "Ringparken",
    postalCode: 6100,
    city: "Haderslev",
  },
  {
    propertyName: "Sct. Olaigade",
    postalCode: 4400,
    city: "Kalundborg",
  },
  {
    propertyName: "Hørparken III",
    postalCode: 6000,
    city: "Kolding",
  },
  {
    propertyName: "Store Grundet Allé II",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Sydbanegade",
    postalCode: 6000,
    city: "Kolding",
  },
  {
    propertyName: "Slagteristrædet",
    postalCode: 4000,
    city: "Roskilde",
  },
  {
    propertyName: "Kamiliehuset",
    postalCode: 2610,
    city: "Rødovre",
  },
  {
    propertyName: "Lynghaven",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Havneparken",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Enghaven",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Rødlundvej",
    postalCode: 8462,
    city: "Harlev J",
  },
  {
    propertyName: "Svalegangen",
    postalCode: 8462,
    city: "Harlev J",
  },
  {
    propertyName: "Vestergaardsvænget",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Kofeltvej",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Lærkevej",
    postalCode: 6600,
    city: "Vejen",
  },
  {
    propertyName: "Hermans Vænge",
    postalCode: 4440,
    city: "Mørkøv",
  },
  {
    propertyName: "Gl. Viborgvej",
    postalCode: 8830,
    city: "Tjele",
  },
  {
    propertyName: "Skovbrynet 12-44",
    postalCode: 4450,
    city: "Jyderup",
  },
  {
    propertyName: "Dyres Kvarter",
    postalCode: 4250,
    city: "Fuglebjerg",
  },
  {
    propertyName: "Irisvej 1-43",
    postalCode: 4420,
    city: "Regstrup",
  },
  {
    propertyName: "Store Grundet Allé I",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Hørmarken",
    postalCode: 7400,
    city: "Herning",
  },
  {
    propertyName: "Guldblommevej",
    postalCode: 8800,
    city: "Viborg",
  },
  {
    propertyName: "Videbechs Alle",
    postalCode: 8800,
    city: "Viborg",
  },
  {
    propertyName: "Padborgvej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Bøgeskovparken",
    postalCode: 8260,
    city: "Viby J",
  },
  {
    propertyName: "Landskronavej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Gl. Kirkevej",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Kildegårdsvej",
    postalCode: 4736,
    city: "Karrebæksminde",
  },
  {
    propertyName: "Røllikevej",
    postalCode: 8653,
    city: "Them",
  },
  {
    propertyName: "Labofaparken",
    postalCode: 4230,
    city: "Skældkør",
  },
  {
    propertyName: "Tyrings Ager",
    postalCode: 6580,
    city: "Vamdrup",
  },
  {
    propertyName: "Mosevænget & Pedersborg Vænge",
    postalCode: 4180,
    city: "Sorø",
  },
  {
    propertyName: "Æblegården",
    postalCode: 4200,
    city: "Slagelse",
  },
  {
    propertyName: "Kragelundvej",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Smedens Hestesko",
    postalCode: 8881,
    city: "Thorsø",
  },
  {
    propertyName: "Balle Husevej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Dybdalhegnet",
    postalCode: 8960,
    city: "Randers SØ",
  },
  {
    propertyName: "Mælkevejen",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Harevænget",
    postalCode: 8450,
    city: "Hammel",
  },
  {
    propertyName: "Fasanvænget",
    postalCode: 7470,
    city: "Karup J",
  },
  {
    propertyName: "Overgårdsvænget",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Christiansfeldsvej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Rosmarinvej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Lysbrovænget",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Podersvej",
    postalCode: 6622,
    city: "Bække",
  },
  {
    propertyName: "Funder Møllevej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Mustangvej",
    postalCode: 8960,
    city: "Randers SØ",
  },
  {
    propertyName: "Skægkærtoften",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Søndermarksvej",
    postalCode: 8800,
    city: "Viborg",
  },
  {
    propertyName: "Svanes Have",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Amaliegården",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Grønnehaven",
    postalCode: 6600,
    city: "Vejen",
  },
  {
    propertyName: "Askelunden",
    postalCode: 6580,
    city: "Vamdrup",
  },
  {
    propertyName: "Møllevangen",
    postalCode: 8310,
    city: "Tranbjerg J",
  },
  {
    propertyName: "Slet Parkvej",
    postalCode: 8310,
    city: "Tranbjerg J",
  },
  {
    propertyName: "Børkop Huse I",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Gl. Jennumvej I",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Gl. Jennumvej II",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Tingager",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Vorup Kirkevej",
    postalCode: 8940,
    city: "Randers SV",
  },
  {
    propertyName: "Møllevangsvej",
    postalCode: 8940,
    city: "Randers SV",
  },
  {
    propertyName: "Sct. Peders Gade",
    postalCode: 8900,
    city: "Randers C",
  },
  {
    propertyName: "Søndervangen",
    postalCode: 8981,
    city: "Spentrup",
  },
  {
    propertyName: "Rødkildevej",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Bakkestjernedalen",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Kristrupvej",
    postalCode: 8960,
    city: "Randers SØ",
  },
  {
    propertyName: "Slyngborggade",
    postalCode: 8900,
    city: "Randers C",
  },
  {
    propertyName: "Nissenparken",
    postalCode: 8870,
    city: "Langå",
  },
  {
    propertyName: "Halmstadvej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Helsingborgvej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Hejlskovparken",
    postalCode: 6040,
    city: "Egtved",
  },
  {
    propertyName: "Langfredparken",
    postalCode: 8471,
    city: "Sabro",
  },
  {
    propertyName: "Fyrretoften",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Langstrømpevej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Herningvej",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Gubsøvænget",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    propertyName: "Bjørnsager",
    postalCode: 8940,
    city: "Randers",
  },
  {
    propertyName: "Haflingervej",
    postalCode: 8960,
    city: "Randers SØ",
  },
  {
    propertyName: "Egedal",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Mandelhaven II",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Sporbyen",
    postalCode: 8930,
    city: "Randers NØ",
  },
  {
    propertyName: "Kordegarden",
    postalCode: 8240,
    city: "Risskov",
  },
  {
    propertyName: "Bredballe Byvej",
    postalCode: 7120,
    city: "Vejle Øst",
  },
  {
    propertyName: "Skelvangen",
    postalCode: 7120,
    city: "Vejle Øst",
  },
  {
    propertyName: "Skjoldborgsvej",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Storegårdsvej",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Vindinggård Center",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Lykkeshøj",
    postalCode: 9293,
    city: "Kongerslev",
  },
  {
    propertyName: "Hasselvang",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Agersbølparken",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Kastanjebakken I",
    postalCode: 8721,
    city: "Daugård",
  },
  {
    propertyName: "Kastanjebakken II",
    postalCode: 8721,
    city: "Daugård",
  },
  {
    propertyName: "Børkop Huse III",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Overmarken",
    postalCode: 7000,
    city: "Fredericia",
  },
  {
    propertyName: "Hvidts Gaard",
    postalCode: 9000,
    city: "Ålborg",
  },
  {
    propertyName: "Helena Park",
    postalCode: 9210,
    city: "Ålborg SØ",
  },
  {
    propertyName: "Da Vinci Parken",
    postalCode: 9220,
    city: "Ålborg Øst",
  },
  {
    propertyName: "Tirsbæk Bakker",
    postalCode: 7120,
    city: "Vejle Øst",
  },
  {
    propertyName: "Egebjerg Bakke",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Haflingervej",
    postalCode: 8960,
    city: "Randers SØ",
  },
  {
    propertyName: "Børkop Huse II",
    postalCode: 7080,
    city: "Børkop",
  },
  {
    propertyName: "Marken",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Brorsonsvej",
    postalCode: 8870,
    city: "Langå",
  },
  {
    propertyName: "H.C. Mondrads Vej",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Flækken",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Stikkelstien",
    postalCode: 8700,
    city: "Horsens",
  },
  {
    propertyName: "Bådhusene",
    postalCode: 7000,
    city: "Fredericia",
  },
  {
    propertyName: "Sofielundsparken",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Torvegade",
    postalCode: 6800,
    city: "Varde",
  },
  {
    propertyName: "Brænderigården",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Rytterskolen",
    postalCode: 7000,
    city: "Fredericia",
  },
  {
    propertyName: "Hyrdevej I",
    postalCode: 7000,
    city: "Fredericia",
  },
  {
    propertyName: "Hyrdevej II",
    postalCode: 7000,
    city: "Fredericia",
  },
  {
    propertyName: "Thorshammer II",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Ballesparken",
    postalCode: 7000,
    city: "Fredericia",
  },
  {
    propertyName: "Vagtbanke",
    postalCode: 7323,
    city: "Give",
  },
  {
    propertyName: "Hvesager",
    postalCode: 7300,
    city: "Jelling",
  },
  {
    propertyName: "Herman Bangs Vej",
    postalCode: 7182,
    city: "Bredsten",
  },
  {
    propertyName: "Herslebsgade",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Holing Engpark",
    postalCode: 7400,
    city: "Herning",
  },
  {
    propertyName: "Haverne Jelling",
    postalCode: 7300,
    city: "Jelling",
  },
  {
    propertyName: "Skarnbassen",
    postalCode: 7190,
    city: "Billund",
  },
  {
    propertyName: "Apotekergården",
    postalCode: 7300,
    city: "Jelling",
  },
  {
    propertyName: "Marielund",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Vestergårdsmarken",
    postalCode: 8783,
    city: "Hornsyld",
  },
  {
    propertyName: "Lupinvej",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Kanalhuset",
    postalCode: 1415,
    city: "København K",
  },
  {
    propertyName: "Hermanhus",
    postalCode: 1633,
    city: "København V",
  },
  {
    propertyName: "Zinnsgade",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Domus Vista",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Jægersborggade",
    postalCode: 2200,
    city: "København N",
  },
  {
    propertyName: "Duegården",
    postalCode: 2000,
    city: "Frederiksberg",
  },
  {
    propertyName: "Amagerbrogade 97 & Lyonsgade 2-4",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 100",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 102 & Lærdalsgade 1",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 122 & Englandsvej 1",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 128",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 134-136",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 138-140",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 142-144",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 145 & Augustagade 1",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 146",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Amagerbrogade 148",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Bella hus",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Engdalsvej 67-69A",
    postalCode: 8000,
    city: "Århus C",
  },
  {
    propertyName: "Toldstedhaven",
    postalCode: 7400,
    city: "Herning",
  },
  {
    propertyName: "Skibsrækkerne",
    postalCode: 7100,
    city: "Vejle",
  },
  {
    propertyName: "Skindergade",
    postalCode: 1159,
    city: "København K",
  },
  {
    propertyName: "Badstuestræde",
    postalCode: 1159,
    city: "København K",
  },
  {
    propertyName: "Sverrigsgade",
    postalCode: 2300,
    city: "København S",
  },
  {
    propertyName: "Vennemindevej",
    postalCode: 2100,
    city: "København Ø",
  },
  {
    propertyName: "Frederik VII´s Gade",
    postalCode: 2200,
    city: "København N",
  },
  {
    propertyName: "Frederiksborgvej 29",
    postalCode: 2400,
    city: "København NV",
  },
  {
    propertyName: "Mejrup (Astersvej)",
    postalCode: 7500,
    city: "Holstebro",
  },
  {
    propertyName: "Skovby Have",
    postalCode: 8722,
    city: "Hedensted",
  },
  {
    propertyName: "Hestehaven, Veflinge",
    postalCode: 5474,
    city: "Veflinge",
  },
  {
    propertyName: "Harritslevvej, Bogense",
    postalCode: 5400,
    city: "Bogense",
  },
  {
    propertyName: "Skovvej, Harndrup",
    postalCode: 5463,
    city: "Harndrup",
  },
  {
    propertyName: "Odensevej, Søndersø",
    postalCode: 5471,
    city: "Søndersø",
  },
  {
    propertyName: "Svelvikparken, Bogense",
    postalCode: 5400,
    city: "Bogense",
  },
  {
    propertyName: "Kongsbergparken, Bogense",
    postalCode: 5400,
    city: "Bogense",
  },
  {
    propertyName: "Østergade, Særslev",
    postalCode: 5471,
    city: "Særslev",
  },
  {
    propertyName: "Ved Diget, Bogense",
    postalCode: 5400,
    city: "Bogense",
  },
  {
    propertyName: "Dalgasparken",
    postalCode: 8600,
    city: "Silkeborg",
  },
  {
    postalCode: 300,
    city: "Akranes",
    propertyName: "Asparskógar2",
  },
  {
    postalCode: 300,
    city: "Akranes",
    propertyName: "Einigrund 4",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Ásatún 34",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Ásatún 36",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Ásatún 40",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Ásatún 42",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Kjarnagata 25",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Kjarnagata 27",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Kjarnagata 29",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Kjarnagata 31",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Lindasíða 45",
  },
  {
    postalCode: 600,
    city: "Akureyri",
    propertyName: "Skálateigur 1",
  },
  {
    postalCode: 730,
    city: "Reyðarfjörður",
    propertyName: "Búðarmelur 12a",
  },
  {
    postalCode: 730,
    city: "Reyðarfjörður",
    propertyName: "Melgerði 11",
  },
  {
    postalCode: 730,
    city: "Reyðarfjörður",
    propertyName: "Melgerði 9",
  },
  {
    postalCode: 700,
    city: "Egilsstaðir",
    propertyName: "Blómvangur 2",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Berjavellir 2",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Burknavellir 17B",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Einivellir 1",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Einivellir 3",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Einivellir 5",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Eskivellir 13",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Eskivellir 17",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Eskivellir 5",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Eskivellir 7",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Eskivellir 9B",
  },
  {
    postalCode: 220,
    city: "Hafnarfjörður",
    propertyName: "Holtsgata 20",
  },
  {
    postalCode: 221,
    city: "Hafnarfjörður",
    propertyName: "Klukkuvellir 3",
  },
  {
    postalCode: 203,
    city: "Kópavogsbær",
    propertyName: "Austurkór 63",
  },
  {
    postalCode: 203,
    city: "Kópavogsbær",
    propertyName: "Boðaþing 20",
  },
  {
    postalCode: 203,
    city: "Kópavogsbær",
    propertyName: "Hörðukór 3",
  },
  {
    postalCode: 270,
    city: "Mosfellsbær",
    propertyName: "Gerplustræti 1-5",
  },
  {
    postalCode: 270,
    city: "Mosfellsbær",
    propertyName: "Vefarastræti 1-5",
  },
  {
    postalCode: 270,
    city: "Mosfellsbær",
    propertyName: "Vefarastræti 24-26",
  },
  {
    postalCode: 270,
    city: "Mosfellsbær",
    propertyName: "Vefarastræti 28-30",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Bogabraut 950",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Bogabraut 960",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Bogabraut 961",
  },
  {
    postalCode: 260,
    city: "Reykjanesbær",
    propertyName: "Engjadalur 2",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1223",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1224",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1225",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1226",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1227",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1228",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1229",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1230",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1231",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Fjörubraut 1232",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Grænásbraut 1215",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Grænásbraut 1216",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Grænásbraut 1218",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Grænásbraut 1219",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Grænásbraut 1220",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Grænásbraut 1221",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Keilisbraut 746",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1101",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1102",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1103",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1104",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1105",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1106",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1107",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1108",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1109",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1110",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1111",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1112",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1113",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 1114",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 926",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 927",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 928",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 929",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 930",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Skógarbraut 931",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Suðurbraut 1233",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Valhallarbraut 1222",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Valhallarbraut 1234",
  },
  {
    postalCode: 262,
    city: "Reykjanesbær",
    propertyName: "Valhallarbraut 741",
  },
  {
    postalCode: 111,
    city: "Reykjavíkurborg",
    propertyName: "Álftahólar 6",
  },
  {
    postalCode: 101,
    city: "Reykjavíkurborg",
    propertyName: "Baldursgata 32",
  },
  {
    postalCode: 101,
    city: "Reykjavíkurborg",
    propertyName: "Baldursgata 34",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Bjallavað 1-3",
  },
  {
    postalCode: 111,
    city: "Reykjavíkurborg",
    propertyName: "Eddufell 8",
  },
  {
    postalCode: 107,
    city: "Reykjavíkurborg",
    propertyName: "Fálkagata 32",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Fálkahlíð 6",
  },
  {
    postalCode: 112,
    city: "Reykjavíkurborg",
    propertyName: "Frostafold 30",
  },
  {
    postalCode: 108,
    city: "Reykjavíkurborg",
    propertyName: "Háaleitisbraut 22",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Hlíðarfótur 11",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Hlíðarfótur 13",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Hlíðarfótur 15",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Hlíðarfótur 17",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Hraunbær 107",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Hraunbær 158",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Hraunbær 172",
  },
  {
    postalCode: 101,
    city: "Reykjavíkurborg",
    propertyName: "Hverfisgata 44",
  },
  {
    postalCode: 116,
    city: "Reykjavíkurborg",
    propertyName: "Jörfagrund 23",
  },
  {
    postalCode: 116,
    city: "Reykjavíkurborg",
    propertyName: "Jörfagrund 25",
  },
  {
    postalCode: 116,
    city: "Reykjavíkurborg",
    propertyName: "Jörfagrund 27",
  },
  {
    postalCode: 111,
    city: "Reykjavíkurborg",
    propertyName: "Krummahólar 2",
  },
  {
    postalCode: 111,
    city: "Reykjavíkurborg",
    propertyName: "Krummahólar 6",
  },
  {
    postalCode: 112,
    city: "Reykjavíkurborg",
    propertyName: "Laufengi 25",
  },
  {
    postalCode: 112,
    city: "Reykjavíkurborg",
    propertyName: "Laufengi 29",
  },
  {
    postalCode: 101,
    city: "Reykjavíkurborg",
    propertyName: "Laugavegur 27A",
  },
  {
    postalCode: 101,
    city: "Reykjavíkurborg",
    propertyName: "Laugavegur 27B",
  },
  {
    postalCode: 109,
    city: "Reykjavíkurborg",
    propertyName: "Leirubakki 36",
  },
  {
    postalCode: 101,
    city: "Reykjavíkurborg",
    propertyName: "Lindargata 28",
  },
  {
    postalCode: 104,
    city: "Reykjavíkurborg",
    propertyName: "Ljósheimar 8",
  },
  {
    postalCode: 112,
    city: "Reykjavíkurborg",
    propertyName: "Mosarimi 2",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Smyrilshlíð 10",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Smyrilshlíð 2",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Smyrilshlíð 4",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Smyrilshlíð 6",
  },
  {
    postalCode: 102,
    city: "Reykjavíkurborg",
    propertyName: "Smyrilshlíð 8",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 10",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 12",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 2",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 4a",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 4b",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 6",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 6a",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Tangabryggja 8",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Vallarás 1",
  },
  {
    postalCode: 110,
    city: "Reykjavíkurborg",
    propertyName: "Vallarás 3",
  },
  {
    postalCode: 112,
    city: "Reykjavíkurborg",
    propertyName: "Veghús 31",
  },
  {
    postalCode: 113,
    city: "Reykjavíkurborg",
    propertyName: "Þórðarsveigur 32",
  },
  {
    postalCode: 113,
    city: "Reykjavíkurborg",
    propertyName: "Þórðarsveigur 34-36",
  },
  {
    postalCode: 800,
    city: "Selfoss",
    propertyName: "Ástjörn 9",
  },
  {
    postalCode: 800,
    city: "Selfoss",
    propertyName: "Fossvegur 8",
  },
  {
    postalCode: "0958",
    city: "OSLO",
    propertyName: "Ammerudveien 19 - 25",
  },
  {
    postalCode: 1366,
    city: "LYSAKER",
    propertyName: "Arnstein Arnebergs vei 30",
  },
  {
    postalCode: "0475",
    city: "OSLO",
    propertyName: "Askergata 1",
  },
  {
    postalCode: 1453,
    city: "BJØRNEMYR",
    propertyName: "Bjørnemyrveien 29",
  },
  {
    postalCode: "0650",
    city: "OSLO",
    propertyName: "Borggata 12",
  },
  {
    postalCode: "0598",
    city: "OSLO",
    propertyName: "Brobekkveien 31",
  },
  {
    postalCode: "0598",
    city: "OSLO",
    propertyName: "Brobekkveien 33",
  },
  {
    postalCode: "0557",
    city: "OSLO",
    propertyName: "Christies gate 19",
  },
  {
    postalCode: "0175",
    city: "OSLO",
    propertyName: "Darres gate 2",
  },
  {
    postalCode: "0175",
    city: "OSLO",
    propertyName: "Darres gate 3",
  },
  {
    postalCode: "0355",
    city: "OSLO",
    propertyName: "Dronning Astrids gate 2",
  },
  {
    postalCode: "0567",
    city: "OSLO",
    propertyName: "Dælenenggata 40",
  },
  {
    postalCode: "0360",
    city: "OSLO",
    propertyName: "Fagerborggata 15",
  },
  {
    postalCode: "0177",
    city: "OSLO",
    propertyName: "Fredensborgveien 13",
  },
  {
    postalCode: "0177",
    city: "OSLO",
    propertyName: "Fredensborgveien 22A",
  },
  {
    postalCode: "0484",
    city: "OSLO",
    propertyName: "Gjerdrums vei 12",
  },
  {
    postalCode: "0652",
    city: "OSLO",
    propertyName: "Gruegata 18",
  },
  {
    postalCode: "0485",
    city: "OSLO",
    propertyName: "Gunnar Schjelderups vei 33",
  },
  {
    postalCode: "0485",
    city: "OSLO",
    propertyName: "Gunnar Schjelderups vei (Bygg D) 33",
  },
  {
    postalCode: "0167",
    city: "OSLO",
    propertyName: "Hegdehaugsveien 2",
  },
  {
    postalCode: "0468",
    city: "OSLO",
    propertyName: "Holmestrandgata 4",
  },
  {
    postalCode: "0473",
    city: "OSLO",
    propertyName: "Holsts gate 4",
  },
  {
    postalCode: "0253",
    city: "OSLO",
    propertyName: "Huitfeldts gate 51",
  },
  {
    postalCode: "0178",
    city: "OSLO",
    propertyName: "Iduns gate 4",
  },
  {
    postalCode: "0450",
    city: "OSLO",
    propertyName: "Kirkeveien (Bygg B) 166",
  },
  {
    postalCode: "0450",
    city: "OSLO",
    propertyName: "Kirkeveien (Bygg C) 166",
  },
  {
    postalCode: "0450",
    city: "OSLO",
    propertyName: "Kirkeveien (Bygg D) 166",
  },
  {
    postalCode: "0450",
    city: "OSLO",
    propertyName: "Kirkeveien (Bygg E) 166",
  },
  {
    postalCode: "0553",
    city: "OSLO",
    propertyName: "Københavngata 3",
  },
  {
    postalCode: "0178",
    city: "OSLO",
    propertyName: "Maridalsveien 11",
  },
  {
    postalCode: "0461",
    city: "OSLO",
    propertyName: "Maridalsveien 122",
  },
  {
    postalCode: "0461",
    city: "OSLO",
    propertyName: "Maridalsveien 128",
  },
  {
    postalCode: "0175",
    city: "OSLO",
    propertyName: "Maridalsveien 37",
  },
  {
    postalCode: "0178",
    city: "OSLO",
    propertyName: "Maridalsveien 9",
  },
  {
    postalCode: "0182",
    city: "OSLO",
    propertyName: "Møllerveien 4",
  },
  {
    postalCode: "0551",
    city: "OSLO",
    propertyName: "Nedre gate 8",
  },
  {
    postalCode: "0850",
    city: "OSLO",
    propertyName: "Nedre Ullevål 1",
  },
  {
    postalCode: "0850",
    city: "OSLO",
    propertyName: "Nedre Ullevål 2",
  },
  {
    postalCode: "0850",
    city: "OSLO",
    propertyName: "Nedre Ullevål 3",
  },
  {
    postalCode: "0850",
    city: "OSLO",
    propertyName: "Nedre Ullevål 4",
  },
  {
    postalCode: "0556",
    city: "OSLO",
    propertyName: "Nissens gate 3",
  },
  {
    postalCode: "0254",
    city: "OSLO",
    propertyName: "Observatoriegata 13- 15",
  },
  {
    postalCode: "0686",
    city: "OSLO",
    propertyName: "Oppsalveien 24",
  },
  {
    postalCode: "0686",
    city: "OSLO",
    propertyName: "Oppsalveien 26",
  },
  {
    postalCode: "0350",
    city: "OSLO",
    propertyName: "Parkveien 4",
  },
  {
    postalCode: "0254",
    city: "OSLO",
    propertyName: "Parkveien 78",
  },
  {
    postalCode: "0358",
    city: "OSLO",
    propertyName: "Pilestredet 103",
  },
  {
    postalCode: "0350",
    city: "OSLO",
    propertyName: "Pilestredet 57",
  },
  {
    postalCode: 4074,
    city: "OSLO",
    propertyName: "Presidentgata 4",
  },
  {
    postalCode: 1368,
    city: "STABEKK",
    propertyName: "Riiser-Larsens vei 7",
  },
  {
    postalCode: "0572",
    city: "OSLO",
    propertyName: "Rødstuveien 15 - 23",
  },
  {
    postalCode: "0477",
    city: "OSLO",
    propertyName: "Sandakerveien 58",
  },
  {
    postalCode: "0484",
    city: "OSLO",
    propertyName: "Sandakerveien 78",
  },
  {
    postalCode: "0557",
    city: "OSLO",
    propertyName: "Sannergata 23",
  },
  {
    postalCode: "0557",
    city: "OSLO",
    propertyName: "Sannergata 32",
  },
  {
    postalCode: "0468",
    city: "OSLO",
    propertyName: "Sarpsborggata 2",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 56",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 66",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 72",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 74",
  },
  {
    postalCode: "0558",
    city: "OSLO",
    propertyName: "Sofienberggata 39",
  },
  {
    postalCode: "0450",
    city: "OSLO",
    propertyName: "Sognsveien 9AB",
  },
  {
    postalCode: "0367",
    city: "OSLO",
    propertyName: "Sorgenfrigata 6",
  },
  {
    postalCode: "0165",
    city: "OSLO",
    propertyName: "St. Olavs Gate 21",
  },
  {
    postalCode: "0467",
    city: "OSLO",
    propertyName: "Stavangergata 23",
  },
  {
    postalCode: "0467",
    city: "OSLO",
    propertyName: "Stavangergata 25",
  },
  {
    postalCode: "0467",
    city: "OSLO",
    propertyName: "Stavangergata 27",
  },
  {
    postalCode: 1453,
    city: "BJØRNEMYR",
    propertyName: "Steinveien 1",
  },
  {
    postalCode: "0985",
    city: "OSLO",
    propertyName: "Stovner Senter 1",
  },
  {
    postalCode: "0985",
    city: "OSLO",
    propertyName: "Stovner Senter 11",
  },
  {
    postalCode: "0166",
    city: "OSLO",
    propertyName: "Sven Bruns gate 1",
  },
  {
    postalCode: "0166",
    city: "OSLO",
    propertyName: "Sven Bruns gate 3",
  },
  {
    postalCode: "0166",
    city: "OSLO",
    propertyName: "Sven Bruns gate 7",
  },
  {
    postalCode: 1412,
    city: "SOFIEMYR",
    propertyName: "Sønsterudveien 32",
  },
  {
    postalCode: "0664",
    city: "OSLO",
    propertyName: "Teisenveien 5E",
  },
  {
    postalCode: "0451",
    city: "OSLO",
    propertyName: "Thulstrups gate 5",
  },
  {
    postalCode: "0451",
    city: "OSLO",
    propertyName: "Thulstrups gate 7",
  },
  {
    postalCode: "0476",
    city: "OSLO",
    propertyName: "Torshovgata 12",
  },
  {
    postalCode: "0560",
    city: "OSLO",
    propertyName: "Trondheimsveien 10",
  },
  {
    postalCode: "0570",
    city: "OSLO",
    propertyName: "Trondheimsveien 187 - 193",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 01",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 03",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 05",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 07",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 08",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 10",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 12",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 14",
  },
  {
    postalCode: 2060,
    city: "GARDERMOEN",
    propertyName: "Tunvegen 16",
  },
  {
    postalCode: "0850",
    city: "OSLO",
    propertyName: "Ullevålsalléen 2",
  },
  {
    postalCode: "0850",
    city: "OSLO",
    propertyName: "Ullevålsallèen 5",
  },
  {
    postalCode: "0187",
    city: "OSLO",
    propertyName: "Urtegata 20 - 22",
  },
  {
    postalCode: "0356",
    city: "OSLO",
    propertyName: "Vibes gate 11",
  },
  {
    postalCode: "0458",
    city: "OSLO",
    propertyName: "Vøyensvingen 2",
  },
  {
    postalCode: "0172",
    city: "OSLO",
    propertyName: "Waldemar Thranes gate 3",
  },
  {
    postalCode: "0175",
    city: "OSLO",
    propertyName: "Waldemar Thranes Gate 71",
  },
  {
    postalCode: "0175",
    city: "OSLO",
    propertyName: "Waldemar Thranes Gate 73",
  },
  {
    postalCode: "0580",
    city: "OSLO",
    propertyName: "Økernveien 149",
  },
  {
    postalCode: "0170",
    city: "OSLO",
    propertyName: "Testveien 1",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 58",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 60",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 62",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 64",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 68",
  },
  {
    postalCode: "0586",
    city: "OSLO",
    propertyName: "Sinsenveien 70",
  },
  {
    postalCode: "0985",
    city: "OSLO",
    propertyName: "Stovner Senter 13",
  },
  {
    postalCode: "0985",
    city: "OSLO",
    propertyName: "Stovner Senter 15",
  },
  {
    postalCode: "0985",
    city: "OSLO",
    propertyName: "Stovner Senter 19",
  },
  {
    postalCode: "0985",
    city: "OSLO",
    propertyName: "Stovner Senter 22",
  },
  {
    postalCode: "0450",
    city: "OSLO",
    propertyName: "Sognsveien 9",
  },
];
