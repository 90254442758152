import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import dateFormat from "dateformat";

import { Message } from "../../../../../shared/types/Ticket";
import { sxProps } from "./message-wrapper.style";
import DOMPurify from "dompurify";

export type MessageWrapperProps = {
  message: Message;
  dateLabel: string;
};

export const MessageWrapper = ({
  message: msg,
  dateLabel,
}: MessageWrapperProps) => {
  const { isAuthor, date, message, is3rdParty, isPublic } = msg;

  const wraperBoxSx = useMemo(() => {
    return {
      ...sxProps.wrapperBox,
      ...(isAuthor ? sxProps.wrapperBoxRight : sxProps.wrapperBoxLeft),
    };
  }, [isAuthor]);

  const dateBoxSx = useMemo(() => {
    return {
      ...sxProps.dateBox,
      ...(isAuthor ? sxProps.dateBoxAlignRight : sxProps.dateBoxAlignLeft),
    };
  }, [isAuthor]);

  const messageBox = useMemo(() => {
    const thirdPartySx = is3rdParty ? sxProps.thirdPartyMessageBox : {};
    const internalSxProps = isPublic ? {} : sxProps.internalMessageBox;
    return {
      ...sxProps.messageBox,
      ...thirdPartySx,
      ...internalSxProps,
    };
  }, [is3rdParty, isPublic]);

  return (
    <Box sx={wraperBoxSx}>
      <Box sx={dateBoxSx}>
        <Typography component="span" variant="body2">
          {dateLabel}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={sxProps.dateTypography}
        >{`${dateFormat(date, "dd.mm.yyyy")}:`}</Typography>
      </Box>
      <Box sx={messageBox}>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
        />
      </Box>
    </Box>
  );
};
