import { TFunction } from "react-i18next";
import { store } from "../../../../../shared/store";
import {
  RequestTypesNamesEnum,
  Ticket,
} from "../../../../../shared/types/Ticket";

export const createFailureString = (ticket: Ticket, t: TFunction) => {
  let failureString = "";
  const { failure } = ticket;
  const { failures } = store.getState().ticket;

  switch (ticket.requestType.type) {
    case RequestTypesNamesEnum.COMPLAINTS:
      return t("failure.customerComplaint");
    case RequestTypesNamesEnum.GENERAL:
      return t("failure.general");
    case RequestTypesNamesEnum.OUTDOOR:
    case RequestTypesNamesEnum.UNIT:
    case RequestTypesNamesEnum.COMMON:
      if (!failure) return "";
      failure.forEach((f, i) => {
        let failureInfo = failures[f.id];
        failureString += failureInfo ? failureInfo : "";
        if (i < failure.length - 1) {
          //adding a / character between levels of failure
          failureString += `${" "}/${" "}`;
        }
      });
  }

  return failureString;
};
