import { ProjectCountryCode, Ticket } from "../types/Ticket";

export const getEmailBody = ({ key, countryCode, assignee }: Ticket) => {
  switch (countryCode) {
    case ProjectCountryCode.MTN:
      return `Hei, dette er ${assignee.fullName} fra Heimstaden. Jeg kontakter deg angående oppgaven din i MyHome ${key}.`;
    case ProjectCountryCode.MTD:
      return `Hej, dette er ${assignee.fullName} fra Heimstaden. Jeg skriver til dig vedrørende din opgave i MinBolig ${key}.`;
    case ProjectCountryCode.MTI:
      return `Hæ, þetta er ${assignee.fullName} frá Heimstaden. Ég er að hafa samband við þig varðandi verkbeiðni þína í MyHome ${key}.`;
    default:
      return `Hi, this is ${assignee.fullName} from Heimstaden. I am writing to you regarding your service request in MinBolig ${key}.`;
  }
};
