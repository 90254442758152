import React from "react";
import { Box, Grid } from "@mui/material";

import { JiraProfile } from "../../../shared/types/Profile";
import { sxProps } from "./profile-image.style";

export type ProfileImageProps = {
  profile: JiraProfile;
};

export const ProfileImage = ({ profile }: ProfileImageProps) => {
  return (
    <Box sx={sxProps.profileImageBox}>
      <Grid container spacing={3}>
        <Grid item xs />
        <Grid item xs={6}>
          <Box component="img" src={profile.avatar} sx={sxProps.profileImage} />
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};
