import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { Notification } from "../../../generated/clients/notifications/data-contracts";
import type { RootState } from "./index";

export type NotificationState = {
  notifications: Record<string, Notification>;
  hasNextPage?: boolean;
  nextPage?: number;
  isAlert: boolean;
};

const initialState: NotificationState = {
  notifications: {},
  isAlert: false,
};

//attachment state will contain all attachments that are donwloaded from jira
const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotifications: (
      state,
      action: PayloadAction<{
        notifications: Notification[];
        hasNextPage?: boolean;
        nextPage?: number;
      }>
    ) => {
      const newNotificationRecord: Record<string, Notification> = {};
      action.payload.notifications.forEach(
        (n) => (newNotificationRecord[n.id] = n)
      );

      state.notifications = {
        ...state.notifications,
        ...newNotificationRecord,
      };
      state.hasNextPage = action.payload.hasNextPage;
      state.nextPage = action.payload.nextPage;
      setIsAlert(state);
    },

    setNotificationAsRead: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const notification = state.notifications[id];
      notification.readAt = new Date().toString();
      state.notifications = { ...state.notifications, [id]: notification };
      setIsAlert(state);
    },

    setAllNotificationAsRead: (state) => {
      const newNotifications = { ...state.notifications };
      Object.keys(newNotifications).forEach(
        (k) => (newNotifications[k].readAt = new Date().toString())
      );
      state.notifications = newNotifications;
      setIsAlert(state);
    },
  },
});

const setIsAlert = (state: WritableDraft<NotificationState>) => {
  state.isAlert = Boolean(
    Object.values(state.notifications).find((n) => !n.readAt)
  );
};

export const {
  addNotifications,
  setNotificationAsRead,
  setAllNotificationAsRead,
} = notificationSlice.actions;
export const selectNotifications = (state: RootState) =>
  state.notifications.notifications;
export const selectNextNotificationPage = (state: RootState) =>
  state.notifications.nextPage;
export const selectHasNextNotificationPage = (state: RootState) =>
  state.notifications.hasNextPage;
export const selectIsAlert = (state: RootState) => state.notifications.isAlert;

export default notificationSlice.reducer;
