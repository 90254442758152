import React, { ReactNode } from "react";
import { Box } from "@mui/material";

import { sxProps } from "./menu-item.style";

export type MenuItemProps = {
  children: ReactNode;
  active: boolean;
};

export const MenuItem = (props: MenuItemProps) => {
  const activeSxProps = props.active ? sxProps.menuItemActive : {};
  return (
    <Box sx={{ ...sxProps.menuItemBox, ...activeSxProps }}>
      {props.children}
    </Box>
  );
};
