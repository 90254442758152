import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  bottomNavigation: {
    marginTop: "auto",
    background: (theme) => theme.palette.primary.main,
    height: (theme) => theme.custom.menuHeight,
  },

  bottomNavigationAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    color: (theme) => theme.palette.primary.contrastText,
    "&.Mui-disabled": {
      color: (theme) => theme.palette.grey[600],
    },
  },
};
