import { SxProps } from "@mui/system/styleFunctionSx";
import { Theme } from "@mui/material/styles/createTheme";

export const sxProps: Record<string, SxProps<Theme>> = {
  serviceRequestDetailsActionLink: {
    textDecoration: "none",
  },

  serviceRequestDetailsButtonsIcon: {
    height: (theme) => theme.spacing(3),
    paddingRight: (theme) => theme.spacing(1.25),
  },

  serviceRequestDetailsButton: {
    minHeight: (theme) => theme.spacing(5.25),
    width: "auto",
    height: "auto",
    marginTop: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(1),
    fontSize: "0.938rem",
    wordBreak: "break-all",
  },
};
