import React, { createContext, useContext, useEffect } from "react";
import type { ReactNode } from "react";
import { useNavigate } from "react-router";

import { useAppSelector } from "../shared/store/hooks";
import { selectTicketByKey } from "../shared/store/ticketSlice";
import { TicketProvider } from "../shared/provider/ticketProvider";
import { paths } from "../shared/routes/paths";
import { Ticket } from "../shared/types/Ticket";
import { TransitionFormFormat, Transition } from "../shared/types/Workflow";

interface TicketContextProviderProps {
  children?: ReactNode;
  ticketKey: string;
}
const TicketContext = createContext<{
  ticket?: Ticket;
  refreshTicket?: () => Promise<void>;

  changeTicketStatus?: (
    ticket: Ticket,
    transition: Transition
  ) => Promise<TransitionFormFormat | undefined>;

  changeTicketStatusWithTransitionData?: (
    url: string,
    transition: Transition,
    transitionData: any
  ) => Promise<void>;
}>({});

const ticketProvider = new TicketProvider();

export const useTicket = () => {
  return useContext(TicketContext).ticket;
};

export const useRefreshTicket = () => {
  return useContext(TicketContext).refreshTicket;
};

export const useChangeTicketStatus = () => {
  return useContext(TicketContext).changeTicketStatus;
};

export const useChangeTicketStatusWithTransitionData = () => {
  return useContext(TicketContext).changeTicketStatusWithTransitionData;
};

export const TicketContextProvider = ({
  children,
  ticketKey,
}: TicketContextProviderProps) => {
  const ticket = useAppSelector((state) => selectTicketByKey(state, ticketKey));
  const navigate = useNavigate();

  const fetchTicketByKey = async () => {
    const ticket = await ticketProvider.fetchTicket(ticketKey);
    if (!ticket) {
      navigate(paths.dashboard);
    }
  };

  const changeTicketStatus = async (ticket: Ticket, transition: Transition) => {
    return await ticketProvider.changeTicketStatus(ticket, transition);
  };

  const changeTicketStatusWithTransitionData = async (
    url: string,
    transition: Transition,
    transitionData: any
  ) => {
    return await ticketProvider.changeTicketStatusWithTransitionData(
      url,
      transition,
      transitionData
    );
  };

  useEffect(() => {
    if (!ticket) {
      fetchTicketByKey();
    }
  }, [ticket]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ticket) return null;
  return (
    <TicketContext.Provider
      value={{
        ticket,
        refreshTicket: fetchTicketByKey,
        changeTicketStatus,
        changeTicketStatusWithTransitionData,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
