import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  serviceRequestDetailsActions: {
    paddingTop: (theme) => theme.spacing(1.4),
  },

  serviceRequestDetailsButtonsBox: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: (theme) => theme.spacing(0, 1),
  },

  serviceRequestDetailsButtonsIcon: {
    height: (theme) => theme.spacing(3),
    paddingRight: (theme) => theme.spacing(1.25),
  },

  serviceRequestDetailsButton: {
    minHeight: (theme) => theme.spacing(5.25),
    width: "auto",
    height: "auto",
    padding: (theme) => theme.spacing(0.75, 2.5),
  },

  serviceRequestDetailsSelect: {
    padding: (theme) => theme.spacing(1.8, 1, 0, 1),
  },

  serviceRequestDetailsActionLink: {
    textDecoration: "none",
  },

  selectStatusLabel: {
    color: (theme) => theme.palette.grey[500],
    display: "block",
    marginBottom: (theme) => theme.spacing(0.5),
  },
};
