import { createTheme, PaletteColor } from "@mui/material/styles";

const primary = {
  main: "#FF5000",
  dark: "#D04100",
  light: "#FF7435",
  contrastText: "#FFFFFF",
};
const secondary = {
  main: "#44546A",
  dark: "#39475A",
  light: "#5D728F",
  contrastText: "#FFFFFF",
};
const info = {
  main: "#0050FF",
  dark: "#0046DF",
  light: "#266AFF",
  contrastText: "#FFFFFF",
};
const error = {
  main: "#F44336",
  dark: "#E31B0C",
  light: "#F88078",
  contrastText: "#FFFFFF",
};
const warning = {
  main: "#FF9800",
  dark: "#C77700",
  light: "#FFB547",
  contrastText: "rgba(0, 0, 0, 0.87)",
};
const success = {
  main: "#00AF3F",
  dark: "#00842F",
  light: "#00E251",
  contrastText: "#FFFFFF",
};

const action = {
  //   focus: "rgba(224, 224, 224, 1)",
  hover: "rgba(0, 0, 0, 0.04)",
  selected: "rgba(0, 0, 0, 0.08)",
  disabledBackground: "rgba(0, 0, 0, 0.12)",
  disabled: "rgba(0, 0, 0, 0.26)",
};

const text = {
  primary: "#333132",
  secondary: "rgba(68, 84, 106, 0.86)",
  disabled: "rgba(0, 0, 0, 0.38)",
};

const other = {
  border: "rgba(0, 0, 0, 0.23)",
  divider: "#E0E0E0",
  backdrop: "rgba(0, 0, 0, 0.5)",
  snackbar: "#323232",
  activeReading: "#FFB400",
  waitingExternal: "#8754F3",
  icons: "#8697AE",
};

const grey = {
  "50": "#FAFAFA",
  "100": "#F5F5F5",
  "200": "#EEEEEE",
  "300": "#E0E0E0",
  "400": "#BDBDBD",
  "500": "#9E9E9E",
  "600": "#9E9E9E",
  "700": "#616161",
  "800": "#424242",
  "900": "#212121",
  a100: "#D5D5D5",
  a200: "#AAAAAA",
  a400: "#616161",
  a700: "#303030",
};

const background = {
  default: "#333132",
  paper: "#F4F7FA",
};
const boxShadow =
  "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)";

export const theme = createTheme({
  palette: {
    primary,
    secondary,
    action,
    info,
    error,
    warning,
    success,
    background,
    text,
    divider: other.divider,
    grey,
  },
  typography: {
    h1: { fontSize: "2rem", lineHeight: "112%", letterSpacing: "-1.5px" },
    h3: {
      fontSize: "1.375rem",
      lineHeight: "116.7%",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "150%",
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: "0.8125rem", //13px
      lineHeight: "143%",
      letterSpacing: "0.15px",
    },
    button: {
      fontSize: "0.875rem", //14px
      fontWeight: "bold",
      lineHeight: "150%",
      textTransform: "none",
    },
    overline: {
      fontSize: "0.625rem",
      lineHeight: "200%",
      fontWeight: 700,
    },
    subtitle2: {
      size: "0.875rem",
      lineHeight: "150%",
      fontWeight: 700,
    },
    fontFamily: "Arial",
  },
  components: {
    MuiCard: {
      defaultProps: {
        style: {
          boxShadow,
          borderRadius: "16px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          boxShadow,
          borderRadius: "64px",
          textTransform: "none",
        },
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: primary.main,
          color: primary.contrastText,
          "&:hover": {
            md: {
              backgroundColor: primary.dark,
            },
          },
        },
      },
    },
    MuiCardActions: {
      defaultProps: {
        style: { padding: "1rem" },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInputBase: {
      defaultProps: {
        style: {
          borderRadius: "16px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        style: {
          boxShadow,
        },
      },
    },
  },
  custom: {
    headerWrapperHeight: "9.6vh",
    headerContainerHeight: "9vh",
    icons: other.icons,
    menuHeight: "9.4vh",
    tabMenuHeight: "6vh",
    notificationListHeight: "33vh",
    dashboardListHeight: "75vh",
    profilePageHeight: "81.4vh",
    activeReading: other.activeReading,
    waitingExternal: other.waitingExternal,
    warning,
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      headerWrapperHeight: string;
      headerContainerHeight: string;
      icons: string;
      menuHeight: string;
      tabMenuHeight: string;
      notificationListHeight: string;
      dashboardListHeight: string;
      profilePageHeight: string;
      activeReading: string;
      waitingExternal: string;
      warning: PaletteColor;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      headerWrapperHeight?: string;
      headerContainerHeight?: string;
      icons: string;
      menuHeight?: string;
      tabMenuHeight?: string;
      notificationListHeight?: string;
      dashboardListHeight?: string;
      profilePageHeight?: string;
      activeReading?: string;
      waitingExternal?: string;
      warning?: PaletteColor;
    };
  }
}
