import { UserSuggestion } from "../../../types/Ticket";
import { Avatar, Box, Typography } from "@mui/material";
import { sxProps } from "./taggable-text-field-mention.style";

export type TaggableTextFieldMentionProps = {
  mention: UserSuggestion;
  selectUser: Function;
};

export const TaggableTextFieldMentionComponent = ({
  mention,
  selectUser,
}: TaggableTextFieldMentionProps) => {
  const onClick = () => {
    selectUser(mention);
  };
  return (
    <Box sx={sxProps.mention} onClick={onClick}>
      <Avatar
        sx={sxProps.mentionAvatar}
        alt={mention.displayName}
        src={mention.avatarUrls["48x48"]}
        sizes={"48px"}
      />
      <Typography sx={sxProps.mentionLabel}>{mention.displayName}</Typography>
    </Box>
  );
};
