import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Message } from "../../../../../shared/types/Ticket";
import { MessageWrapper } from "../MessageWrapper";

export type MessageListProps = {
  messages?: Message[];
};

export const MessageList = ({ messages }: MessageListProps) => {
  const { t } = useTranslation();
  const bottomRef = useRef<HTMLDivElement>(null);

  const getDateLabel = useCallback(
    (m: Message) => {
      if (m.isAuthor) return t("+serviceRequest.messages.dateLabelOwner");
      else if (m.is3rdParty) {
        return t("+serviceRequest.messages.dateLabel3rdParty");
      } else {
        return t("+serviceRequest.messages.dateLabelOther");
      }
    },
    [t]
  );

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return messages ? (
    <>
      {messages.map((m, i) => {
        return (
          <MessageWrapper message={m} dateLabel={getDateLabel(m)} key={i} />
        );
      })}
      <div ref={bottomRef} />
    </>
  ) : null;
};
