import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

import { sxProps } from "./service-request-navbar-button.style";
import { useNavigate } from "react-router";
import { Counter, CounterTypes } from "../../types";

export type ServiceRequestNavbarButtonProps = {
  path: string;
  label: string;
  active: boolean;
  counter: Counter;
};

export const ServiceRequestNavbarButton = ({
  path,
  label,
  active,
  counter,
}: ServiceRequestNavbarButtonProps) => {
  const navigate = useNavigate();

  const sx = useMemo<SxProps<Theme>>(() => {
    return active
      ? { ...sxProps.navbarButtonBox, ...sxProps.navbarButtonActive }
      : sxProps.navbarButtonBox;
  }, [active]);

  const sxContainsCounter = useMemo<SxProps<Theme>>(() => {
    return counter.type !== CounterTypes.DISABLED
      ? { ...sx, ...sxProps.navbarButtonBoxCounter }
      : sx;
  }, [sx, counter.type]);

  const sxCounter = useMemo<SxProps<Theme>>(() => {
    return active
      ? {
          ...sxProps.counter,
          ...sxProps[`counter${counter.type}`],
          ...sxProps.counterActive,
        }
      : { ...sxProps.counter, ...sxProps[`counter${counter.type}`] };
  }, [active, counter.type]);

  const counterBox = () => {
    return <Box sx={sxCounter}>{counter.value}</Box>;
  };

  const onClick = () => {
    return active ? undefined : navigate(path);
  };
  return (
    <Box sx={sxContainsCounter} onClick={onClick}>
      <Typography variant="button">{label}</Typography>
      {counter.type !== CounterTypes.DISABLED ? counterBox() : ""}
    </Box>
  );
};
