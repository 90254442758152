import { FC, SVGProps } from "react";
import { ReactComponent as HouseIcon } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/home/house-1.svg";
import { ReactComponent as StairsIcon } from "@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/cellar-stair.svg";
import { ReactComponent as OutdoorsIcon } from "@heimstaden/icons-library/img/streamline-regular/nature-farming/outdoors/outdoors-tree-valley.svg";

import { ReactComponent as BathroomIcon } from "@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-tub-towel.svg";
import { ReactComponent as KitchenIcon } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/kitchenware/kitchenware-ladle-hot.svg";
import { ReactComponent as LivingRoomIcon } from "@heimstaden/icons-library/img/streamline-regular/health-beauty/waiting-room-appointments/medical-room-clock-man.svg";
import { ReactComponent as BedroomIcon } from "@heimstaden/icons-library/img/streamline-regular/hotels-spas/hotel-bedrooms/hotel-single-bed.svg";
import { ReactComponent as EntranceIcon } from "@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg";
import { ReactComponent as BalconyIcon } from "@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/cellar-3.svg";

import { ReactComponent as ACHeatIcon } from "@heimstaden/icons-library/img/streamline-regular/hotels-spas/ac-heating/ac-heat.svg";
import { ReactComponent as AppliancesFridgeIcon } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-fridge.svg";
import { ReactComponent as AppliancesStove } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-stove.svg";
import { ReactComponent as BathroomShowerIcon } from "@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-shower.svg";
import { ReactComponent as BikingPersonIcon } from "@heimstaden/icons-library/img/streamline-regular/sports/biking/biking-person.svg";
import { ReactComponent as BuildingIcon } from "@heimstaden/icons-library/img/streamline-regular/building-construction/buildings/building.svg";
import { ReactComponent as FamilyOutdoorsPlayhouseIcon } from "@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-playhouse.svg";
import { ReactComponent as FamilyOutdoorsTeeterIcon } from "@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-teeter.svg";
import { ReactComponent as GarbageBinIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-bin.svg";
import { ReactComponent as GardeningWateringPipeIcon } from "@heimstaden/icons-library/img/streamline-regular/nature-farming/gardening/gardening-watering-pipe.svg";
import { ReactComponent as IceRoadIcon } from "@heimstaden/icons-library/img/streamline-regular/weather/ice-snow/ice-road.svg";
import { ReactComponent as RenewableEnergyWindTurbine } from "@heimstaden/icons-library/img/streamline-regular/ecology/renewable-energy/renewable-energy-wind-turbine.svg";
import { ReactComponent as LaundryMachine1Icon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/laundry/laundry-machine-1.svg";
import { ReactComponent as LaundryMachineIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/laundry/laundry-machine.svg";
import { ReactComponent as LiftIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/lifts/lift.svg";
import { ReactComponent as LightBulbIcon } from "@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/light-bubbles/light-bulb.svg";
import { ReactComponent as LockerRoomWashHands1Icon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/cloak-lockers-room/locker-room-wash-hands-1.svg";
import { ReactComponent as MailboxInIcon } from "@heimstaden/icons-library/img/streamline-regular/emails/mailbox/mailbox-in.svg";
import { ReactComponent as MatchesFireIcon } from "@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/lighter-matches-candles/match-fire.svg";
import { ReactComponent as MaterialStoneIcon } from "@heimstaden/icons-library/img/streamline-regular/tools-constructions/materials/material-stone.svg";
import { ReactComponent as ModernTvRemoteIcon } from "@heimstaden/icons-library/img/streamline-regular/video-movies-tv/modern-tv/modern-tv-remote.svg";
import { ReactComponent as NuclearEnergyMessageIcon } from "@heimstaden/icons-library/img/streamline-regular/ecology/nuclear-fossil-energy/nuclear-energy-message.svg";
import { ReactComponent as ParkingP1Icon } from "@heimstaden/icons-library/img/streamline-regular/transportation/parking/parking-p-1.svg";
import { ReactComponent as RealEstateActionHouseKeyIcon } from "@heimstaden/icons-library/img/streamline-regular/real-estate/actions-houses/real-estate-action-house-key.svg";
import { ReactComponent as SkunkIcon } from "@heimstaden/icons-library/img/streamline-regular/pets-animals/rodents/skunk.svg";
import { ReactComponent as SofaDoubleIcon } from "@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/sofas-benches/sofa-double.svg";
import { ReactComponent as StairsAscendIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-ascend.svg";
import { ReactComponent as ToiletSeatIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/toilets/toilet-seat.svg";
import { ReactComponent as VegetablesPlateIcon } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/vegetables/vegetables-plate.svg";
import { ReactComponent as WarehouseStorageIcon } from "@heimstaden/icons-library/img/streamline-regular/shipping-delivery/warehouse-fullfiment/warehouse-storage.svg";
import { ReactComponent as WaterFountainJetIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/water-fountain/water-fountain-jet.svg";
import { ReactComponent as WaterFountainSinkIcon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/water-fountain/water-fountain-sink.svg";
import { ReactComponent as LaundryMachine2Icon } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/laundry/laundry-machine-2.svg";
import { ReactComponent as SafetyFireAlarm } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/safety/safety-fire-alarm.svg";
import { ReactComponent as BathroomMirrorCabinet } from "@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-mirror-cabinet.svg";
import { ReactComponent as HumidityHigh } from "@heimstaden/icons-library/img/streamline-regular/weather/humidity/humidity-high.svg";
import { ReactComponent as ArchitectureDoor } from "@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg";
import { ReactComponent as WaterProtectionFaucet } from "@heimstaden/icons-library/img/streamline-regular/ecology/water-protection/water-protection-faucet.svg";
import { ReactComponent as HardwareNutsRound } from "@heimstaden/icons-library/img/streamline-regular/tools-constructions/hardware/hardware-nuts-round.svg";
import { ReactComponent as AppliancesSlowCooker } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-slow-cooker.svg";
import { ReactComponent as StoveInductionPot } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/stove/stove-induction-pot.svg";
import { ReactComponent as TemperatureSnowflake } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/freezer-cooking-temperatures/temperature-snowflake.svg";
import { ReactComponent as LoginKeys } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/login-keys.svg";
import { ReactComponent as PestBusters } from "@heimstaden/icons-library/img/streamline-regular/building-construction/renovation-improvements/pest-busters.svg";
import { ReactComponent as AppliancesOven } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-oven.svg";
import { ReactComponent as RealEstateDimensionsPlan1 } from "@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-plan-1.svg";
import { ReactComponent as GarbageThrow } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-throw.svg";
import { ReactComponent as StairsPersonDescend } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-person-descend.svg";
import { ReactComponent as FocusFlower } from "@heimstaden/icons-library/img/streamline-regular/images-photography/focus/focus-flower.svg";
import { ReactComponent as WarehouseStorage2 } from "@heimstaden/icons-library/img/streamline-regular/shipping-delivery/warehouse-fullfiment/warehouse-storage-2.svg";
import { ReactComponent as ColorRollingBrush } from "@heimstaden/icons-library/img/streamline-regular/design/colors-painting/color-rolling-brush.svg";
import { ReactComponent as WayfindingTicket } from "@heimstaden/icons-library/img/streamline-regular/wayfinding/tickets/ticket.svg";
import { ReactComponent as SmileyAngry } from "@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-angry.svg";
import { ReactComponent as HeadphonesSupport } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/help/headphones-customer-support-human.svg";
import { ReactComponent as PlantSad } from "@heimstaden/icons-library/img/streamline-regular/nature-farming/plants/plant-sad.svg";
import { ReactComponent as InductionPot } from "@heimstaden/icons-library/img/streamline-regular/food-drinks/stove/stove-induction-pot.svg";
import { ReactComponent as ConstructionDrill } from "@heimstaden/icons-library/img/streamline-regular/building-construction/construction/construction-drill.svg";
import { ReactComponent as FolderQuestion } from "@heimstaden/icons-library/img/streamline-regular/files-folders/folders/folder-question.svg";
import { ReactComponent as ScienceBrain } from "@heimstaden/icons-library/img/streamline-regular/science/science/science-brain.svg";
import { ReactComponent as WenchIcon } from "@heimstaden/icons-library/img/streamline-regular/interface-essential/settings/wench-1.svg";
import { CustomerComplaintReason } from "../types/Ticket";

export type FailureType = {
  categoryKey: string;
  icon: FC<SVGProps<SVGSVGElement>>;
};

export const problemKey = "problem";
export const customerComplaintKey = "customerComplaint";
export const generalKey = "general";

export enum FailureCategoryLabelKey {
  CATEGORY = "failure.category.category",
  ROOM = "failure.category.room",
  TYPE = "failure.category.type",
  SPECIFIC_PROBLEM = "failure.category.problem",
}

export const failure: { [key: string]: FailureType } = {
  //SERVICE REQUEST TYPE
  // NORWAY
  "93": {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: HouseIcon,
  },
  "94": {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: StairsIcon,
  },
  "95": {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: OutdoorsIcon,
  },
  // DENMARK
  "119": {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: HouseIcon,
  },
  "120": {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: StairsIcon,
  },
  "121": {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: OutdoorsIcon,
  },
  //rooms
  // NORWAY
  "10732": {
    categoryKey: FailureCategoryLabelKey.ROOM,
    icon: BathroomIcon,
  },
  "10733": {
    categoryKey: FailureCategoryLabelKey.ROOM,
    icon: KitchenIcon,
  },
  "10734": {
    categoryKey: FailureCategoryLabelKey.ROOM,
    icon: LivingRoomIcon,
  },
  "10735": {
    categoryKey: FailureCategoryLabelKey.ROOM,
    icon: BedroomIcon,
  },
  "10736": {
    categoryKey: FailureCategoryLabelKey.ROOM,
    icon: EntranceIcon,
  },
  "10737": {
    categoryKey: FailureCategoryLabelKey.ROOM,
    icon: BalconyIcon,
  },
  // DANMARK
  "11130": { categoryKey: FailureCategoryLabelKey.ROOM, icon: EntranceIcon },
  "11131": { categoryKey: FailureCategoryLabelKey.ROOM, icon: BathroomIcon },
  "11132": { categoryKey: FailureCategoryLabelKey.ROOM, icon: EntranceIcon },
  "11134": { categoryKey: FailureCategoryLabelKey.ROOM, icon: KitchenIcon },
  "11135": { categoryKey: FailureCategoryLabelKey.ROOM, icon: EntranceIcon },
  "11137": { categoryKey: FailureCategoryLabelKey.ROOM, icon: EntranceIcon },
  "11138": { categoryKey: FailureCategoryLabelKey.ROOM, icon: EntranceIcon },
  "11139": { categoryKey: FailureCategoryLabelKey.ROOM, icon: EntranceIcon },

  //category
  // NORWAY
  "10738": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BathroomShowerIcon,
  },
  "10739": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ToiletSeatIcon,
  },
  "10740": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LaundryMachine1Icon,
  },
  "10741": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WaterFountainJetIcon,
  },
  "10834": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LockerRoomWashHands1Icon,
  },
  "10742": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LockerRoomWashHands1Icon,
  },
  "10743": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LightBulbIcon,
  },
  "10744": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: SofaDoubleIcon,
  },
  "10745": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ACHeatIcon,
  },
  "10840": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: MaterialStoneIcon,
  },
  "10746": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: MaterialStoneIcon,
  },
  "10747": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: NuclearEnergyMessageIcon,
  },
  "10749": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: AppliancesFridgeIcon,
  },
  "10750": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: VegetablesPlateIcon,
  },
  "10751": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: AppliancesStove,
  },
  "10752": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: RenewableEnergyWindTurbine,
  },
  "10753": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WaterFountainSinkIcon,
  },
  "10754": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ModernTvRemoteIcon,
  },
  "10839": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: RealEstateActionHouseKeyIcon,
  },
  "10755": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: RealEstateActionHouseKeyIcon,
  },
  "10833": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: MatchesFireIcon,
  },
  "10756": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: MatchesFireIcon,
  },
  "10835": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: GardeningWateringPipeIcon,
  },
  "10836": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ParkingP1Icon,
  },
  "10837": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LiftIcon,
  },
  "10838": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: MailboxInIcon,
  },
  "10841": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: StairsAscendIcon,
  },
  "10842": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LaundryMachineIcon,
  },
  "10843": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WarehouseStorageIcon,
  },
  "10847": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: GarbageBinIcon,
  },
  "10844": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: GarbageBinIcon,
  },
  "10845": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BikingPersonIcon,
  },
  "10846": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: SkunkIcon,
  },
  "10905": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: GarbageBinIcon,
  },
  "10901": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BuildingIcon,
  },
  "10902": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: FamilyOutdoorsPlayhouseIcon,
  },
  "10903": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: FamilyOutdoorsTeeterIcon,
  },
  "10904": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: IceRoadIcon,
  },
  // DENMARK
  "11178": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LaundryMachine2Icon,
  },
  "11181": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LightBulbIcon,
  },
  "11187": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: SafetyFireAlarm,
  },
  "11190": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ACHeatIcon,
  },
  "11200": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BathroomMirrorCabinet,
  },
  "11216": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: HumidityHigh,
  },
  "11234": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BathroomShowerIcon,
  },
  "11241": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ArchitectureDoor,
  },
  "11250": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WaterFountainSinkIcon,
  },
  "11253": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LaundryMachine1Icon,
  },
  "11257": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WaterProtectionFaucet,
  },
  "11263": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ToiletSeatIcon,
  },
  "11268": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WenchIcon,
  },
  "11173": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: HardwareNutsRound,
  },
  "11212": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: AppliancesSlowCooker,
  },
  "11214": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WaterFountainSinkIcon,
  },
  "11221": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: StoveInductionPot,
  },
  "11229": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: TemperatureSnowflake,
  },
  "11205": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LoginKeys,
  },
  "11986": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: PestBusters,
  },
  "11275": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BikingPersonIcon,
  },
  "11278": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: AppliancesOven,
  },
  "11281": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: RealEstateDimensionsPlan1,
  },
  "11282": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LiftIcon,
  },
  "11285": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: SafetyFireAlarm,
  },
  "11288": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ParkingP1Icon,
  },
  "11291": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: GarbageThrow,
  },
  "11295": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: StairsPersonDescend,
  },
  "11298": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LoginKeys,
  },
  "11304": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: LaundryMachine1Icon,
  },
  "11311": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: MailboxInIcon,
  },
  "11317": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: SkunkIcon,
  },
  "11319": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: FocusFlower,
  },
  "11321": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WarehouseStorage2,
  },
  "11322": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: GarbageBinIcon,
  },
  "11325": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ArchitectureDoor,
  },
  "11334": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WaterProtectionFaucet,
  },
  "11989": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: PestBusters,
  },
  "11270": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: ColorRollingBrush,
  },
  "11271": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: BuildingIcon,
  },
  "11272": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: FamilyOutdoorsPlayhouseIcon,
  },
  "11273": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: FamilyOutdoorsTeeterIcon,
  },
  "11274": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: IceRoadIcon,
  },
  "11988": {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: PestBusters,
  },
  [problemKey]: {
    categoryKey: FailureCategoryLabelKey.SPECIFIC_PROBLEM,
    icon: WenchIcon,
  },
  [generalKey]: {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: WayfindingTicket,
  },
  [customerComplaintKey]: {
    categoryKey: FailureCategoryLabelKey.TYPE,
    icon: HeadphonesSupport,
  },
  [CustomerComplaintReason.NEIGHBOUR_COMPLAINT]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: SmileyAngry,
  },
  [CustomerComplaintReason.COMPLAINT_REGARDING_HEIMSTADEN]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: PlantSad,
  },
  [CustomerComplaintReason.GARBAGE]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: GarbageBinIcon,
  },
  [CustomerComplaintReason.NOISE_FROM_CONSTRUCTION_WORK]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: ConstructionDrill,
  },
  [CustomerComplaintReason.OTHER]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: FolderQuestion,
  },
  [CustomerComplaintReason.SMOKE_ODOR]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: InductionPot,
  },
  [CustomerComplaintReason.SUGGESTIONS_FROM_IMPROVEMENTS]: {
    categoryKey: FailureCategoryLabelKey.CATEGORY,
    icon: ScienceBrain,
  },
};
