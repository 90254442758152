import React, { useEffect, useMemo, useState } from "react";
import { Container, Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useRefreshTicket, useTicket } from "../../ticketContextProvider";
import { sxProps } from "./service-request-attachments.style";
import { AttachmentProvider } from "../../../shared/provider/attachmentProvider";
import {
  AttachmentContent,
  AttachmentForUpload,
} from "../../../shared/types/Ticket";
import {
  AttachmentThumbnail,
  AttachmentUpload,
  AttachmentViewModal,
} from "../../../shared/components";
import { useAppSelector } from "../../../shared/store/hooks";
import { selectAttachmentsContentMapForTicket } from "../../../shared/store/attachmentSlice";
import { ProfileProvider } from "../../../shared/provider/profileProvider";
import { selectProfile } from "../../../shared/store/profileSlice";

const attachmentProvider = new AttachmentProvider();

export const ServiceRequestAttachments = () => {
  const { t } = useTranslation();
  const ticket = useTicket();
  const refreshTicket = useRefreshTicket();
  const loadedAttachments = useAppSelector((state) =>
    selectAttachmentsContentMapForTicket(state, ticket)
  );
  const [open, setOpen] = useState<boolean>(false);
  const [clickedImageIndex, setClickedImageIndex] = useState<number>(0);
  const profileProvider = new ProfileProvider();
  const profile = useAppSelector((state) => selectProfile(state));

  const ownedAttachments = useMemo(() => {
    return loadedAttachments
      ? Object.values(loadedAttachments).filter((a) => {
          return a.userId === profile?.accountId || a.appCreated;
        })
      : [];
  }, [loadedAttachments, profile]);

  const otherAttachments = useMemo(() => {
    return loadedAttachments
      ? Object.values(loadedAttachments).filter((a) => {
          return !(a.userId === profile?.accountId || a.appCreated);
        })
      : [];
  }, [loadedAttachments, profile]);

  useEffect(() => {
    profileProvider.fetchProfile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ticket) {
      attachmentProvider.fetchAttachments(ticket);
    }
  }, [ticket]);

  const onFileSelected = async (
    attachmentsForUpload: AttachmentForUpload[]
  ) => {
    if (!ticket || !refreshTicket) return;
    await attachmentProvider.uploadAttachment(ticket, attachmentsForUpload);
    await refreshTicket();
  };

  const onThumbnailImageClick = (index: number, ownerAttachments: boolean) => {
    const clickedImageIndex =
      index + (ownerAttachments ? otherAttachments.length : 0);
    setClickedImageIndex(clickedImageIndex);
    setOpen(true);
  };

  const handleClick = (
    a: AttachmentContent,
    index: number,
    ownerAttachments: boolean
  ) => {
    if (a.type !== "image/jpeg") {
      window.open(a.url);
    } else {
      onThumbnailImageClick(index, ownerAttachments);
    }
  };

  const renderAttachments = (
    attachments: AttachmentContent[],
    label: string,
    isOwnerAttachments: boolean
  ) => {
    return (
      <>
        <Typography variant="overline" sx={sxProps.attachmentContainerLabel}>
          {label}
        </Typography>
        <Grid container sx={sxProps.attachmentGridContainer}>
          {attachments.map((a, i) => (
            <Grid
              item
              onClick={() => handleClick(a, i, isOwnerAttachments)}
              key={i}
              sx={sxProps.attachmentContainerItem}
              xs={6}
            >
              <AttachmentThumbnail attachment={a} />
            </Grid>
          ))}
          {isOwnerAttachments && (
            <Grid
              item
              key={"upload"}
              sx={sxProps.attachmentContainerItem}
              xs={6}
            >
              <AttachmentUpload onFileSelected={onFileSelected} />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  if (!ticket) return null;
  return (
    <Container sx={sxProps.attachmentContainer}>
      <AttachmentViewModal
        attachments={[...otherAttachments, ...ownedAttachments]}
        open={open}
        setOpen={setOpen}
        startIndex={clickedImageIndex}
      />
      <Box sx={sxProps.otherAttachmentsBox}>
        {renderAttachments(
          otherAttachments,
          t("+serviceRequest.attachments.otherAttachments"),
          false
        )}
      </Box>
      <Box sx={sxProps.ownerAttachmentsBox}>
        {renderAttachments(
          ownedAttachments,
          t("+serviceRequest.attachments.ownerAttachments"),
          true
        )}
      </Box>
    </Container>
  );
};
