import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import enTrans from "./enTrans.json";

export const initTransaltion = () => {
  i18next.use(initReactI18next).init({
    resources: {
      en: { translation: enTrans },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });
};
