import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

//this are styles that are used accross application
//but are to specific to be put into theme.ts
export const globalSxProps: Record<string, SxProps<Theme>> = {
  textBold: {
    fontWeight: 700,
  },

  buttonLong: {
    flex: 1,
  },

  cleanLink: {
    textDecoration: "none",
    "&:visited": {
      color: (theme) => theme.palette.primary.main,
    },
  },

  statusInProgress: {
    background: (theme) => theme.palette.info.main,
  },

  statusClosed: {
    background: (theme) => theme.palette.success.main,
  },

  statusOpen: {
    background: (theme) => theme.palette.grey[500],
  },

  statusAwaitingTenant: {
    background: (theme) => theme.custom.activeReading,
  },

  statusAwaitingExternal: {
    background: (theme) => theme.custom.waitingExternal,
  },

  statusOnHold: {
    background: (theme) => theme.palette.error.main,
  },

  singleLineOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
};
