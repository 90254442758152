import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

import backgroundImage from "./assets/image-background.jpg";

export const sxProps: Record<string, SxProps<Theme>> = {
  imageBackgroundContainer: {
    //because header is sticky and is 64px height, we need to
    marginTop: (theme) => `-${theme.custom.headerWrapperHeight}`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundSize: { xs: "cover", sm: "none" },
    height: "100vh",
  },
  imageBackgroundGrid: {
    height: "100%",
  },
};
