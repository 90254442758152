import React from "react";
import { RouteProps, Navigate } from "react-router-dom";
import { selectLoggedUser } from "../store/authSlice";
import { useAppSelector } from "../store/hooks";
import { paths } from "./paths";

export const GuardedRoute = ({ children }: RouteProps) => {
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));

  if (loggedUser) {
    return <>{children}</>;
  } else {
    return <Navigate to={paths.root} />;
  }
};
