import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  profileUnitsBox: {
    marginTop: (theme) => theme.spacing(3),
    paddingBottom: (theme) => theme.spacing(4),
  },
  profileUnitsLabel: {
    color: (theme) => theme.palette.primary.main,
    paddingLeft: (theme) => theme.spacing(2),
  },
  profileUnitsIconWrapper: {
    width: (theme) => theme.spacing(4.5),
    height: (theme) => theme.spacing(4.5),
  },

  profileUnitsIcon: {
    width: "100%",
    height: "100%",
  },

  profileUnitsGridItem: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    padding: (theme) => theme.spacing(2.5, 2, 0, 2),
  },

  profileUnitsAddress: {
    height: (theme) => theme.spacing(5),
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },

  profileUnitsButton: {
    marginTop: (theme) => theme.spacing(2.25),
    "& svg": {
      marginRight: (theme) => theme.spacing(1),
    },
  },
};
