import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthUser } from "../types/AuthUser";
import { loadAuthState } from "../utils/localStorage";
import type { RootState } from "./index";

export interface AuthState {
  loggedUser?: AuthUser;
}

const persistedState = loadAuthState();
const initialState: AuthState = persistedState || {};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthUser>) => {
      state.loggedUser = action.payload;
    },
    logout: (state) => {
      state.loggedUser = undefined;
    },
    updateAccessAndIdTokens: (
      state,
      action: PayloadAction<{ accessToken: string; idToken: string }>
    ) => {
      if (state.loggedUser) {
        state.loggedUser.accessToken = action.payload.accessToken;
        state.loggedUser.idToken = action.payload.idToken;
      }
    },
  },
});

export const { login, logout, updateAccessAndIdTokens } = authSlice.actions;
export const selectLoggedUser = (state: RootState) => state.auth.loggedUser;

export default authSlice.reducer;
