import { AttachmentForUpload } from "../../../shared/types/Ticket";
import _ from "lodash";

export const imagesToUpload: AttachmentForUpload[] = [];
export const parseHTMLMessage = (html: string) => {
  let imagesCounter: number = 1;

  const messageToProcess = document.createElement("div");
  messageToProcess.innerHTML = html;
  const childNodes = Array.from(messageToProcess.childNodes);

  const startsWithSpace = (str: string) => {
    return str.slice(0, 1).indexOf(" ") > -1;
  };

  const endsWithSpace = (str: string) => {
    return str.slice(str.length - 1).indexOf(" ") > -1;
  };

  const openingMarks = (marks: string[]) => {
    return marks.reduce((acc, val) => {
      return acc + val;
    }, "");
  };
  const closingMarks = (marks: string[]) => {
    return marks.reduceRight((acc, val) => {
      return acc + val;
    }, "");
  };

  const createMentionContent = (node: any) => {
    return `[~accountid:${node.dataset.id}]`;
  };

  const createTextContent = (node: any, parents: string[]) => {
    const marks: string[] = [];
    if (parents.length > 0) {
      parents.forEach((parent) => {
        if (parent === "STRONG") {
          marks.push("*");
        }
        if (parent === "EM") {
          marks.push("_");
        }
        if (parent === "U") {
          marks.push("+");
        }
      });
    }
    let textValue = `${openingMarks(
      marks
    )}${node.nodeValue.trim()}${closingMarks(marks)}`;
    if (startsWithSpace(node.nodeValue)) {
      textValue = ` ${textValue}`;
    }
    if (endsWithSpace(node.nodeValue)) {
      textValue = `${textValue} `;
    }

    return textValue;
  };

  const createMarkContent = (node: any, parents: string[]): any => {
    // @ts-ignore
    return Array.from(node.childNodes).map((childNode: ChildNode) => {
      if (childNode.nodeName === "#text") {
        return createTextContent(childNode, parents);
      } else {
        const newParents = [...parents, childNode.nodeName];
        return createMarkContent(childNode, newParents);
      }
    });
  };

  const createImageContent = (node: any) => {
    const name = `img-${Date.now()}-${imagesCounter}.jpeg`;
    imagesToUpload.push({
      name: name,
      value: node.currentSrc,
    });
    imagesCounter++;
    return `!${name}!`;
  };

  const createParagraph = (nodes: ChildNode[]) => {
    const paragraphContent = ["\n"];
    const paragraphNodesValue = nodes.map((node: any) => {
      if (node.nodeName === "#text") {
        return createTextContent(node, []);
      } else if (node.nodeName === "SPAN") {
        return createMentionContent(node);
      } else if (node.nodeName === "IMG") {
        return createImageContent(node);
      } else {
        return createMarkContent(node, [node.nodeName]);
      }
    });
    return [paragraphContent, ...paragraphNodesValue];
  };

  const createListItem = (nodes: ChildNode[], type: string) => {
    const listItemValue = nodes.map((node: any) => {
      if (node.nodeName === "#text") {
        return createTextContent(node, []);
      } else if (node.nodeName === "SPAN") {
        return createMentionContent(node);
      } else if (node.nodeName === "IMG") {
        return createImageContent(node);
      } else {
        return createMarkContent(node, [node.nodeName]);
      }
    });
    const flatListItemValue = _.flattenDeep(listItemValue);
    const stringListItemValue = flatListItemValue.reduce((acc, val) => {
      return acc + val;
    }, "");

    if (type === "orderedList") {
      return `\n# ${stringListItemValue}`;
    } else {
      return `\n* ${stringListItemValue}`;
    }
  };

  const createList = (type: string, nodes: ChildNode[]) => {
    return nodes.map((node: any) => {
      return createListItem(Array.from(node.childNodes), type);
    });
  };

  const nodesValue = childNodes.map((node) => {
    if (node.nodeName === "P") {
      return createParagraph(Array.from(node.childNodes));
    } else if (node.nodeName === "OL") {
      return createList("orderedList", Array.from(node.childNodes));
    } else if (node.nodeName === "UL") {
      return createList("unorderedList", Array.from(node.childNodes));
    } else {
      return [];
    }
  });
  const flatValues = _.flattenDeep(nodesValue);

  return flatValues.reduce((acc, val) => {
    return acc + val;
  }, "");
};
