import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  serviceRequestDetailsBox: {
    color: (theme) => theme.palette.common.black,
    height: (theme) => theme.custom.dashboardListHeight,
    overflow: "scroll",
  },
  serviceRequestDetailsMap: {
    marginTop: (theme) => theme.spacing(5.5),
    height: (theme) => theme.spacing(50),
    width: "100%",
  },
};
