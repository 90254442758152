import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  backButton: {
    color: (theme) => theme.palette.primary.contrastText,
    height: (theme) => theme.spacing(2.5),
    width: (theme) => theme.spacing(2.5),
    display: "flex",
  },

  serviceRequestNavbar: {
    background: (theme) => theme.palette.background.default,
    height: (theme) => theme.custom.tabMenuHeight,
    padding: (theme) => theme.spacing(0, 1),
    justifyContent: "space-around",
    alignItems: "center",
  },

  serviceRequestNavbarItem: {
    padding: (theme) => theme.spacing(1),
    justifyContent: "space-between",
  },
};
