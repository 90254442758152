import React from "react";
import { Card } from "@mui/material";
import { ImageBackground } from "../ImageBackground";
import { WelcomeCardContent } from "../WelcomeCardContent";

export const LoginScreen = () => {
  return (
    <ImageBackground>
      <Card>
        <WelcomeCardContent />
      </Card>
    </ImageBackground>
  );
};
