import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system/styleFunctionSx";

export const sxProps: Record<string, SxProps<Theme>> = {
  modal: {
    margin: "3vh 5vw 3vh 5vw",
  },

  modalContent: {
    position: "relative",
    maxHeight: "94vh",
    overflowY: "scroll",
  },

  inputField: {
    marginBottom: (theme) => theme.spacing(2),
  },

  inputFieldEmpty: {
    marginBottom: (theme) => theme.spacing(2),
    "& input": {
      color: "transparent",
    },
  },

  cancelButton: {
    marginLeft: (theme) => theme.spacing(2),
  },

  confirmButton: {
    marginRight: (theme) => theme.spacing(2),
  },

  buttonIcon: {
    marginRight: (theme) => theme.spacing(1),
  },
};
