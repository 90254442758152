import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

import { TicketStatus } from "../../types/Ticket";
import { Workflow } from "../../types/Workflow";

import { sxProps } from "./select-status.style";

export type SelectMenuItem = {
  status?: TicketStatus;
  labelKey: string;
  sx: SxProps<Theme>;
};

export const statusSelectPickerItems: {
  status: TicketStatus;
  labelKey: string;
  sx: SxProps<Theme>;
}[] = [
  {
    status: TicketStatus.OPEN,
    labelKey: "shared.selectStatus.open",
    sx: sxProps.openMenuBackground,
  },
  {
    status: TicketStatus.IN_PROGRESS,
    labelKey: "shared.selectStatus.inProgress",
    sx: sxProps.inProgressMenuBackground,
  },
  {
    status: TicketStatus.ON_HOLD,
    labelKey: "shared.selectStatus.onHold",
    sx: sxProps.onHoldMenuBackground,
  },
  {
    status: TicketStatus.AWAITING_TENANT,
    labelKey: "shared.selectStatus.awaitingTenant",
    sx: sxProps.awaitingTenenatMenuBackground,
  },
  {
    status: TicketStatus.TASK_IS_SENT_TO_EXTERNAL_PROVIDER,
    labelKey: "shared.selectStatus.awaitingExternal",
    sx: sxProps.awaitingExternalMenuBackground,
  },
  {
    status: TicketStatus.CLOSED,
    labelKey: "shared.selectStatus.closed",
    sx: sxProps.closedMenuBackground,
  },
];

export const statusSelectFilterItems: {
  status?: TicketStatus;
  labelKey: string;
  sx: SxProps<Theme>;
}[] = [
  {
    status: undefined,
    labelKey: "shared.selectStatus.active",
    sx: sxProps.openMenuBackground,
  },
  ...statusSelectPickerItems,
];

export const getPossibleTransition = (
  workflow: Workflow,
  selectedStatus: TicketStatus,
  statusToShow: TicketStatus
) => {
  return workflow[selectedStatus].find((w) => w.status === statusToShow);
};
