import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  TextField,
} from "@mui/material";
import { sxProps } from "./appointment-modal.style";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import { useTicket } from "../../../../../../../ticketContextProvider";
import { convertDateToString, createIcalFile } from "./util";
import { TicketProvider } from "../../../../../../../../shared/provider/ticketProvider";
import { Ticket } from "../../../../../../../../shared/types/Ticket";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../shared/store/hooks";
import { selectProfile } from "../../../../../../../../shared/store/profileSlice";
import { addErrorSnackbar } from "../../../../../../../../shared/store/snackbarSlice";

export type AppointmentModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  appointment: Date;
  setAppointment: Dispatch<SetStateAction<Date>>;
};

export const AppointmentModal = ({
  open,
  setOpen,
  appointment,
  setAppointment,
}: AppointmentModalProps) => {
  const { t } = useTranslation();
  const ticket = useTicket();
  const dispatch = useAppDispatch();
  const ticketProvider = new TicketProvider();
  const profile = useAppSelector((state) => selectProfile(state));

  const [reschedule, setReschedule] = useState<number>(
    ticket?.reschedulingCounter || 0
  );
  const [appointmentDay, setAppointmentDay] = useState<string>(
    dateFormat(appointment, "yyyy-mm-dd")
  );
  const [appointmentHour, setAppointmentHour] = useState<string>(
    dateFormat(appointment, "HH:MM")
  );
  const [appointmentTimeObject, setAppointmentTimeObject] = useState<
    Record<string, string>
  >({
    startTime: "",
    endTime: "",
  });

  const createTimesStrings = (scheduledDate: Date) => {
    const startTime = scheduledDate;
    const endTime = new Date(
      new Date(startTime).setHours(new Date(startTime).getHours() + 1)
    );
    setAppointmentTimeObject({
      startTime: convertDateToString(startTime),
      endTime: convertDateToString(endTime),
    });
  };

  const setBookingDate = async (
    ticket: Ticket,
    date: string,
    reschedule: number
  ) => {
    return await ticketProvider.setBookingDate(ticket, date, reschedule);
  };

  useEffect(() => {
    if (appointmentTimeObject.startTime.length > 0 && ticket) {
      try {
        let save = document.createElement("a");
        save.href = createIcalFile(
          appointmentTimeObject,
          profile,
          ticket,
          reschedule
        );
        save.target = "_blank";
        save.download = ticket?.key || "event";
        let evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      } catch (error) {
        dispatch(addErrorSnackbar(error as string));
      }
    }
  }, [appointmentTimeObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = () => {
    if (!ticket) return;
    const selectedDate = new Date(
      dateFormat(`${appointmentDay}T${appointmentHour}`)
    );
    setAppointment(selectedDate);
    createTimesStrings(selectedDate);
    setReschedule(reschedule + 1);
    setBookingDate(ticket, convertDateToString(selectedDate), reschedule);
    setOpen(false);
  };

  return (
    <Modal open={open} sx={sxProps.modal}>
      <Card sx={sxProps.modalContent}>
        <CardContent>
          <TextField
            sx={
              appointmentDay.length < 1
                ? sxProps.inputFieldEmpty
                : sxProps.inputField
            }
            fullWidth
            type="date"
            onChange={(e) => setAppointmentDay(e.target.value)}
            label={t(`+serviceRequest.details.appointmentDate`)}
            value={appointmentDay}
            placeholder={t(`+serviceRequest.details.appointmentDate`)}
          />
          <TextField
            sx={
              appointmentHour.length < 1
                ? sxProps.inputFieldEmpty
                : sxProps.inputField
            }
            fullWidth
            type="time"
            onChange={(e) => setAppointmentHour(e.target.value)}
            label={t(`+serviceRequest.details.appointmentTime`)}
            value={appointmentHour}
            placeholder={t(`+serviceRequest.details.appointmentTime`)}
          />
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            sx={sxProps.cancelButton}
            onClick={() => setOpen(false)}
          >
            <CancelOutlined sx={sxProps.buttonIcon} />
            {t(`+serviceRequest.details.cancelButton`)}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={sxProps.confirmButton}
            onClick={() => {
              submitForm();
            }}
            disabled={appointmentDay.length < 1 || appointmentHour.length < 1}
          >
            <CheckCircleOutline sx={sxProps.buttonIcon} />
            {t(`+serviceRequest.details.confirmButton`)}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
