import sanityClient from "@sanity/client";
import { FailureData } from "../../types/Ticket";
import { getEnvValue } from "../../utils/env.helper";

export class SanityClient {
  private client;

  constructor() {
    this.client = sanityClient({
      projectId: getEnvValue("SANITY_PROJECT_ID"),
      dataset: getEnvValue("SANITY_DATASET"),
      apiVersion: "v2021-03-25",
      useCdn: Boolean(getEnvValue("SANITY_CDN")),
    });
  }

  fetchFailures = async (failureIds: string[]): Promise<FailureData[]> => {
    const params = {
      type: "ticketElement",
      fieldId: failureIds,
    };
    const query = `*[_type == $type && fieldId in $fieldId]{
        "id": fieldId,
        "value": title->value.en
      }`;
    return await this.client.fetch<FailureData[]>(query, params);
  };

  fetchRequestType = async (
    areaId: string
  ): Promise<FailureData | undefined> => {
    const params = {
      type: "settings",
      typeId: Number(areaId),
    };
    const query = `*[_type == $type]{
      ...content.ticketing{
         ...select(
           repairMaintenanceRequest.areas.common.typeId == $typeId => {
             "value": repairMaintenanceRequest.areas.common.title->value.en
           },
           repairMaintenanceRequest.areas.outdoor.typeId == $typeId => {
             "value": repairMaintenanceRequest.areas.outdoor.title->value.en
           },
           repairMaintenanceRequest.areas.unit.typeId == $typeId => {
             "value": repairMaintenanceRequest.areas.unit.title->value.en
           },
           customerServiceRequest.types.generalRequest.typeId == $typeId => {
             "value": customerServiceRequest.types.generalRequest.title->value.en
           },
           customerServiceRequest.types.issueComplaint.typeId == $typeId => {
             "value": customerServiceRequest.types.issueComplaint.title->value.en
           },
         )
      }
    }`;
    const response = await this.client.fetch<{ value?: string }[]>(
      query,
      params
    );
    const value = response.filter((r) => r.value)[0]?.value;
    return value ? { id: areaId, value } : undefined;
  };
}
