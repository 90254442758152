export const paths = {
  root: "/",
  login: "/login",
  authCallback: "/auth",
  dashboard: "/dashboard",
  createServiceRequest: "/create-service-request",
  serviceRequestDetails: "/",
  serviceRequestAttachments: "/attachments",
  serviceRequestMessages: "/messages",
  serviceRequest: "/service-request",
  profile: "/profile",
};
