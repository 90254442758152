import { TFunction } from "i18next";
import MessageIcon from "@mui/icons-material/Message";
import CameraAltIcon from "@mui/icons-material/CameraAltOutlined";
import TimerIcon from "@mui/icons-material/Timer";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { NavigateFunction } from "react-router";

import { Ticket } from "../../../../../types/Ticket";
import { Notification } from "../../../../../../../generated/clients/notifications/data-contracts";
import { AppDispatch } from "../../../../../store";
import { paths } from "../../../../../routes/paths";
import { removeTicket } from "../../../../../store/ticketSlice";
import { ValidNotificationTypes } from "../../../../../types/Notification";

export type SpecificItemProps = {
  icon: JSX.Element;
  label: string;
  text: string;
  onClick: () => Promise<void>;
};

export type SpecificItemParams = {
  type: string;
  ticket: Ticket;
  t: TFunction;
  notificationObjects: Notification[];
  navigate: NavigateFunction;
  dispatch: AppDispatch;
};

export const getSpecificItemProps = (
  params: SpecificItemParams
): SpecificItemProps | undefined => {
  switch (params.type) {
    case ValidNotificationTypes.SERVICE_REQUEST_ASSIGNED:
      return getServiceRequestAssignedProps(params);
    case ValidNotificationTypes.SERVICE_REQUEST_UNASSIGNED:
      return getServiceRequestUnassignedProps(params);
    case ValidNotificationTypes.SERVICE_REQUEST_NEW_ATTACHMENT:
      return getServiceRequestNewAttacment(params);
    case ValidNotificationTypes.SERVICE_REQUEST_NON_ASSIGNEE_COMMENT_ADDED:
      return getServiceRequestNonAssigneeCommentProps(params);
    case ValidNotificationTypes.SERVICE_REQUEST_OVERDUE:
      return getServiceRequestOverdueProps(params);
    case ValidNotificationTypes.SERVICE_REQUEST_STATUS_CHANGED:
      return getServiceRequestStatusChangedProps(params);
  }
};

const getServiceRequestAssignedProps = (
  params: SpecificItemParams
): SpecificItemProps => {
  const { t, ticket, navigate } = params;
  return {
    icon: <ConfirmationNumberOutlinedIcon />,
    label: t("shared.notifications.newRequest.label"),
    text: ticket.summary,
    onClick: async () => {
      navigate(
        `${paths.serviceRequest}/${ticket.key}/${paths.serviceRequestDetails}`
      );
    },
  };
};

const getServiceRequestUnassignedProps = (
  params: SpecificItemParams
): SpecificItemProps => {
  const { t, ticket, dispatch } = params;
  return {
    icon: <PlaylistRemoveOutlinedIcon />,
    label: t("shared.notifications.requestRemoved.label"),
    text: ticket.summary,
    onClick: async () => {
      dispatch(removeTicket(ticket.id));
    },
  };
};

const getServiceRequestNewAttacment = (
  params: SpecificItemParams
): SpecificItemProps => {
  const { t, notificationObjects, navigate, ticket } = params;
  let text =
    notificationObjects.length === 1
      ? t("shared.notifications.attachment.single")
      : t("shared.notifications.attachment.multiple", {
          count: notificationObjects.length,
        });
  return {
    icon: <CameraAltIcon />,
    label: t("shared.notifications.attachment.label"),
    text,
    onClick: async () => {
      navigate(
        `${paths.serviceRequest}/${ticket.key}/${paths.serviceRequestAttachments}`
      );
    },
  };
};

const getServiceRequestNonAssigneeCommentProps = (
  params: SpecificItemParams
): SpecificItemProps => {
  const { t, notificationObjects, ticket, navigate } = params;
  let text =
    notificationObjects.length === 1
      ? t("shared.notifications.message.single")
      : t("shared.notifications.message.multiple", {
          count: notificationObjects.length,
        });
  return {
    icon: <MessageIcon />,
    label: t("shared.notifications.message.label"),
    text,
    onClick: async () => {
      navigate(
        `${paths.serviceRequest}/${ticket.key}/${paths.serviceRequestMessages}`
      );
    },
  };
};

const getServiceRequestOverdueProps = (
  params: SpecificItemParams
): SpecificItemProps => {
  const { t, ticket, navigate } = params;
  return {
    icon: <TimerIcon />,
    label: t("shared.notifications.overdue.label"),
    text: t("shared.notifications.overdue.message"),
    onClick: async () => {
      navigate(
        `${paths.serviceRequest}/${ticket.key}/${paths.serviceRequestDetails}`
      );
    },
  };
};

const getServiceRequestStatusChangedProps = (
  params: SpecificItemParams
): SpecificItemProps => {
  const { t, ticket, navigate } = params;
  return {
    icon: <ArrowForwardOutlinedIcon />,
    label: t("shared.notifications.statusChanged.label", {
      status: ticket.status,
    }),
    text: ticket.summary,
    onClick: async () => {
      navigate(
        `${paths.serviceRequest}/${ticket.key}/${paths.serviceRequestDetails}`
      );
    },
  };
};
